import styled from 'styled-components';

export const ButtonActions = styled.div`

  div.data {
    width: 100%;
    border-radius: 5px;
    padding: 32px;
    background-color: #222222;
    margin: 20px 0;

    label {
      margin: 0;
    }
  }

  button.buttonIcon {
    background: none;
    border: 0;
    margin-bottom: 10px;
  }

  span.buttonIcon {
    font-size: 14px;
    display: flex;
    align-items: center;

    > svg {
      font-size: 14px;
      margin-right: 3px;
      margin-left: 3px;
    }
  }
`

export const BackgroundSection = styled.section`
  font-family: 'Roboto', sans-serif;
  margin: 20px 40px;
  color: #ffffff;

  div.data {
    width: 100%;
    border-radius: 5px;
    padding: 32px;
    background-color: #222222;
    margin: 20px 0;

    label {
      margin: 0;
    }
  }

  h2 {
    font-family: 'UniviaRegular';
    font-size: 28px;
    margin-bottom: 20px;
  }

  @media (max-width: 1000px) {
    margin: 50px 100px;
  }

  @media (max-width: 700px) {
    margin: 15px;
  }
`;
