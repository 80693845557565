import React from 'react';
// import ReactPlayer from 'react-player';
// import ShowMoreText from 'react-show-more-text';
// import { Link, useParams } from 'react-router-dom';
// import { IoIosArrowForward } from 'react-icons/io';
// import { BiEdit, BiTrash } from 'react-icons/bi';

// import api from '~/services/api';
import Index from '~/pages/Index';
// import { useProfile } from '~/hooks/profile';
// import avatar from '~/assets/img/avatar.png';
import { ReactComponent as Processing } from '~/assets/img/undraw_processing.svg';

// import Loading from '~/components/Loading';
// import Modal from '~/components/Modal';
import * as S from './styles';

function Video() {
  // const { key } = useParams();
  // const { dataProfile } = useProfile();
  // const [modalShow, setModalShow] = useState('');
  // const [comments, setComments] = useState('');
  // const [dataVideo, setDataVideo] = useState(null);
  // const [erroUrl, setErroUrl] = useState('');
  // const [source, setSource] = useState(null);

  // const Image = lazy(() => dataVideo.video_path);

  // useEffect(() => {
  //   const img = new Image();
  //   img.src = item.posterPath;
  //   img.onload = () => setSource(item.posterPath);
  // }, [item.posterPath]);

  // const genertorIDs = Math.random()
  //   .toString(36)
  //   .substring(7);

  // console.log(dataProfile);

  // useEffect(() => {
  //   api
  //     .get(`/videos/${key}`)
  //     .then(response => {
  //       setDataVideo(response.data);
  //     })
  //     .catch(err => {
  //       setDataVideo([]);
  //       setErroUrl('Em breve novidades!');
  //     });
  // }, [key]);

  // async function handleComment(e) {
  //   e.preventDefault();

  //   setDataVideo({
  //     ...dataVideo,
  //     comments: [
  //       ...dataVideo.comments,
  //       {
  //         id: genertorIDs,
  //         message: comments,
  //         user: {
  //           nickname: dataProfile.nickname,
  //           avatar: dataProfile.avatar ? dataProfile.avatar : avatar,
  //         },
  //       },
  //     ],
  //   });

  //   await api.post(`/comments/${key}`, {
  //     entityType: 'video',
  //     message: comments,
  //   });

  //   setComments('');
  // }

  // const handleEditComments = commentKey => {
  //   console.log('edit', commentKey);
  //   // const editComments = dataVideo.comments.map(comment => {
  //   //   return key === comment.key ? { ...comment, comment: setComments() } : comment
  //   // })
  // };

  // const handleDeleteComment = commentKey => {
  //   const comentario = dataVideo.comments;

  //   const newComments = comentario.filter(
  //     comment => comment.key !== commentKey
  //   );

  //   setDataVideo({
  //     ...dataVideo,
  //     comments: newComments,
  //   });

  //   setModalShow('');
  // };

  return (
    <Index>
      <S.SectionContainer>
        <div className="notVideo">
          <Processing />
          <span>Em breve novidades!</span>
        </div>
        {/* {dataVideo === null && erroUrl === '' ? (
          <Loading />
        ) : (
          <>
            {dataVideo.length === 0 ? (
              <div className="notVideo">
                <Processing />
                <span>{erroUrl}</span>
              </div>
            ) : (
              <>
                <div className="start">
                  <Link to="/dashboard/tutoriais"> Início</Link>{' '}
                  <IoIosArrowForward /> <font> vídeo </font>
                </div>

                <div>
                  <Suspense fullback={<h1>Carregando...</h1>}>
                  <ReactPlayer
                    config={{
                      youtube: {
                        playerVars: { modestbranding: 1 },
                      },
                    }}
                    className="react-player"
                    url={dataVideo.video_path}
                  />
                  </Suspense>
                </div>

                <S.SectionBottom>
                  <S.Group>
                    <h1>{dataVideo.title}</h1>
                    <span>Tema: {dataVideo.genres}</span>
                    <S.Description>
                      <div className="description">
                        <h1>Descrição</h1>
                        <ShowMoreText
                          line={3}
                          more="MOSTRAR MAIS"
                          less="MOSTRAR MENOS"
                          className="showMoreText"
                          expanded={false}
                        >
                          {dataVideo.overview}
                        </ShowMoreText>
                      </div>
                    </S.Description>

                    <S.VideoColumnList className="activeResponsive">
                      <ul>
                        {dataVideo.suggestions?.map(suggestion => (
                          <li key={suggestion.key} className="activeResponsive">
                            <Link
                              to={`/dashboard/tutoriais/video/${suggestion.key}`}
                            >
                              <img
                                src={
                                  suggestion.backdrop[1].link_with_play_button
                                }
                                alt="Sugestão de vídeo"
                              />
                              <div className="titleTema">
                                <span className="title">
                                  {suggestion.title}
                                </span>
                                <span className="tema">
                                  Tema: {suggestion.genres}
                                </span>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </S.VideoColumnList>

                    <S.Comments>
                      <p>{dataVideo.comments?.length} Comentários</p>
                      <div>
                        <img
                          src={dataProfile.avatar ? dataProfile.avatar : avatar}
                          alt="Sua foto"
                        />
                        <form onSubmit={handleComment}>
                          <input
                            type="text"
                            value={comments}
                            onChange={e => setComments(e.target.value)}
                            placeholder="Escreva seu comentário"
                          />
                          {comments && <button type="submit">Comentar</button>}
                        </form>
                      </div>
                      {dataVideo.comments?.map(comment => (
                        <React.Fragment key={comment.key}>
                          <div>
                            <img
                              src={
                                comment.user.avatar
                                  ? comment.user.avatar
                                  : avatar
                              }
                              alt="Sua foto"
                            />
                            <p className="comments">{comment.user.nickname}</p>
                          </div>
                          <div className="commentFixed">
                            <div className="comment">
                              <span>{comment.message}</span>
                              <div className="edit">
                                <BiEdit
                                  onClick={() =>
                                    handleEditComments(comment.key)
                                  }
                                />
                                <BiTrash
                                  onClick={() => setModalShow('remove')}
                                />
                              </div>
                            </div>
                          </div>
                          {modalShow === 'remove' && (
                            <Modal
                              isOpen={modalShow}
                              onClickClose={() => setModalShow('')}
                            >
                              <div className="remove">
                                <h1>Excluir comentário</h1>
                                <span className="spanRemove">
                                  Deseja excluir seu comentário permanentemente?
                                </span>
                                <div className="alignButtonsRemove">
                                  <button
                                    className="buttonsCancel"
                                    type="button"
                                    onClick={() => setModalShow('')}
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    className="buttonsRemove"
                                    type="button"
                                    onClick={() =>
                                      handleDeleteComment(comment.key)
                                    }
                                  >
                                    Excluir
                                  </button>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </React.Fragment>
                      ))}
                    </S.Comments>
                  </S.Group>

                  <S.VideoColumnList className="activeResponsiveNone">
                    <ul>
                      {dataVideo.suggestions?.map(suggestion => (
                        <li key={suggestion.key}>
                          <Link
                            to={`/dashboard/tutoriais/video/${suggestion.key}`}
                          >
                            <img
                              src={suggestion.backdrop[1].link_with_play_button}
                              alt="Sugestão de vídeo"
                            />
                            <div className="titleTema">
                              <span className="title">{suggestion.title}</span>
                              <span className="tema">
                                Tema: {suggestion.genres}
                              </span>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </S.VideoColumnList>
                </S.SectionBottom>
              </>
            )}
          </>
        )} */}

        {/* </> */}
        {/* ) : (
          <div className="notVideo">
            <WebSearsh />
            <span>{erroUrl}</span>
          </div>
        )} */}
      </S.SectionContainer>
    </Index>
  );
}

export default Video;
