import React from 'react';

import * as S from './styles';
import loadingGif from '~/assets/img/loadingMusic.gif';

export default function Loading({ style }) {
  return (
    <S.Loading style={style}>
      <img src={loadingGif} alt="Carregando..." />
    </S.Loading>
  );
}
