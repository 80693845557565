import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Header = styled.header`
  width: 100%;
  height: 80px;
  background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);

  color: #fff;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 270px; */

  /* @media (max-width: 1200px) {
    padding: 0 150px;
  }
  @media (max-width: 1000px) {
    padding: 0 100px;
  }
  @media (max-width: 600px) {
    padding: 0 50px;
  }
  @media (max-width: 400px) {
    padding: 0;
  } */

  div.nameMusic {
    display: flex;
    flex-direction: column;
    font-size: 'Roboto';

    h1 {
      font-weight: 500;
      font-size: 28px;
      margin-bottom: 0;
    }
    span {
      font-weight: normal;
    }

    @media (max-width: 700px) {
      h1 {
        font-size: 20px;
      }
    }
  }

  div.author {
    display: flex;
    align-items: center;
    font-family: 'UniviaRegular';

    font-style: normal;
    font-weight: normal;

    div.holder {
      height: 120px;
      width: 120px;
      img {
        /* overflow: hidden; */
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    div {
      margin-left: 20px;
      span {
        font-size: 16px;
      }

      h1 {
        font-size: 30px;
        margin: 0;
      }

      span.contact {
        font-size: 14px;
      }
    }
  }
`;

export const Section = styled.section`
    margin: 30px auto;
    padding: 10px 45px;
    width: 65vw;
    background: #2a2a2a no-repeat;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 700px) {
      padding: 10px 20px;
      width: 85vw;
    }
`;

const appearFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const DivSectionGlobal = styled.div`
    animation: ${appearFromRight} 1s;

    padding: 25px 0px;

    div.sectionSucess {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      text-align: center;

      img {
        width: 150px;
      }

      span.sucess {
        font-size: 18px;
        color: #43b581;

        background-color: #181818;
        padding: 5px 60px;
        border-radius: 20px;
      }

    }

    div.infoCertificate {
      display: flex;
      flex-direction: row;
      align-content: space-around;
      justify-content: space-around;
      align-items: center;

      h1 {
        font-size: 28px;
        padding-bottom: 1rem;
      }

      div.dataForm {
        width: 100%;
        display: flex;
        flex-direction: row;

        div.inputsRow {
          width: 100%;
          display: flex;
          justify-content: space-between;

          button {
            height: 0;
            border: 0;
            margin-top: 35px;
            color: #87868b;

            &:hover {
              cursor: pointer;
              color: ${shade(0.2, '#f5134b')};
            }
          }

          div.divColumn48 {
            width: 90%;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            div {
              background: #272727;
            }
          }

        }

      }
      div.dataQR {
        width: 100%;
        display: flex;
        flex-direction: row;

        div {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media (max-width: 600px) {
            margin-bottom: 20px;
          }
        }

        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
        }
      }

      @media (max-width: 1090px) {
        display: flex;
        flex-direction: column;
      }
    }

    div.footerButtons {

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 25px 0;
      width: 100%;

      div.sectionButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 25px;

        @media (max-width: 400px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      div.sectionSaibamais {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;

        div.alignTooltip {
          margin-right: 10%;

          .mytooltip > .tooltip-inner {
            width: 800px;
          }

          span {
            display: flex;
            align-items: center;

            color: #fe5812;
            font-size: 14px;

            svg {
              margin: 0 5px 3px 0;
            }

            &:hover {
              cursor: help;
              color: ${shade(0.2, '#f25812')};
            }
          }
        }
      }

      @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
      }
    }


    hr {
      background-color: #ffffff;
    }

    margin: 0 10px;

    color: #fff;

    h1 {
        font-size: 28px;
        padding-bottom: 1rem;
    }

    p {
        font-size: 16px;
        margin-bottom: -0.01rem;
    }

    form {
        max-width: 7250px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        input {
            width: 100%;
        }

        button {
            width: 10rem;
            align-self: flex-end;

            font-size: 18px;
            color: #ffffff;

            background: linear-gradient(
                251.45deg,
                #f5134b 1.26%,
                #f34d21 96.99%
            );
            border-radius: 17px;
            outline: none;
            border: 0;
            padding: 0.4rem 0;

            &:hover {
                box-shadow: 0 0 2px 2px #f34d21;
            }
        }
        button.active {
            display: none;
        }

        img {
            align-self: flex-end;
            margin: 10px 20px 0 0;
        }
    }

    a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fe5812;
        font-size: 15px;
        cursor: pointer;

        > svg {
            margin-right: 10px;
        }

        &:hover {
            color: ${shade(0.2, '#f25812')};
        }
    }

`;
