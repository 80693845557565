import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import * as Yup from 'yup';

import Index from '~/pages/Index';
import api from '~/services/api';
import { useToast } from '~/hooks/toast';
import Input from '~/components/Input';
import Button from '~/components/Button';
import getValidationErrors from '~/utils/getValidationErrors';

import { BackgroundSectionForgotPass, DivForgotPass } from './styles';

const AlterPassword = () => {
  const [seePass, setSeePass] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [password_confirmation, setPassword_confirmation] = useState(false);
  const [typePassword, setTypePassword] = useState('password');
  const [typeNewPassword, setTypeNewPassword] = useState('password');
  const [typePassword_confirmation, setTypePassword_confirmation] = useState(
    'password'
  );

  const formRef = useRef(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('Insira sua senha atual'),
          newPassword: Yup.string()
            .required('Insira uma nova senha')
            .min(6, 'No mínimo 6 dígitos'),
          password_confirmation: Yup.string()
            .required('Repetir senha')
            .oneOf(
              [Yup.ref('newPassword'), null],
              'As senhas devem corresponder'
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put('/auth/change/password', {
          password: data.password,
          passwordNew: data.newPassword,
          passwordNew_confirmation: data.password_confirmation,
        });

        const { message } = response.data;

        addToast({
          type: 'success',
          title: 'Senha alterada!',
          description: `${message}`,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        const msg = err.response.data.message;

        addToast({
          type: 'error',
          title: 'Erro na alteração de senha',
          description: `${msg}`,
        });
      }
    },
    [addToast]
  );

  const handleViewPassword = () => {
    setSeePass(!seePass);

    if (seePass === false) {
      setTypePassword('text');
    } else {
      setTypePassword('password');
    }
  };

  const handleViewNewPassword = () => {
    setNewPassword(!newPassword);

    if (newPassword === false) {
      setTypeNewPassword('text');
    } else {
      setTypeNewPassword('password');
    }
  };

  const handleViewPassword_confirmation = () => {
    setPassword_confirmation(!password_confirmation);

    if (password_confirmation === false) {
      setTypePassword_confirmation('text');
    } else {
      setTypePassword_confirmation('password');
    }
  };

  return (
    <Index>
      <BackgroundSectionForgotPass>
        <DivForgotPass>
          <h1>Alterar senha</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              type={typePassword}
              name="password"
              label="Senha atual:"
              iconView={seePass ? FiEyeOff : FiEye}
              handleViewPassword={() => handleViewPassword()}
            />
            <Input
              type={typeNewPassword}
              name="newPassword"
              label="Nova senha:"
              iconView={newPassword ? FiEyeOff : FiEye}
              handleViewPassword={() => handleViewNewPassword()}
            />
            <Input
              type={typePassword_confirmation}
              name="password_confirmation"
              label="Confirme a nova senha:"
              iconView={password_confirmation ? FiEyeOff : FiEye}
              handleViewPassword={() => handleViewPassword_confirmation()}
            />
            <Button type="submit">Alterar senha</Button>
          </Form>
        </DivForgotPass>
      </BackgroundSectionForgotPass>
    </Index>
  );
};

export default AlterPassword;
