import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { IoIosCard } from 'react-icons/io';
import { MdOutlinePix } from 'react-icons/md';
import { AiOutlineBarcode, AiTwotoneGift } from 'react-icons/ai';
import Moment from 'moment';

import { Table } from 'react-bootstrap';
import Index from '~/pages/Index';
import Modal from '~/components/Modal';
import Pagination from '~/components/Pagination';
import Posts from '~/components/Posts';
import formatValue from '~/utils/formatValue';

import * as S from './styles';
import api from '~/services/api';
import { useToast } from '~/hooks/toast';
// import { useProfile } from '~/hooks/profile';
import { ReactComponent as UndrawEmptyCart } from '~/assets/img/undraw_empty_cart.svg';
import Loading from '~/components/Loading';
import PaymentMethodEnum from '~/enums/PaymentMethodEnum';

const MinhasCompras = () => {
  // const { cardData } = useProfile();
  const { addToast } = useToast();
  const [products, setProducts] = useState(null);
  const [modalShow, setModalShow] = useState('');
  const [productsModal, setProductsModal] = useState(null);
  const [inteiro, setInteiro] = useState();

  const limit = 10;
  const [currentPages, setCurrentPages] = useState(1);

  useEffect(() => {
    api.get(`/orders?page=${currentPages}&limit=${limit}`).then(response => {
      const { data } = response.data;
      const { total } = response.data;

      setProducts(data);
      setInteiro(total);
    });
  }, [currentPages]);

  const secret = products?.map(k => k.key);

  // console.log('products', products);
  // console.log('PaymentMethodEnum', PaymentMethodEnum);
  // console.log('cardData', cardData);

  function openTab(product) {
    if (product.payment) {
      window.open(
        `${product.payment.transaction.boleto_url}?format=pdf`,
        '_blank'
      );
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: `Erro ao capturar boleto!`,
      });
    }
  }

  const showModal = product => {
    setProductsModal(product);
    setModalShow('cardModal');
  };
  // console.log(productsModal);

  return (
    <Index>
      <Posts />
      {products === null ? (
        <Loading />
      ) : (
        <S.BackgroundSection>
          {secret.length === 0 ? (
            <div className="notShopping">
              {/* <div className="buttonVoucher">
                <Link to="/dashboard/validacao/voucher">Adicionar voucher</Link>
              </div> */}
              <UndrawEmptyCart />
              <span>Você não possui compras</span>
              <Link to="/dashboard/adicionar-creditos/planos">
                Adicionar créditos
              </Link>
            </div>
          ) : (
            <div className="scroll">
              <h1>Minhas Compras</h1>
              {/* <div className="buttonVoucher">
                <Link to="/dashboard/validacao/voucher">Adicionar voucher</Link>
              </div> */}
              <table>
                <thead>
                  <tr>
                    <th>
                      <BsChevronDown />
                      DATA
                    </th>
                    <th>Nº ORDEM</th>
                    <th>CRÉDITOS</th>
                    <th>BÔNUS</th>
                    <th>PONTOS</th>
                    <th>VALOR</th>
                    <th>TIPO</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map(product => (
                    <tr key={product.key}>
                      <td>{Moment(product.created_at).format('DD/MM/YYYY')}</td>
                      <td>
                        {product.payment && product.payment.transaction
                          ? product.payment.transaction.transaction_id
                          : ''}
                      </td>
                      <td>{product.__meta__.qty_items}</td>
                      <td>{product.__meta__.bonusCredit}</td>
                      <td>{product.__meta__.totalScore}</td>
                      <td>{formatValue(product.total)}</td>
                      <td id="orange">
                        {product.payment && product.payment.method &&
                          product.payment.method.id ===
                          PaymentMethodEnum.CREDIT_CARD ? (
                          <button
                            type="button" onClick={() => showModal(product)}
                          >
                            <IoIosCard />
                          </button>
                        ) : null}
                        {product.payment && product.payment.method &&
                          product.payment.method.id ===
                          PaymentMethodEnum.BILLET ? (
                          <button
                            type="button"
                            onClick={() => openTab(product)}
                          >
                            <AiOutlineBarcode />{' '}
                          </button>
                        ) : null}
                        {product.payment && product.payment.method &&
                          product.payment.method.id ===
                          PaymentMethodEnum.VOUCHER ? (
                          <button
                            type="button"
                          >
                            <AiTwotoneGift />{' '}
                          </button>
                        ) : null}
                        {product.payment && product.payment.method &&
                          product.payment.method.id ===
                          PaymentMethodEnum.PIX ? (
                          <button
                            type="button"
                          >
                            <MdOutlinePix />{' '}
                          </button>
                        ) : null}
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {product.status.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {inteiro && (
            <Pagination
              limit={limit}
              total={inteiro}
              setCurrentPages={setCurrentPages}
            />
          )}
        </S.BackgroundSection>
      )}

      {/* Modais */}

      {modalShow === 'cardModal' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <Table borderless className="resumePaymentCardCredit">
            <div className="orderService">
              <span>
                Transação Nº:
                <strong>
                  {' '}
                  {productsModal.payment.transaction.transaction_id}
                </strong>
              </span>
            </div>
            <tbody>
              <tr>
                <td>Créditos:</td>
                <td>{productsModal.__meta__.qty_items}</td>
              </tr>

              <tr>
                <td>Bônus em créditos:</td>
                <td>{productsModal.__meta__.bonusCredit}</td>
              </tr>

              <tr>
                <td>Pontos:</td>
                <td>{productsModal.__meta__.totalScore}</td>
              </tr>

              <tr>
                <td>Status:</td>
                <td style={{ textTransform: 'capitalize' }}>
                  {productsModal.status.name}
                </td>
              </tr>

              <tr>
                <td>Meio de pagamento:</td>
                <td>{productsModal.payment.method.name}</td>
              </tr>

              <tr>
                <td>Data:</td>
                <td>{Moment(productsModal.created_at).format('DD/MM/YYYY')}</td>
              </tr>

              {/* <tr>
                <td>Parcelas:</td>
                <td>5x de 2</td>
              </tr> */}

              <tr>
                <td>Total:</td>
                <td>{formatValue(productsModal.__meta__.subtotal)}</td>
              </tr>
            </tbody>
          </Table>
        </Modal>
      )}
    </Index>
  );
};

export default MinhasCompras;
