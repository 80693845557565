import styled from 'styled-components';

export const BackgroundHeader = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;

  background-color: #222222;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.09);

  div.contenTest {
    width: 100%;
    padding: 0 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 160px;
      height: 67px;
    }

    button {
      border: solid 2px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #f34d21, #f5134b);
      color: white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #222222 inset;
      border-radius: 20px;
      padding: 0.3rem 2rem;
      font-size: 18px;
      outline: none;
      transition: all 0.5s ease;
      font-style: normal;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }
    }
  }
`;
