import React from 'react';

import { ReactComponent as Group } from '../../../assets/img/Group.svg';
import { ReactComponent as Composition } from '../../../assets/img/composition.svg';
import { ReactComponent as Information } from '../../../assets/img/information.svg';
import { ReactComponent as Upload } from '../../../assets/img/upload.svg';
import { ReactComponent as Certification } from '../../../assets/img/certification.svg';

import { BackgroundSection3, DivNumber, DivSteps } from './styles';

export default function Section3() {
    return (
        <BackgroundSection3 id="como_funciona">
            <div className="content">
                <h2>Conheça os passos para registrar sua música</h2>
                <DivNumber>
                    <ul>
                        <li>1</li>
                        <li>
                            <Group />
                        </li>
                        <li>2</li>
                        <li>
                            <Group />
                        </li>
                        <li>3</li>
                        <li>
                            <Group />
                        </li>
                        <li>4</li>
                    </ul>
                </DivNumber>
                <DivSteps>
                  <div>
                    <ul>
                      <li>
                          <Composition />
                      </li>
                      <li>Composição</li>
                    </ul>
                    <ul>
                      <li>
                          <Information />
                      </li>
                      <li>Informações</li>
                    </ul>
                    <ul>
                      <li>
                          <Upload />
                      </li>
                      <li>Upload</li>
                    </ul>
                    <ul id="last">
                      <li>
                          <Certification />
                      </li>
                      <li>Certificado</li>
                    </ul>
                  </div>
                </DivSteps>
            </div>
        </BackgroundSection3>
    );
}
