import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import Index from '~/pages/Index';
import Posts from '~/components/Posts';

import * as S from './styles';

export default function Inicio({ children }) {
  const location = useLocation();

  return (
    <Index>
      <S.BackgroundSection>
        <Posts />
        <nav>
          <div>
            <ul>
              <li
                className={
                  location.pathname.split('/')[3] === 'resumo' ? 'isSelected' : ''
                }
              >
                <Link to="/dashboard/inicio/resumo">Resumo</Link>
              </li>
              <li
                className={
                  location.pathname.split('/')[3] === 'creditos'
                    ? 'isSelected'
                    : ''
                }
              >
                <Link to="/dashboard/inicio/creditos">Créditos</Link>
              </li>
              <li
                className={
                  location.pathname.split('/')[3] === 'pontos' ? 'isSelected' : ''
                }
              >
                <Link to="/dashboard/inicio/pontos">Pontos</Link>
              </li>
              <li
                className={
                  location.pathname.split('/')[3] === 'ranking'
                    ? 'isSelected'
                    : ''
                }
              >
                <Link to="/dashboard/inicio/ranking">Ranking</Link>
              </li>
            </ul>
          </div>
        </nav>
      </S.BackgroundSection>
      {children}
    </Index>
  );
}
