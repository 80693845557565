import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsFillCaretRightFill } from 'react-icons/bs';
// import ReactPlayer from 'react-player';

import * as S from './styles';
// import api from '~/services/api';

export default function FeatureVideo({ item }) {
  const [source, setSource] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = item.posterPath;
    img.onload = () => setSource(item.posterPath);
  }, [item.posterPath]);

  let description = item.overview;
  if (description?.length > 150) {
    description = `${description.substring(0, 150)}...`;
  }

  let { title } = item;
  if (title?.length > 45) {
    title = `${title.substring(0, 45)}...`;
  }

  return (
    <S.Featured
      style={{
        backgroundSize: 'cover',
        backgroundPosition: '0% 0%',
        backgroundImage: `url(${source})`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <S.FeaturedHorizontal>
        <S.FeaturedVertical>
          <S.FeaturedInfo>
            {/* <div className="test"> */}
            <S.FeaturedName>{title}</S.FeaturedName>

            <S.FeaturedDescription>{description}</S.FeaturedDescription>

            <Link to={`/dashboard/tutoriais/video/${item.key}`}>
              {/* href={`/watch/${item.id}`}
              onClick={() => setModalShow('videoMain')} */}
              <BsFillCaretRightFill /> Assistir
            </Link>
            {/* </div> */}
            {/* {modalShow === 'videoMain' && (
              <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
                <ReactPlayer
                  controls
                  className="knowVideos"
                  url="https://www.youtube.com/watch?v=Pfm8M3q-4TY&t=9008s&ab_channel=CozyRain"
                />
              </Modal>
            )} */}
          </S.FeaturedInfo>
        </S.FeaturedVertical>
      </S.FeaturedHorizontal>
    </S.Featured>
  );
}
