import styled from 'styled-components';

import Tooltip from '~/components/Tooltip';

export const Error = styled(Tooltip)`
  margin-bottom: 1px;
  margin-right: 10px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
