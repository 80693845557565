import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import ClipboardJS from 'clipboard';
import Moment from 'moment';

import Index from '~/pages/Index';
import check from '~/assets/img/check.svg';


import { useToast } from '~/hooks/toast';

import OrderStatusEnum from '~/enums/OrderStatusEnum';
import { useProfile } from '~/hooks/profile';
import formatValue from '~/utils/formatValue';

import api from '~/services/api';
import * as S from './styles';


export default function SuccessCard() {
  const history = useHistory();
  const { addToast } = useToast();
  const { cardData } = useProfile();
  const formatedTotal = formatValue(cardData.total);
  const [statusOrder, setStatusOrder] = useState('');
  const [objPix, setObjPix] = useState({
    encodedImage: '',
    payload: '',
  });

  const formatDate = Moment(cardData.created_at).format('DD/MM/YYYY');

  useEffect(() => {
    setStatusOrder(
      cardData.status_id
        ? OrderStatusEnum.properties[cardData.status_id].label
        : ''
    );

    if(cardData?.payment?.payment_method_id === 29){
      api.get(`/payments/store/pix/${cardData.payment.transaction.transaction_id}`).then(response => {
        if(response.data){
          setObjPix(response.data)
        }
      });
    }
  }, [cardData]);

  const clipboard = new ClipboardJS('#buttonCopy');
  clipboard.on('success', e => {

    addToast({
      type: 'success',
      title: 'Sucesso',
      description: `Código copiado para área de transferência!`,
    });

    e.clearSelection();
  });

  clipboard.on('error', e => {
    addToast({
      type: 'error',
      title: 'Erro',
      description: `Erro ao copiar!`,
    });
  });


  useEffect(() => {
    if (cardData.length === 0) {
      history.push('/dashboard/inicio/resumo');
    }
  }, [cardData, history]);

  return (
    <Index>
      <S.SectionNotMusic>
        <div className="sucess">
          <img src={check} alt="Solicitação realizada com sucesso"/>

          <span>Solicitação realizada com sucesso!</span>
        </div>

        <div className="informationPix">
          <span className='lerQr'>Leia o QrCode</span>
          <img src={`data:image/jpeg;base64,${objPix.encodedImage}`} alt="Imagem QrCode PIX"/>

          <span>Ou pague com pix copia e cola!</span>

          <span className="spanCopy">
            {objPix.payload
              ? objPix.payload
              : ''}
          </span>
          <div>
            <button
              id="buttonCopy"
              type="button"
              data-clipboard-text={
                objPix.payload
                  ? objPix.payload
                  : ''
              }
            >
              <FaRegCopy />
              Copiar código PIX
            </button>
          </div>

        </div>

        <div className="resume">
          <h1>Resumo:</h1>
          <Table borderless>
            <div className="orderService">
              <span>
                Transação Nº:
                <strong>
                  {' '}
                  {cardData.payment && cardData.payment.transaction
                    ? cardData.payment.transaction.transaction_id
                    : ''}
                </strong>
              </span>
            </div>
            <tbody>
              <tr>
                <td>Créditos:</td>
                <td>{cardData.__meta__ ? cardData.__meta__.qty_items : ''}</td>
              </tr>

              <tr>
                <td>Status:</td>
                <td>{statusOrder}</td>
              </tr>

              <tr>
                <td>Meio de pagamento:</td>
                <td>PIX</td>
              </tr>

              <tr>
                <td>Data:</td>
                <td>{formatDate}</td>
              </tr>

              <tr>
                <td>Total:</td>
                <td>{formatedTotal}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Link to="/dashboard/inicio/resumo">Voltar para o início</Link>
      </S.SectionNotMusic>
    </Index>
  );
}
