import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  display: flex;
  flex-direction: column;

  div.divHeader {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    @media (max-width: 800px) {
      margin-left: 20px;
    }
    h1 {
      font-size: 25px;
    }

    div.pag {
      display: flex;

      button {
        cursor: pointer;

        svg {
          /* font-size: 1.5rem; */
          margin-right: 5px;
          display: flex;
          align-items: center;
        }
        color: #ffffff;
        outline: none;
        display: flex;
        align-items: center;
        font-size: 16px;
        background-color: #222222;
        border: 0;
        border-radius: 3px;
        padding: 10px 70px 10px 10px;
        margin: 10px 15px 10px 0;

        /* @media (max-width: 800px) {
          width: 22%;
          height: 50px;
        } */

        &:hover {
          background: ${shade(0.2, '#1d1d1d')};
        }
      }

      button.active {
        background-color: rgba(254, 88, 18, 0.15);
        color: #e84c3d;
      }

      @media (max-width: 580px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  div.divBody {
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-wrap: wrap;
      margin: 0 20px;
    }
  }
`;

export const BackgroundDivLeft = styled.div`
  width: 600px;
  /* height: 625px; */
  background: #222222;
  padding: 30px;
  border-radius: 10px;
  margin: 10px 10px 40px 50px;

  @media (max-width: 800px) {
    margin: 0;
    width: 100%;
  }

  div.saveCardInputCheckbox {
    margin-top: 20px;
  }

  div.saveCardInputCheckbox,
  div.patternCardInputCheckbox {
    display: flex;
    align-items: center;

    input {
      width: 18px;
      margin-right: 10px;
    }
  }

  button.cleanCard {
    display: flex;
    margin: 20px 0 0;
    background: 0;
    color: #f34d21;
    border: 0;
    cursor: pointer;

    &:hover {
      color: ${shade(0.2, '#f25812')};
    }
  }

  form {
    display: none;

    div.cardsData {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h2 {
        font-size: 25px;
        margin: 0;
      }

      > svg {
        color: #e84c3d;
        font-size: 25px;
        cursor: pointer;
      }
    }

    div.inputsRow {
      margin-top: 20px;
    }

    div.rccs {
      margin: 20px auto;
      @media (max-width: 400px) {
        width: 100%;
      }
    }

    div.rccs__card {
      @media (max-width: 400px) {
        width: 100%;
      }
    }

    div.twoInputsInLine {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      align-items: end;

      div.inputVality {
        margin-right: 10px;
        width: 47%;
      }
      div.inputCvv {
        margin-left: 10px;
        width: 47%;
      }
    }

    label {
      font-size: 15px;
      color: #87868b;
      margin-bottom: 2px;
      display: flex;
      flex-direction: column;
    }

    input {
      width: 100%;
      height: 40px;
      background-color: #1d1d1d;
      border: 2px solid #363636;
      box-sizing: border-box;
      border-radius: 5px;
      outline: 0;
      color: #ffffff;
      padding: 0 20px;

      &:focus-within {
        background-color: #1d1d1d;
        outline: 0;
        border: 2px solid #fe5812;
        box-shadow: 0 0 0 0;
        color: #ffffff;
      }
    }

    input.activeValidation {
      border: 2px solid #c53030;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px #1d1d1d inset;
    }
  }

  form.active {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 290px;
      margin: 0 auto;
    }
  }
`;

export const BackgroundDivRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  /* height: 625px; */

  /* @media (max-width: 800px) {
    margin: 20px;
  } */
  @media (max-width: 800px) {
    margin: 20px 0;
    width: 100%;
  }

  background: #222222;
  padding: 10px 30px;
  border-radius: 10px;
  margin: 10px 50px 40px 10px;

  h2 {
    font-size: 25px;
    margin-top: 20px;
  }

  form.qtdPortion {
    label {
      font-size: 16px;
      margin-top: 0.8rem;
      color: #87868b;
    }

    select {
      color: #ffffff;
    }

    div {
      padding: 0 15px;
      background: #1d1d1d;
      border: 1px solid #363636;
      box-sizing: border-box;
      border-radius: 5px;

      select,
      option {
        background: #1d1d1d;
        border: 1px solid #1d1d1d;
        width: 100%;
        height: 40px;
      }
    }

    span {
      display: block;
      margin: 10px 0;
      color: #828282;
      font-size: 16px;
      font {
        color: #ffffff;
      }
    }
  }

  form.disable {
    display: none;
  }

  h3 {
    margin-top: 30px;
  }

  div.table {
    color: #ffffff;
    background: #363636;
    border-radius: 5px;
    font-size: 16px;

    div {
      padding: 10px;

      div.spaceBetween {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 16px;
        }
      }
      div.disable,
      hr.disable {
        display: none;
      }
      hr {
        background-color: #4e4e4e;
        margin: 5px 10px;
      }
    }
  }

  div.terms {
    font-size: 15px;

    button.termsButton {
      border: none;
      background: none;
      color: #f34d21;
      cursor: pointer;

      &:hover {
        color: ${shade(0.2, '#f25812')};
      }
    }
  }

  button.conclude {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 30px auto;
    color: #fafafa;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 15px;
    outline: none;
    border: 0;
    padding: 0.7rem;

    span {
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 2px 2px #f34d21;
    }
  }

  button.active {
    display: none;
  }

  img {
    display: block;
    margin: 20px auto;
  }
`;
