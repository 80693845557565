import styled from 'styled-components';

export const BackgroundHeader = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  /* position: relative;
  z-index: 5; */

  background-color: #222222;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.09);

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 160px;
      height: 67px;
    }
  }

  /* Responsividade */
  @media (max-width: 820px) {
    img {
      margin-left: -50px;
    }
  }
  @media (max-width: 450px) {
    /* display: none; */

    img {
      margin-left: -30px;
    }
  }
`;
