import styled from 'styled-components';
import { shade } from 'polished';

export const SectionContainer = styled.section`
  margin: 30px;

  div.notVideo {
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 180px auto;

    span {
      font-family: 'UniviaRegular';
    }

    svg {
      width: 400px;
      height: 270px;

      @media (max-width: 450px) {
        width: 100%;
        height: 100%;
      }
    }

    span {
      color: #fff;
      font-size: 30px;
      padding: 30px 0;
    }
  }

  div.start {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 18px;
    color: #7e7e7e;

    a {
      color: #7e7e7e;
      &:hover {
        filter: brightness(0.8);
      }
    }

    > svg {
      margin: 0 5px;
    }

    font {
      color: #ffffff;
    }

    @media (max-width: 1130px) {
      margin-top: 60px;
    }
  }

  h1 {
    font-size: 24px;
  }

  span {
    color: #c5c5c5;
    font-size: 12px;
  }

  div.react-player {
    width: 100% !important;
    height: 450px !important;
    margin: 25px 0;
  }
`;

export const SectionBottom = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 800px;

  @media (max-width: 700px) {
    div.activeResponsiveNone {
      display: none;
    }
  }
`;

export const Description = styled.div`
  margin-top: 30px;
  div.description {
    /* max-width: 40rem; */
    border-bottom: 0.2px solid #363636;

    div.showMoreText {
      margin: 15px 0 7px;

      span {
        text-decoration: none;
        color: #fff;
        font-size: 15px;

        > a {
          text-decoration: none;
          display: flex;
          justify-content: center;
          color: #7c7c7d;
          font-size: 14px;
          margin: 0.5rem 0 0;

          &:hover {
            color: ${shade(0.2, '#7c7c7d')};
          }
        }
      }
    }
  }
`;

export const Comments = styled.div`
  margin-top: 40px;

  p {
    text-align: right;
    color: #7c7c7d;
  }

  div.commentFixed {
    margin: -50px 0 10px 50px;
  }

  div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    p.comments {
      margin: -30px 20px 0;
      color: #ffffff;
    }

    div.comment {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    div.edit {
      width: 40px;
      margin: 0;
      display: flex;
      justify-content: space-between;

      > svg {
        cursor: pointer;
        color: #c5c5c5;

        &:hover {
          color: ${shade(0.2, '#7c7c7d')};
        }
      }
    }

    form,
    div.comment {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      background: #222222;
      border: 2px solid #363636;
      border-radius: 5px;
      margin: 10px 0 10px 20px;

      &:focus-within {
        background-color: #1d1d1d;
        outline: 0;
        border: 2px solid #fe5812;
        box-shadow: 0 0 0 0;
        color: #ffffff;
      }

      input {
        width: 80%;
        background: none;
        border: none;
        color: #ffffff;
      }

      & input[value=''] {
        width: 100%;
      }

      button {
        background: none;
        width: 20%;
        border: none;
        color: #fe5812;

        &:hover {
          color: ${shade(0.2, '#fe5812')};
        }
      }
    }
  }
`;

export const Group = styled.div`
  width: 75%;
  margin-right: 30px;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    width: 100%;
  }
  div.activeResponsive {
    div.titleTema {
      margin-left: 20px;
    }
    ul {
      margin-top: 10px;
    }
    li {
      display: flex;
      a {
        display: flex;
      }
    }
    @media (min-width: 700px) {
      display: none;
    }
  }
`;

export const VideoColumnList = styled.div`
  ul {
    li {
      margin-bottom: 20px;

      width: 100%;
      transform: scale(0.9);
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1);
        cursor: pointer;
      }

      div.titleTema {
        width: 100%;

        padding: 10px 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        display: flex;
        flex-direction: column;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;

        span.title {
          color: #fff;
          font-size: 15px;
          width: 200px;
        }
        span.tema {
          font-size: 12px;
          color: #c5c5c5;
          width: 200px;
        }
      }
    }
  }
`;
