import styled from 'styled-components';

export const Featured = styled.section`
  height: 80vh;
`;

export const FeaturedHorizontal = styled.div`
  width: inherit;
  height: inherit;
  background: linear-gradient(to right, #111111 35%, transparent 85%);
  /* background: linear-gradient(
    to right,
    rgba(243, 77, 33, 0.5),
    rgba(245, 19, 75, 0.5)
  ); */
`;

export const FeaturedVertical = styled.div`
  width: inherit;
  height: inherit;
  background: linear-gradient(to top, #111111 15%, transparent 85%);

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FeaturedInfo = styled.div`
  /* margin-top: 80px; */

  div.test {
    width: 200px;
  }

  margin-left: 30px;
  padding-bottom: 50px;
  padding-top: 70px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fafafa;
    font-size: 20px;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 40px;
    outline: none;
    border: 0;
    width: 165px;
    height: 42px;
    /* padding: 0.4rem 3rem; */
    margin: 15px 0;
    /* opacity: 1;
        transition: all ease 0.2s; */

    svg {
      margin-left: -10px;
      margin-right: 10px;
    }

    &:hover {
      /* opacity: 0.7; */
      box-shadow: 0 0 2px 2px #f34d21;
    }
  }
`;

export const FeaturedName = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 47px;
`;

export const FeaturedDescription = styled.div`
  font-size: 15px;
  margin-top: 15px;
  color: #fff;
  max-width: 35%;
`;
