import React, { useRef, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FiMail, FiLogIn } from 'react-icons/fi';
import * as Yup from 'yup';

import api from '~/services/api';
import loadingGif from '~/assets/img/loadingMusic.gif';
import { useToast } from '~/hooks/toast';
import getValidationErrors from '~/utils/getValidationErrors';
import Input from '~/components/Input';
import Button from '~/components/Button';
import * as S from './styles';

export default function SignIn() {
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToast();

  const [disable, setDisable] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        // console.log(data);
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Insira um e-mail válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setDisable(disable === '' ? 'active' : '');

        setLoading(true);

        data.goTo = `${process.env.REACT_APP_URL}/alterar-senha`;

        const response = await api.post('/auth/forgot/password', data);

        const { title, message } = response.data;

        setLoading(false);

        history.push(`/email-enviado/${title}/${message}`);
      } catch (err) {
        setLoading(false);
        setDisable(disable === 'active' ? '' : '');

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        if (!err.response) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Serviço de dados indisponível no momento, favor tente mais tarde!',
          });
          return;
        }

        const { message } = err.response.data;
        addToast({
          type: 'error',
          title: 'Erro na recuperação de senha',
          description: `${message}`,
        });
      }
    },
    [addToast, history, disable]
  );

  return (
    <S.BackgroundSectionForgotPass>
      <S.DivRightLogin>
        <h1>Recuperar senha</h1>
        <p>Digite seu endereço de e-mail para redefinir a senha.</p>
        <p>
          Talvez você precise verificar sua pasta de spams ou desbloquear o
          e-mail no-reply@acordeon.com.br.
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input icon={FiMail} name="email" type="email" placeholder="E-mail" />

          <Button type="submit" disabled={loading} className={disable}>
            Enviar
          </Button>
          {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}
        </Form>

        <Link to="/login">
          <FiLogIn /> Voltar para o login
        </Link>
      </S.DivRightLogin>
    </S.BackgroundSectionForgotPass>
  );
}
