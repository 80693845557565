import styled from 'styled-components';

export const BackgroundSection = styled.section`
  nav {
    height: 60px;
    width: 100%;

    > div {
      display: flex;
      justify-content: center;
    }

    ul {
      height: 100%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 20px -30px 20px 0;
      /* flex-wrap: wrap; */

      li {
        font-size: 17px;
        font-family: 'UniviaRegular';
        color: #8e8e8e;

        cursor: pointer;
        position: relative;

        transition: all ease 0.3s;

        a {
          width: 100px;
          height: 37px;
          display: block;
          background: none;
          border: none;
          color: #8e8e8e;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            color: #ffffff;
          }
        }
      }

      li.isSelected {
        border-radius: 20px;
        background-color: rgba(254, 88, 18, 0.15);
        line-height: normal;

        a {
          color: #e84c3d;
        }
      }
    }

    @media (max-width: 580px) {
      margin-bottom: 20px;

      ul {
        display: flex;
        justify-content: center;
        margin: 20px 0;
      }
    }

    @media (max-width: 450px) {
      ul li {
        font-size: 15px;
      }

      ul li a {
        width: 70px;
      }
    }
  }

`;
