import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';

const ProfileContext = createContext({});

const initialValue = {
  fullname: '',
  cpf: '',
  rg: '',
  gender: '',
  birthday: '',
  addresses: {
    zipcode: '',
    street: '',
    street_number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
  },
  contacts: {
    name: '',
    category_id: 7,
    number: '',
  },
};

export const ProfileProvider = ({ children }) => {
  const [dataProfile, setDataProfile] = useState([]);
  const [dataPerson, setDataPerson] = useState([initialValue]);
  const [dataRanking, setDataRanking] = useState([]);
  const [listTypeContacts, setListTypeContacts] = useState([]);
  const [cardData, setcardData] = useState([]);
  const [test, setTest] = useState('');
  const history = useHistory();

  useEffect(() => {
    api.get('/profile').then(res => {
      setDataProfile(res.data);
      setDataRanking(res.data.ranking);
      if (res.data.person === null) {
        setDataPerson(...dataPerson);
      } else {
        setDataPerson(res.data.person);
      }

      if(res.data.person.fullname === null) {
       return history.push('/dashboard/perfil')
      }
    });

    api.get('/categories/list/contacts').then(contact => {
      setListTypeContacts(contact.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        dataProfile,
        dataPerson,
        dataRanking,
        initialValue,
        cardData,
        listTypeContacts,
        test,
        setTest,
        setDataPerson,
        setcardData,
        setDataRanking,
        setDataProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export function useProfile() {
  const context = useContext(ProfileContext);

  return context;
}
