import styled from 'styled-components';

export const BackgroundSectionTerms = styled.section`
    width: 100%;
    height: auto;
    background: #181818 no-repeat;
    z-index: -1;

    display: flex;
    justify-content: center;
`;

export const DivTerms = styled.div`
    margin: 40px auto;

    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;

    h1 {
        font-family: 'UniviaMedium';

        padding-bottom: 1rem;

        font-style: normal;
        font-weight: 500;
        font-size: 28px;
    }

    h6 {
        font-family: 'UniviaBook';
        font-size: 18px;
        margin-top: 20px;
        text-transform: uppercase;
    }

    p {
        font-family: 'UniviaBook';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        text-align: justify;
    }

    a {
        width: 10rem;
        align-self: flex-end;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 18px;
        color: #ffffff;

        background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
        border-radius: 17px;
        outline: none;
        border: 0;
        margin-top: 1rem;
        padding: 0.4rem 0;

        &:hover {
            box-shadow: 0 0 2px 2px #f34d21;
        }
    }
`;
