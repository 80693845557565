import styled from 'styled-components';

export const BackgroundSection = styled.section`
  margin: 30px 70px;

  nav {
    ul {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 850px) {
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 90px;
      }

      /* @media (max-width: 850px) {
        display: flex;
        flex-direction: column;
        width: 30%;
      } */

      li {
        font-family: 'Roboto', sans-serif;
        font-size: 19px;
        cursor: pointer;

        transition: all ease 0.3s;

        a {
          height: 37px;
          display: block;
          background: none;
          border: none;
          color: #7c7c7d;
          padding: 5px 14px;
          text-decoration: none;

          &:hover {
            color: #ffffff;
          }
        }
      }

      li.isSelected {
        border-radius: 20px;
        background-color: rgba(254, 88, 18, 0.15);
        a {
          color: #e84c3d;
        }

        @media (max-width: 1363px) {
          background: none;
        }
      }
    }
  }
  @media (max-width: 500px) {
    margin: 30px 0;
  }
`;

export const DivSection = styled.div`
  margin: 30px 70px;
  color: #c5c5c5;
  font-size: 18px;

  .card {
    background-color: transparent;
    border-style: solid;
    border-color: #363636;
    border-bottom-width: 2px;
    border-top-width: 2px;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;

    .card-header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        color: #ffffff;
      }
    }

    .card-body {
      padding: 1.25rem 2.5rem;
      text-align: justify;
    }
  }

  button,
  a {
    border: 0;
    background: 0;
    color: #79b8f3;
    font-size: 17px;

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media (max-width: 500px) {
    margin: 30px 0;
  }
`;
