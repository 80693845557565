import React, { useState } from 'react';
import Index from '~/pages/Index';
import * as S from './styles';

import {Button, Form} from 'react-bootstrap';

const obj = {
  configPayment: [
    { name: 'asaas', select: true },
    { name: 'sicredi', select: false }
  ]
};

const Configuracoes = () => {

  const [configPayment, setConfigPayment] = useState(obj.configPayment);

  const handleCheckboxChange = (index, oldSelect) => {
    const updatedConfigPayment = configPayment.map((item, i) => {
      if (index === i) {
        return { ...item, select: !oldSelect }; // Inverte o valor de select
      }else {
        return { ...item, select: oldSelect }; // Inverte o valor de select
      }
    });
    setConfigPayment(updatedConfigPayment);
  };

  return (
    <Index>
      <S.BackgroundSection>
        <>          
          <h2>CONFIGURAÇÕES DO SISTEMA</h2>
          <br></br>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Integrações de Pagamento</Form.Label>
              {configPayment.map((item, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  id={`checkbox-${index}`}
                  label={item.name}
                  checked={item.select}
                  onChange={() => handleCheckboxChange(index, item.select)}
                />
              ))}
            </Form.Group>            
          </Form>

          <div>
            <Button variant="secondary" size="sm">
              SALVAR
            </Button>
          </div>
        </>
      </S.BackgroundSection>      
    </Index>
  );
};

export default Configuracoes;
