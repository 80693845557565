import React from 'react';

import { lower } from '~/utils/lower';

const Highlight = ({ children, toHighlight }) => {
  const regex = new RegExp(`(${toHighlight})`, 'i');
  return children.split(regex).map((chunk, index) => {
    if (!toHighlight) {
      return chunk;
    }
    // eslint-disable-next-line no-use-before-define
    if (lower(chunk) === lower(toHighlight)) {
      // eslint-disable-next-line react/no-array-index-key
      return <mark key={index}>{chunk}</mark>;
    }
    return chunk;
  });
};

export default Highlight;
