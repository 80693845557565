import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Error } from './styles';

const ShowError = ({ description }) => {
  // const inputRef = useRef(null);

  // console.log('a', name);

  // const { fieldName, error, registerField } = useField(name);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: 'value',
  //   });
  // }, [fieldName, registerField]);

  return (
    <Error title={description}>
      <FiAlertCircle color="#c53030" size={20} />
    </Error>
  );
};

export default ShowError;
