import React, { useEffect, useState } from 'react';

import Loading from '~/components/Loading';
import { useProfile } from '~/hooks/profile';
import ContentStart from '~/components/ContentStart';

import api from '~/services/api';

export default function Ranking() {
  const { dataRanking, dataProfile } = useProfile();
  const [historiesRanking, setHistoriesRanking] = useState();
  const [inteiro, setInteiro] = useState();

  const limit = 5;
  const [currentPages, setCurrentPages] = useState(1);

  useEffect(() => {
    api
      .get(`/user/history/ranking?page=${currentPages}&limit=${limit}`)
      .then(response => {
        setHistoriesRanking(response.data.data);
        const { total } = response.data;
        setInteiro(total);
      });
  }, [currentPages]);

  const rankingTotal = dataProfile?.ranking?.score;

  // const sinal = value < 0 ? '-' : '+';

  return (
    <>
      {dataProfile.length === 0 ? (
        <Loading />
      ) : (
        <ContentStart
          title="Seu ranking:"
          textButton="Ranking geral"
          textTooltip="Como subir posições?"
          tooltip="Para subir posições no ranking ..."
          historyName="RANKING"
          total={rankingTotal}
          typeObject="score"
          histories={historiesRanking}
          // historyDataValue={value <= 9 ? `0${value}°` : `${value}°`}
          inteiro={inteiro}
          limit={limit}
          setCurrentPages={setCurrentPages}
          link="/dashboard/ranking"
        >
          <div>
            <span className="clasNumberCred">{dataRanking?.position || 0}º</span>
            <aside>Posição</aside>
          </div>
          {/* <S.BackgroundDiv>
            <span className="classNumberRanking">{dataRanking.position}º</span>
            <div className="bottomRanking">
              <aside className="classNumberRanking">
                Na última semana
                <br />
                {dataRanking.pastPosition} posições
              </aside>
              <BsChevronUp size={40} />
            </div>
          </S.BackgroundDiv> */}
        </ContentStart>
      )}
    </>
  );
}
