import styled from 'styled-components';

export const BackgroundSection = styled.section`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 18px;
    color: #7e7e7e;
    align-self: flex-start;
    margin-left: 60px;
    margin-bottom: 30px;

    > a {
      text-decoration: none;
      color: #7e7e7e;
    }

    font {
      color: #ffffff;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }
`;

export const BackgroundDiv = styled.div`
  width: 90%;
  height: 350px;
  background: #222222;
  border-radius: 10px;
  margin: 10px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  div.soon {
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 30px auto;
    font-family: 'UniviaRegular';

    svg {
      width: 400px;
      height: 240px;

      @media (max-width: 450px) {
        width: 90%;
        height: 100%;
        margin: 20px auto;
      }
    }
  }

  ul {
    /* margin: 40px auto; */

    li#formInstagram.submitted,
    li#paragraphInstagram,
    li#formYoutube.submitted,
    li#paragraphYoutube {
      display: none;
    }

    li#paragraphInstagram.submitted,
    li#paragraphYoutube.submitted {
      display: block;
      color: #7C7C7D;
    }

    li {
      display: flex;
      flex-direction: column;
      text-align: center;

      font-size: 15px;
      color: #ffffff;

      img {
        margin: 20px auto;
        width: 40%;
      }

      img.youtube {
        margin: 20px auto;
        width: 32%;
      }

      button.disabled {
        display: flex;
        align-items: center;
        border: solid 2px #7C7C7D;
        /* background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #7C7C7D, #7C7C7D); */
        color: #7C7C7D;
        background-color: transparent;
        /* background-origin: border-box; */
        /* box-shadow: 2px 1000px 1px #222222 inset; */
        border-radius: 20px;
        padding: 0.4rem 1.7rem;
        font-size: 12px;
        outline: none;
        transition: all 0.5s ease;
        font-style: normal;
        margin: 20px auto 5px auto;
        cursor: no-drop;

      }
      button.notDisabled {
        display: flex;
        align-items: center;
        border: solid 2px transparent;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #f34d21, #f5134b);
        color: white;
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #222222 inset;
        border-radius: 20px;
        padding: 0.4rem 1.7rem;
        font-size: 12px;
        outline: none;
        transition: all 0.5s ease;
        font-style: normal;
        margin: 20px auto 5px auto;

        &:hover {
          cursor: pointer;
          box-shadow: none;
        }
      }
    }
  }

  ul.share {
    li {
      img {
        margin: 20px auto 2px auto;
      }
      a {
        display: flex;
        align-items: center;
        align-self: center;
        img {
          margin-left: 10px;
          width: 10%;
        }
      }

      button {
        padding: 0.5rem 1rem;
        border-radius: 5px;
      }
    }
  }
`;
