import styled from 'styled-components';

export const BackgroundSection = styled.section`
  font-family: 'roboto';
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 100px;

  @media (max-width: 700px) {
    margin: 50px;
  }

  h1 {
    color: #ffffff;
    font-size: 35px;
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 25px;
      margin: auto;
    }
  }

  div.notRanking {
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 70px auto;

    svg {
      width: 400px;
      height: 270px;

      @media (max-width: 450px) {
        width: 100%;
        height: 100%;
      }
    }

    span {
      color: #fff;
      font-size: 30px;
      padding: 30px 0;
      max-width: 500px;
      font-family: 'UniviaRegular';

      @media (max-width: 450px) {
        font-size: 20px;
      }
    }

    a {
      font-family: 'Roboto', sans-serif;
      color: #fafafa;
      font-size: 17px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 15px;
      outline: none;
      border: 0;
      padding: 0.5rem 3rem;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }
  }

  table {
    font-size: 16px;

    tbody {
      color: #ffffff;

      tr {
        margin-top: 20px;
        background: #222222;
        border-radius: 5px;

        border-width: 2px;
        border-style: solid;
        border-color: #363636;

        display: flex;
        justify-content: space-between;
        align-items: center;

        td {
          display: flex;
          align-items: center;
          img {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin: 0 20px 0 5px;
            object-fit: cover;
            cursor: pointer;
          }

          @media (max-width: 540px) {
            img {
              display: none;
            }
          }
        }

      }
      @media (min-width: 400px) and (max-width: 450px) {
        tr {
          width: 330px;
          margin: 20px auto 0;
        }
      }
      @media (max-width: 400px) {
        tr {
          width: 280px;
          margin: 20px auto 0;
        }
      }

      tr td span.names {
        color: #ffffff;
        font-size: 16px;
        font {
          font-size: 12px;
          color: #828282;

          @media (max-width: 400px) {
            display: none;
          }
        }
      }
      tr td span.orange {
        color: #fe5812;
        font-size: 18px;
      }
      tr td span.grey {
        color: #828282;
        font-size: 13px;
        margin: 0 10px;
        cursor: help;
      }
    }
  }
`;

export const EllipsisContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .ellipsis-dot {
    width: 10px;
    height: 10px;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 50%;
    margin: 10px 10px 5px;
  }
`;
