import React from 'react';
import { Link, useParams } from 'react-router-dom';

// import { useMessage } from '~/hooks/message';

// import api from '~/services/api';
import Check from '~/assets/img/check.svg';

import * as S from './styles';

export default function EmailValidation() {
    const { message } = useParams();

    return (
        <S.SectionNotMusic>
            <div>
                <img src={Check} alt="Email enviado com sucesso" />
                <span className="sucess">Solicitação enviada.</span>
                <span className="validation">{message}</span>
                <Link to="/">Voltar para o início</Link>
            </div>
        </S.SectionNotMusic>
    );
}
