import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

export default function Terms() {
    return (
        <S.BackgroundSectionTerms>
            <S.DivTerms className="cont">
                <h1>Política de Privacidade e Termos de uso</h1>
                <h6>POLÍTICA DE PRIVACIDADE</h6>
                <p>
                    Os dados solicitados pelo Vagalume no momento do cadastro
                    são de uso exclusivo do site e não são compartilhados com
                    terceiros sem que haja autorização do usuário.
                </p>
                <h6>TERMOS DE USO</h6>
                <p>
                    O uso do site "vagalume.com.br" e de seus serviços
                    disponíveis é sujeito ao disposto nas cláusulas que se
                    seguem, e sujeita todos os usuários, cadastrados ou não, ao
                    seu cumprimento integral, e o seu desrespeito pode acarretar
                    conseqüências judiciais.
                </p>
                <h6>SERVIÇOS OFERECIDOS</h6>
                <p>
                    Um dos serviços oferecidos pelo Vagalume constitui-se de
                    acesso à perfis de divulgação de artistas que são mantidos
                    pelos fãs, gravadoras, assessores ou os próprios artistas.
                    Os perfis incluem letras de músicas, traduções, vídeos
                    disponibilizados no Youtube e outros canais de vídeo (com
                    legenda), criação de playlists (sequências de execução de
                    músicas), rede social para interação com os usuários, fotos
                    de divulgação e outras informações que colaboram para a
                    análise de popularidade, divulgação do trabalho de artistas
                    através de vários canais como notícias, etc...
                </p>
                <p>
                    O usuário do Vagalume também tem acesso a serviços para
                    criar seu próprio perfil para criar playlists e acessar
                    áreas restritas do site, onde é necessário cadastro para
                    haver interação. O usuário é totalmente responsável pelas
                    informações que edita ou interage com site, sem que o
                    Vagalume se responsabilize por qualquer informação. Sua
                    senha é pessoal e intransferível.
                </p>
                <Link to="/cadastro">Voltar</Link>
            </S.DivTerms>
        </S.BackgroundSectionTerms>
    );
}
