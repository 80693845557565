import styled from 'styled-components';
import { shade } from 'polished';

export const UlPagination = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 20px; */

  li + li {
    margin-left: 1rem;
  }

  li.paginatin__item--disabled {
    display: none;
  }

  button {
    background: none;
    /* font-weight: bold; */
    border: none;
    color: #fff;

    > svg {
      font-size: 30px;
    }

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }

  .paginatin__item--active {
    color: #87868b;
    cursor: default;
    font-weight: 600;
    &:hover {
      color: #87868b;
    }
    /* width: 30px;
    height: 30px;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 50%; */
  }
`;
