import React from 'react';

import { Container } from './styles';

function TermsUse() {
  return (
    <Container>
      <h1>TERMOS E CONDIÇÕES DE USO</h1>

      <h2>
        ACORDEON<font>®</font>
      </h2>
      <p>
        <strong> ACORDEON REGISTRO DE MUSICA ONLINE LTDA, </strong> pessoa
        jurídica de direito privado, inscrita no CNPJ n° 39.305.821/0001-71, com
        sede na Avenida Amintas Barros, n° 3700, Ed. CTC, Bloco B, Sala 2104,
        bairro Lagoa Nova, CEP n° 59.075-810, na cidade de Natal/RN.{' '}
      </p>

      <h2>CONTA</h2>
      <p>
        <strong>1.</strong> Para acessar os serviços e conteúdo da ACORDEON será
        necessário o COMPOSITOR fazer, primeiramente, uma conta ("Criar conta"),
        gratuitamente, apenas com o nome artístico, e-mail e senha, como também
        aceitar esses Termos e Condições de Uso e as Políticas de Privacidade.{' '}
        <br /> <strong>1.1.</strong> O COMPOSITOR receberá um e-mail de
        confirmação informando que sua conta já estará efetivada. <br />{' '}
        <strong>1.2.</strong> Para acessar a plataforma, o COMPOSITOR apenas
        necessitará informar o login e a senha.
      </p>

      <h2>PERFIL</h2>
      <p>
        <strong>2.</strong> O COMPOSITOR fará seu perfil, como um cadastro.{' '}
        <br />
        <strong>2.1.</strong> O COMPOSITOR escolherá uma imagem para compor seu
        Avatar, ou mesmo alguma fotografia de sua preferência, preencherá campos
        referentes a dados pessoais, endereço, contato e seu perfil na
        ACORDEON®, confirmando o nome artístico e seu e-mail. <br />
        <strong>2.2.</strong> O nome artístico após criado, só poderá ser
        alterado com solicitação à ACORDEON pelo seguinte contato:
        suporte@registreacordeon.com.br. <br />
        <strong>2.3.</strong> Os campos para cadastro precisam conter
        informações exatas, precisas e verdadeiras. <br />
        <strong>2.4.</strong> O COMPOSITOR assume o compromisso de atualizar o
        seu perfil sempre que nele ocorrer alguma alteração, bem como garante e
        responde, em qualquer caso, pela veracidade, exatidão e autenticidade
        dos dados pessoais cadastrados. <br />
        <strong>2.5.</strong> A ACORDEON não se responsabiliza pela correção dos
        dados pessoais inseridos pelo COMPOSITOR. <br />
        <strong>2.6.</strong> A ACORDEON se reserva o direito de utilizar todos
        os meios válidos e possíveis para identificar a pessoa do COMPOSITOR,
        bem como solicitar dados adicionais e documentos que estime serem
        pertinentes, a fim de conferir os dados pessoais informados. <br />
      </p>

      <h2>CAPACIDADE</h2>
      <p>
        <strong>3.</strong> O COMPOSITOR declara ser maior de 18 (dezoito) anos
        de idade, gozando este de plena capacidade, sendo responsável pelos seus
        atos e dados, civil e criminalmente. <br />
        <strong>3.1.</strong> Caso o COMPOSITOR seja menor de 18 (dezoito) anos,
        deverá ser assistido por seus responsáveis legais, os quais declaram que
        leram estes termos e condições de uso com o COMPOSITOR, auxiliando-o na
        compreensão deste documento, e que o COMPOSITOR e seus responsáveis
        legais concordam com todos os termos e condições aqui descritos. <br />
        <strong>3.2.</strong> Os responsáveis legais do menor COMPOSITOR são
        legitimamente responsáveis civil e criminalmente por toda e qualquer
        compra realizada, violação cometida ou declaração falsa, incompleta,
        desatualizada ou imprecisa prestada pelo menor COMPOSITOR. <br />
      </p>

      <h2>CRÉDITOS</h2>
      <p>
        <strong>4.</strong> A moeda utilizada na plataforma ACORDEON é chamada
        de CRÉDITO. <br />
        <strong>4.1.</strong> O preço do CRÉDITO tem um valor fixo de R$ 19,90
        (dezenove reais e noventa centavos). <br />
        <strong>4.2.</strong> No preço previsto no Item 4.1, já estão
        contabilizados todos os impostos e taxas incidentes. <br />
        <strong>4.3.</strong> Os registros das composições musicais ocorrerão
        através dos CRÉDITOS adquiridos, podendo ser registradas apenas
        individualmente, logo cada composição musical equivale a 01 (um)
        CRÉDITO. <br />
        <strong>4.4.</strong> O COMPOSITOR poderá adquirir créditos
        ilimitadamente, bem como ficarão disponíveis para o COMPOSITOR
        utilizá-los nos registros de suas composições por tempo indeterminado.{' '}
        <br />
        <strong>4.5.</strong> A ACORDEON poderá, a qualquer momento, alterar o
        preço dos créditos, bônus, planos e promoções, sendo certo que
        comunicará qualquer alteração de preço para o COMPOSITOR
        antecipadamente. <br />
        <strong>5.</strong> As aquisições dos referidos CRÉDITOS ocorrerão por
        meio de cartão de crédito ou boleto bancário. <br />
        <strong>5.1.</strong> Para a aquisição dos CRÉDITOS, mediante cartão de
        crédito, a ACORDEON necessitará das seguintes informações: número do
        cartão de crédito, nome do titular da conta conforme descrito no cartão
        de crédito, data de validade do cartão de crédito, código de segurança,
        a quantidade de parcelas, dentre outras informações. <br />
        <strong>5.2.</strong> O COMPOSITOR poderá, ainda, salvar cartão para as
        próprias compras ou defini-lo como padrão. <br />
        <strong>5.3.</strong> Durante o processo de compra e antes da conclusão
        do pedido e pagamento, o COMPOSITOR terá acesso ao resumo da compra,
        informando a quantidade de créditos a serem adquiridos; bônus em
        crédito, se houver; pontos ganhos com a transação; o total de crédito
        que terá após a aquisição da compra; o número de parcelas; e, o total da
        compra. <br />
        <strong>5.3.</strong> Por fim, para concluir a compra, o COMPOSITOR terá
        que concordar com estes Termos e Condições de Uso. <br />
        <strong>5.4.</strong> O COMPOSITOR acompanhará suas compras em MINHAS
        COMPRAS, onde conterá o histórico de todas as aquisições do COMPOSITOR,
        informando, portanto, a data, o número da ordem, os créditos, os bônus
        ganhos, os pontos, o valor, o tipo, e o status. <br />
        <strong>5.5.</strong> O COMPOSITOR poderá acompanhar seu histórico em
        CRÉDITOS, onde poderá ter acesso a data da aquisição do crédito, os
        créditos que foram adquiridos, bem como os créditos que foram
        utilizados, e o saldo atual dos créditos. <br />
        <strong>5.6.</strong> O saldo de CRÉDITOS é apresentado ao COMPOSITOR na
        página inicial da ACORDEON. <br />
        <strong>6.</strong> A ACORDEON solicita ao COMPOSITOR a certificar-se
        que todos os campos estão preenchidos e que as informações introduzidas
        são corretas. <br />
        <strong>6.1.</strong> O COMPOSITOR é responsável pelo preenchimento dos
        dados de pagamento. <br />
        <strong>6.2.</strong> A ACORDEON não é responsável por pedidos não
        concluídos devido a dados do COMPOSITOR incorretos ou insuficientes.{' '}
        <br />
        <strong>7.</strong> Todas as operações de pagamentos da ACORDEON serão
        realizadas através de empresa terceirizada, responsável pela gestão dos
        pagamentos e administradoras de cartão de crédito, PAGAR.ME PAGAMENTOS
        S.A. (CNPJ n° 17.727.053/0001-74). <br />
        <strong>7.1.</strong> A ACORDEON® poderá não intervir nos resultados da
        relação do COMPOSITOR com a empresa responsável por gestão dos
        pagamentos, uma vez que esta administra suas operações independentemente
        da ACORDEON®. <br />
        <strong>7.2.</strong> A ACORDEON® não poderá garantir de forma nenhuma
        que os serviços prestados pela empresa responsável pela gestão de
        pagamentos funcionarão livre de erros, interrupções, mal funcionamentos,
        atrasos ou outras imperfeições. <br />
        <strong>8.3.</strong> A ACORDEON® não será responsável pela
        disponibilidade ou não dos serviços prestados pela empresa responsável
        pela gestão de pagamentos ou pela impossibilidade do uso do serviço.
      </p>

      <h2>PONTOS</h2>
      <p>
        <strong>8.</strong> Os pontos são concedidos a título de bonificação.{' '}
        <br />
        <strong>8.1.</strong> A quantidade de PONTOS para cada ação do
        COMPOSITOR ficará a critério exclusivo da ACORDEON®. <br />
        <strong>8.2.</strong> As principais formas de ganhar PONTOS na ACORDEON®
        são: compra de créditos e registros de música. <br />
        <strong>8.3.</strong> Ficará a critério exclusivo da ACORDEON® a
        quantidades de PONTOS que o COMPOSITOR ganhará. <br />
        <strong>8.4.</strong> A quantidade de PONTOS será devidamente informada,
        se houver. <br />
        <strong>8.5.</strong> Na página de PONTOS, em GANHAR PONTOS, o
        compositor terá acesso as ações disponíveis para ganhar PONTOS. <br />
        <strong>8.6.</strong> Os PONTOS não possuem prazo de validade, logo não
        se expiram ao longo do tempo, ficando à disposição do COMPOSITOR para
        utilizá-los quando quiser. <br />
        <strong>9.</strong> O saldo de PONTOS é apresentado ao COMPOSITOR na
        página inicial da ACORDEON®. <br />
        <strong>9.1.</strong> O COMPOSITOR terá acesso ao histórico de todos os
        PONTOS gerados a partir de suas ações na ACORDEON®, com suas respectivas
        datas e pontuação adquirida, em PONTOS, na página inicial. <br />
        <strong>10.</strong> Ao utilizar os PONTOS, a ACORDEON® debitará dos
        mais antigos para os mais recentes. <br />
        <strong>10.1.</strong> O COMPOSITOR poderá utilizar seus PONTOS somente
        em serviços e produtos oferecidos pela ACORDEON®. <br />
        <strong>11.</strong> Os PONTOS são pessoais e intransferíveis, sendo
        vedada a sua cessão a terceiros, a qualquer título, inclusive por
        sucessão ou herança. <br />
        <strong>11.1.</strong> É expressamente proibida a negociação pelo
        COMPOSITOR dos PONTOS, incluindo, mas não se limitando a compra, venda,
        cessão ou permuta. <br />
        <strong>11.2.</strong> Havendo comprovação de tais práticas ocorrerá a
        imediata exclusão do COMPOSITOR na ACORDEON® e o cancelamento de seus
        PONTOS, independentemente de serem tomadas as medidas cíveis e criminais
        cabíveis.
      </p>

      <h2>SERVIÇOS</h2>
      <p>
        <strong>12.</strong> A ACORDEON® disponibiliza ao COMPOSITOR serviços
        gratuitos e pagos. <br />
        <strong>12.1.</strong> A ACORDEON® disponibiliza ao Compositor acesso ao
        seu sistema de forma gratuita, isto é, não exige pagamento, basta estar
        devidamente cadastrado e efetuado login para consumir todo serviço
        oferecido. <br />
        <strong>13.</strong> Serviço Gratuito: TUTORIAIS é um ambiente de
        educação para o COMPOSITOR, com streaming de vídeo aulas e outros
        conteúdos que venham a ser desenvolvidos pela ACORDEON®. <br />
        <strong>14.</strong> A ACORDEON disponibiliza ao COMPOSITOR o REGISTRO
        DE MÚSICA como serviço pago. <br />
        <strong>14.1.</strong> O REGISTRO DE MÚSICA acontece de forma totalmente
        online utilizando a certificação de Infraestrutura de Chaves Públicas
        Brasileira, o ICP-BRASIL, obedecendo todos os requisitos e
        obrigatoriedades estabelecidas na Medida Provisória n° 2.200-2, de 24 de
        agosto de 2001, bem como garante a declaração de autoria assinada
        digitalmente e com carimbo de tempo, além de ter validade jurídica em
        todos os 175 (cento e setenta e cinco) países signatários da Convenção
        de Berna para a Proteção de Obras Literárias e Artísticas, tratado
        internacional de Direitos Autorais, do qual o Brasil faz parte. <br />
        <strong>14.2.</strong> O COMPOSITOR terá acesso ao acompanhamento de se
        seus registros em MINHAS MÚSICAS, verificando a data de registro; o nome
        da música; coautor, se houver; a composição na íntegra; a letra da
        música, se houver; e, o status da sua composição. <br />
        <strong>15.</strong> Os serviços da ACORDEON® são exclusivamente para
        uso pessoal, não poderão ser utilizados para fins comerciais. <br />
        <strong>16.</strong> O COMPOSITOR poderá interagir com a ACORDEON® e com
        outros compositores por meio de comentários, enquetes, testes, fóruns,
        promoções, dentre outras formas de interação disponibilizadas nesta
        plataforma. <br />
        <strong>17.</strong> O COMPOSITOR deve acessar ou usar os serviços da
        ACORDEON® visando tomar cuidado em qualquer ação que possa prejudicar ou
        trazer danos a terceiros.
      </p>

      <h2>CERTIFICAÇÃO</h2>
      <p>
        <strong>18.</strong> A ACORDEON® utiliza a certificação Infraestrutura
        de Chaves Públicas Brasileira, o ICP-BRASIL, para o registro das
        músicas, o que garante autenticidade, integridade e validade jurídica
        das composições em 172 (cento e setenta e dois) países, emitidas com a
        respectiva data e autoria(s), em forma eletrônica e com carimbo de
        tempo, nos termos da Medida Provisória n° 2.200-1, de 24 de agosto
        de2001, que institui a ICP-Brasil.
        <br />
        <strong>19.</strong> A certificação utilizada pela ACORDEON® está em
        concordância com a Lei n°9.610, de 19 de fevereiro de 1998, que
        consolida a legislação sobre direitos autorais. <br />
        <strong>19.1.</strong> São obras intelectuais as composições musicais,
        tenham ou não letra, designadas como direitos autorais.
        <br />
        <strong>19.2.</strong> O registro da composição musical é meramente
        declaratório, fazendo-se prova da anterioridade de autoria, servindo,
        também, de prova judicial, por obedecer, rigorosamente, as legislações
        brasileiras, de modo a garantir a inviolabilidade do documento.
        <br />
        <strong>19.3.</strong> Para a segurança dos direitos do COMPOSITOR, o
        registro da composição musical pela ACORDEON® apresenta a mesma validade
        da Biblioteca Nacional e da Escola de Belas Artes. <br />
        <strong>20.</strong> O certificado de registro da composição musical da
        ACORDEON® conterá as seguintes informações: nomes do autor(a),
        cotitular(es), e intérpretes, se houver, acompanhados dos seus
        respectivos CPF´s; título da composição; letra da composição, se houver;
        data, horário e código do registro; selo ACORDEON®; QR-Code da obra
        registrada e certificação ICP-Brasil; código HASH; código de
        verificação; e informações da empresa ACORDEON®.
      </p>

      <h2>REGRAS DE CONDUTA</h2>
      <p>
        <strong>21.</strong> O COMPOSITOR obriga-se a utilizar a ACORDEON®
        respeitando e observando estes Termos e Condições de Uso, bem como as
        legislações vigentes, os costumes e a ordem pública. <br />
        <strong>22.</strong> Com relação ao uso da ACORDEON®, o COMPOSITOR
        concorda em NÃO:
        <br />
        <strong>a)</strong> Violar quaisquer direitos de propriedade intelectual
        ou de privacidade, incluindo, sem limitação, direitos autorais de
        quaisquer terceiros;
        <br />
        <strong>b)</strong> Violar quaisquer direitos de propriedade intelectual
        ou de privacidade, incluindo, sem limitação, marcas, segredos
        comerciais, patentes ou direitos autorais da ACORDEON®, das empresas
        parceiras e dos professores convidados da ACORDEON®;
        <br />
        <strong>c)</strong> Copiar, redistribuir, reproduzir, gravar,
        transferir, executar ou exibir ao público, divulgar, ou disponibilizar
        ao público, quaisquer partes dos serviços da ACORDEON® ou de seus
        conteúdos;
        <br />
        <strong>d)</strong> Executar atos que limitem ou impeçam o acesso e a
        utilização da ACORDEON®, em condições adequadas, aos demais
        compositores;
        <br />
        <strong>e)</strong> Postar conteúdo que é abusivo ou ilegal, contendo
        textos, imagens, fotos ou vídeos, letras, músicas que tenham, ainda que
        implicitamente, caráter calunioso, difamatório, discriminatório,
        obsceno, pornográfico, ofensivo, ameaçador, vexatório, prejudicial, que
        manifeste ódio contra pessoas ou grupos específicos, tais como insultos
        religiosos, raciais, de gênero ou de orientação sexual, ou que viole ou
        incentive a violação a quaisquer direitos humanos;
        <br />
        <strong>f)</strong> Fazer anúncios publicitários ou qualquer outro tipo
        de publicidade ou propaganda de terceiros, sem a expressa autorização da
        ACORDEON® por escrito;
        <br />
        <strong>g)</strong> Carregar ou redirecionar outros compositores para
        vírus ou códigos maliciosos (malware e spyware), bem como anexar links
        nos comentários, exceto se for rede social ou lista de reprodução da
        ACORDEON®.;
        <br />
        <strong>h)</strong> Fornecer ou divulgar, de maneira voluntária ou
        consentida, informações e materiais enganosos com a intenção de fraudar
        e enganar os demais compositores;
        <br />
        <strong>i)</strong> Acessar ou coletar dados de maneira automatizada sem
        a prévia autorização da ACORDEON® ou fazer “raspagem” de informações,
        incluindo, mas não se limitando a spiders, scripts e softwares;
        <br />
        <strong>j)</strong> Utilizar procedimentos e técnicas que impliquem em
        uma carga desproporcional na infraestrutura e rede da ACORDEON®, capazes
        de prejudicar a estabilidade de nossos sistemas e sites;
        <br />
        <strong>k)</strong> Vender, alugar, sublicenciar ou locar qualquer parte
        do serviço ACORDEON® ou do seu conteúdo.
        <br />
        <strong>l)</strong> Contornar ou bloquear anúncios no serviço ACORDEON®,
        ou criar e distribuir ferramentas concebidas para bloquear anúncios no
        serviço ACORDEON®;
        <br />
        <strong>m)</strong> Fornecer sua senha para qualquer outra pessoa ou
        usar o nome de usuário e senha de qualquer outro compositor;
        <br />
        <strong>n)</strong> Realizar quaisquer atos que de alguma forma possam
        implicar qualquer prejuízo ou dano à ACORDEON® ou a outros compositores;
        <br />
        <strong>o)</strong> Realizar ou permitir engenharia reversa, traduzir,
        modificar, alterar a linguagem, compilar, decompilar, modificar,
        reproduzir, alugar, sublocar, divulgar, transmitir, distribuir, usar ou,
        de outra maneira, dispor da plataforma da ACORDEON® ou das ferramentas e
        funcionalidades nele disponibilizadas sob qualquer meio ou forma,
        inclusive de modo a violar direitos da ACORDEON® e/ou de terceiros;
        <br />
        <strong>p)</strong> Interferir na segurança ou cometer usos indevidos
        contra a plataforma ou qualquer recurso do sistema, rede ou serviço
        conectado ou que possa ser acessado por meio da plataforma, devendo
        acessar o sistema apenas para fins lícitos e autorizados;
        <br />
        <strong>q)</strong> Utilizar o domínio da ACORDEON® para criar links ou
        atalhos a serem disponibilizados em e-mails não solicitados (mensagem
        spam) ou em websites de terceiros ou do próprio COMPOSITOR ou, ainda,
        para realizar qualquer tipo de ação que possa vir a prejudicar a
        ACORDEON® ou terceiros; <br />
        <strong>r)</strong> Violar, copiar ou infringir quaisquer direitos de
        terceiros, incluindo direitos autorais, direitos de propriedade
        intelectual e industrial, direito à privacidade (sua e de terceiros) ou
        qualquer outro direito de personalidade;
        <br />
        <strong>s)</strong> Violar estes Termos e Condições de Uso e a Política
        de Privacidade da ACORDEON®.
        <br />
        <strong>23.</strong> Qualquer intromissão, tentativa de, ou atividade
        que viole ou contrarie as leis de direito de propriedade intelectual
        e/ou as proibições estipuladas neste documento, tornará o COMPOSITOR
        responsável, assim, passível das ações legais pertinentes, bem como das
        sanções previstas neste Termos e Condições de Uso, sendo ainda
        responsável pelas indenizações por eventuais danos causados. <br />
        <strong>23.1.</strong> A ACORDEON® poderá, a seu exclusivo critério,
        bloquear, restringir, desabilitar ou impedir o acesso do COMPOSITOR ao
        sistema, total ou parcialmente, sem qualquer aviso prévio, sempre que
        for detectada uma conduta inadequada do COMPOSITOR, sem prejuízo das
        medidas administrativas, extrajudiciais e judiciais que julgar
        convenientes. <br />
        <strong>24.</strong> O COMPOSITOR se compromete a denunciar quaisquer
        abusos e violações destes Termos e Condições de Uso praticados contra
        terceiros ou contra si.
        <br />
        <strong>24.1.</strong> Para reportar violações, o COMPOSITOR deve entrar
        em contato conosco pelo e-mail suporte@registreacordeon.com.br.
      </p>

      <h2>PROPRIEDADE INTELECTUAL</h2>
      <p>
        <strong>25.</strong> Os serviços e conteúdos disponibilizados nesta
        plataforma ao COMPOSITOR são de propriedade exclusiva da ACORDEON®.
        <br />
        <strong>25.1.</strong> A ACORDEON® possui suas marcas devidamente
        registradas pelo Instituto Nacional da Propriedade Industrial – INPI,
        logo lhe é assegurado o direito de uso exclusivo em todo território
        nacional, sendo vedado a utilização das marcas registradas da ACORDEON®
        indevidamente pelo COMPOSITOR. <br />
        <strong>25.2.</strong> Todas as marcas, nomes comerciais, logotipos,
        nomes de domínio da ACORDEON® e quaisquer outras características são de
        propriedade exclusiva da ACORDEON®.
        <br />
        <strong>25.3.</strong> Todos os materiais exibidos ou disponibilizados
        na plataforma da ACORDEON®, como gráficos, documentos, imagens, textos,
        vídeos, áudios, software e código HTML são de propriedade exclusiva da
        ACORDEON® ou de seus fornecedores de conteúdo, os quais reservam para si
        todos os seus direitos de propriedade intelectual.
        <br />
        <strong>25.4.</strong> Alguns conteúdos publicados pela ACORDEON® podem
        ser de titularidades de terceiros.
        <br />
        <strong>25.5.</strong> A ACORDEON® respeita os direitos dos titulares de
        propriedade intelectual.
        <br />
        <strong>25.6.</strong> Em alguns casos, a plataforma da ACORDEON® poderá
        linkar outros sites, o que não significa que sejam de propriedade ou
        operados pela ACORDEON®. Portanto, a ACORDEON® não será responsável
        pelos conteúdos, práticas e serviços ofertados nos mesmos.
        <br />
        <strong>26.</strong> Todo conteúdo publicado pela ACORDEON® é para uso
        pessoal do COMPOSITOR. <br />
        <strong>26.1.</strong> Copiar, armazenar ou divulgar os conteúdos
        disponibilizados pela ACORDEON® para qualquer outra finalidade que não
        para uso pessoal é estritamente proibido sem a autorização por escrito
        da ACORDEON® ou do titular dos direitos de propriedade intelectual dos
        conteúdos publicados pela ACORDEON®. <br />
        <strong>26.2</strong> O uso indevido e a reprodução total ou parcial dos
        conteúdos são proibidos, salvo autorização expressa da ACORDEON® ou de
        terceiros autores dos conteúdos disponibilizados.
        <br />
        <strong>26.3.</strong> É vedada a criação de quaisquer obras derivadas
        de qualquer propriedade intelectual da ACORDEON® sem a autorização
        prévia e por escrito da ACORDEON.
        <br />
        <strong>26.4.</strong> É expressamente proibido ao COMPOSITOR
        reproduzir, modificar, exibir e criar trabalhos derivados ou qualquer
        outra forma de utilização de qualquer propriedade intelectual ou outro
        conteúdo desta plataforma e dos materiais veiculados no ou pela
        ACORDEON®.
        <br />
        <strong>27.</strong> O COMPOSITOR compromete-se a se abster de fazer
        qualquer uso das marcas registradas da ACORDEON® ou de suas variações,
        nome de domínio ou parte de nome de domínio ou em qualquer nome de
        empresa, de qualquer tipo ou natureza, sob qualquer meio ou forma,
        inclusive por meio de criação de nomes de domínio ou e-mails.
        <br />
        <strong>27.1.</strong> O COMPOSITOR se compromete a denunciar quaisquer
        abusos e violações destes Termos e Condições de Uso praticados contra
        terceiros ou contra si.
        <br />
        <strong>28.</strong> O COMPOSITOR que violar as proibições contidas nas
        legislações e tratados internacionais sobre propriedade intelectual e
        nestes Termos e Condições de Uso será responsabilizado, civil e
        criminalmente, pelas infrações cometidas.
        <br />
        <strong>29.</strong> As fotos e imagens utilizadas no site da ACORDEON®
        podem não refletir seu tamanho original ou situação atual do cenário
        reproduzido, sendo meramente ilustrativas. <br />
        <strong>30.</strong> Se a ACORDEON® for notificada por um detentor de
        direitos autorais de que qualquer conteúdo viola direitos autorais, a
        ACORDEON® poderá, a seu exclusivo critério, remover tal conteúdo da
        plataforma, ou tomar outras medidas que a ACORDEON® considerar adequada,
        sem a prévia notificação para o COMPOSITOR ou outra parte que tenha
        fornecido ou postado o conteúdo, inclusive a própria ACORDEON®. <br />
        <strong>30.1.</strong> Caso o COMPOSITOR ou outra parte acredite que o
        conteúdo não é ilícito poderá, em determinadas circunstâncias,
        apresentar uma contranotificação à ACORDEON® com pedido para restaurar o
        conteúdo removido, o qual a ACORDEON® poderá ou não cumprir, por ser
        critério exclusivo da ACORDEON®.
        <br />
        <strong>31.</strong> Se o COMPOSITOR acredita que qualquer conteúdo
        viola seus direitos de propriedade intelectual, o que inclui os seus
        direitos autorais, ou outros direitos, bem como de terceiros, deverá
        informar e notificar imediatamente a ACORDEON® através do e-mail
        suporte@registreacordeon.com.br incluindo as seguintes informações e
        documentos:
        <br />
        • Assinatura manuscrita ou eletrônica do titular dos direitos autorais
        supostamente violados ou seu responsável legal; (6)
        <br />
        • Identificação específica de cada obra sujeita aos direitos autorais
        supostamente violados; (2)
        <br />
        • Descrição da localização onde se encontra o material supostamente
        violado na ACORDEON com o máximo de precisão possível e a URL, a fim de
        ajudar a localizar o material reivindicado; (1)
        <br />
        • Informações de contato da parte requerente como nome completo,
        endereço, telefone e e-mail; (4)
        <br />
        • Declaração de que a parte requerente acredita, de boa-fé, que o uso
        do(s)material(is) da maneira reivindicada não está autorizado pelo
        titular dos direitos autorais, por seu representante ou pela lei; (3)
        <br />
        • Declaração de que as informações constantes na notificação são
        precisas e, sob pena de perjúrio, de que a parte requerente está
        autorizada a agir em nome do titular de um direito exclusivo
        supostamente violado. (5)
        <br />
        <strong>31.1.</strong> Em caso de contas relacionadas a COMPOSITORES
        identificados como infratores reincidentes, a ACORDEON® poderá, a seu
        critério exclusivo, encerrar a respectiva conta.
      </p>

      <h2>PRIVACIDADE</h2>
      <p>
        <strong>32.</strong> A ACORDEON toma todas as medidas necessárias para
        proteger a privacidade e manter a confidencialidade e a segurança do
        COMPOSITOR, em atendimento à legislação em vigor. <br />
        <strong>32.1.</strong> O COMPOSITOR deverá ler a Política de Privacidade
        da ACORDEON® para entender melhor e em detalhes as formas de coleta,
        guarda, utilização, compartilhamento e divulgação de sus dados pessoais,
        bem como aponta as medidas tomadas para assegurar a proteção dos dados
        coletados. <br />
        <strong>32.2.</strong> Em caso de dúvidas sobre a proteção à privacidade
        e dados pessoais ou para obter maiores informações sobre privacidade,
        dados e casos, o COMPOSITOR deve consultar através do
        suporte@registreacordeon.com.br.
      </p>

      <h2>RESPONSABILIDADE</h2>
      <p>
        <strong>33.</strong> O COMPOSITOR é o único responsável:
        <br />
        • por manter a confidencialidade de sua senha de acesso aos serviços da
        ACORDEON®;
        <br />
        • pelo conteúdo de seus comentários e interações;
        <br />
        • pelas suas composições e demais obras intelectuais;
        <br />
        • pelas operações de pagamentos efetuadas em sua conta;
        <br />
        • pelo preenchimento dos dados pessoais;
        <br />
        • pela veracidade, exatidão e autenticidade dos dados pessoais
        cadastrados;
        <br />
        • pela utilização da plataforma, de suas ferramentas e funcionalidades;
        <br />
        • por todas as atividades que forem realizadas através de sua conta;
        <br />
        • por avisar imediatamente a ACORDEON® caso ocorra qualquer utilização
        não autorizada de sua conta ou qualquer quebra de segurança.
        <br />
        <strong>34.</strong> A ACORDEON® não será responsável pelo efetivo
        cumprimento das obrigações assumidas pelo COMPOSITOR:
        <br />
        • pelo lucro cessante ou por qualquer outro dano e/ou prejuízo que o
        COMPOSITOR possa sofrer devido as suas composições já registradas
        através da plataforma;
        <br />
        • por qualquer dano, prejuízo ou perda no equipamento do COMPOSITOR
        causado por falhas no sistema, no servidor ou na internet decorrentes de
        condutas de terceiros;
        <br />
        • pela disponibilidade ou não dos serviços prestados pela empresa
        responsável pela gestão de pagamentos;
        <br />
        • pela correção dos dados pessoais inseridos pelo COMPOSITOR.
        <br />
        <strong>35.</strong> A ACORDEON® não é proprietária e nem tampouco
        possuidora das composições registradas pelo COMPOSITOR em nossas
        plataformas, bem como não realiza ofertas/vendas em seu nome.
      </p>

      <h2>INDENIZAÇÃO</h2>
      <p>
        <strong>36.</strong> O COMPOSITOR concorda em indenizar a ACORDEON® e
        manter indene a ACORDEON® contra todos os danos, perdas e despesas de
        qualquer natureza (incluindo custos e honorários advocatícios razoáveis)
        decorrentes de um ou relacionados com:
        <br />
        <strong>I</strong> - violação de parte do COMPOSITOR dos acordos que por
        ventura sejam propostos, estes Termos e Condições de Uso e/ou a Política
        de Privacidade;
        <br />
        <strong>II</strong> - qualquer atividade na qual o COMPOSITOR se envolva
        no ou através do serviço ACORDEON®;
        <br />
        <strong>III</strong> - violação de parte do COMPOSITOR de qualquer lei
        ou dos direitos de terceiros.
      </p>

      <h2>SUPORTE TÉCNICO</h2>
      <p>
        <strong>37.</strong> A ACORDEON® se utilizará de todas as ferramentas de
        soluções técnicas que estejam à disposição, pela equipe de suporte
        técnico ACORDEON®, para permitir o bom funcionamento e acesso do
        COMPOSITOR aos serviços da plataforma 24(vinte e quatro) horas por dia,
        07 (sete) dias por semana. <br />
        <strong>38.</strong> A ACORDEON® dedicará todos os esforços possíveis
        para responder a todas as consultas de suporte ao COMPOSITOR dentro de
        um prazo razoável. <br />
        <strong>38.1.</strong> A navegação na plataforma ACORDEON® ou em
        alguma(s) página(s) da ACORDEON® poderão ser interrompidas, limitadas ou
        suspensas para atualizações, modificações ou qualquer ação necessária ao
        seu bom e fiel funcionamento. <br />
        <strong>38.2.</strong> Para reportar instabilidades dos serviços e/ou
        páginas da plataforma ACORDEON®, o COMPOSITOR deverá entrar em contato
        por e-mail suporte@registreacordeon.com.br.
      </p>

      <h2>CANCELAMENTO</h2>
      <p>
        <strong>39.</strong> Caso deseje, o COMPOSITOR poderá realizar o
        cancelamento dos serviços da ACORDEON® a qualquer momento, basta entrar
        em contato através do nosso e-mail suporte@registreacordeon.com.br.{' '}
        <br />
        <strong>39.1.</strong> Após o cancelamento, o COMPOSITOR receberá um
        e-mail de confirmação e o acesso aos serviços da ACORDEON® será
        suspenso. <br />
        <strong>40.</strong> A ACORDEON® por sua própria liberalidade poderá
        terminar ou suspender, definitiva ou temporariamente, o acesso do
        COMPOSITOR à plataforma, em virtude de violação destes Termos e
        Condições de Uso e/ou da Política de Privacidade.
      </p>

      <h2>FALE CONOSCO</h2>
      <p>
        <strong>41.</strong> Se o COMPOSITOR tiver alguma dúvida sobre a
        ACORDEON®, os serviços dessa plataforma, este documento ou até mesmo a
        Política de Privacidade, entre em contato com a ACORDEON® pelo e-mail
        suporte@registreacordeon.com.br.
      </p>

      <h2>DISPOSIÇÕES GERAIS</h2>
      <p>
        <strong>42.</strong> A plataforma é controlada, operada e administrada
        pela própria ACORDEON®, que se localiza em Natal, Estado do Rio Grande
        do Norte, Brasil.
        <br />
        <strong>43.</strong> A plataforma da ACORDEON® poderá ser acessada pelo
        COMPOSITOR por qualquer dispositivo conectado à internet,
        independentemente de sua localização geográfica.
        <br />
        <strong>44.</strong> Em vista das diferenças que podem existir entre as
        legislações locais, nacionais e internacionais, ao acessar a plataforma
        ACORDEON®, o COMPOSITOR concorda que a legislação aplicável para fins
        destes Termos e Condições de Uso será vigente na República Federativa do
        Brasil. <br />
        <strong>45.</strong> Estes Termos e Condições de Uso têm duração
        indefinida e permanecerão em vigor enquanto a plataforma estiver ativa.
        <br />
        <strong>45.1.</strong> O acesso e a utilização da plataforma e dos
        recursos por ela oferecidos têm, em princípio, duração indeterminada, a
        exclusivo critério da ACORDEON®. <br />
        <strong>45.2.</strong> A ACORDEON® reserva-se o direito de suspender
        e/ou cancelar de forma unilateral e a qualquer momento o acesso a
        plataforma ou a algumas de suas partes ou a alguns de seus recursos.{' '}
        <br />
        <strong>46.</strong> A ACORDEON® e o COMPOSITOR concordam que o Foro da
        Comarca de Natal/RN será o único competente para dirimir qualquer
        questão ou controvérsia oriunda ou resultante do uso desta plataforma,
        renunciando expressamente a qualquer outro, por mais privilegiado que
        seja, ou venha a ser. <br />
        <strong>47.</strong> A ACORDEON® poderá unilateralmente revisar,
        aprimorar, modificar e/ou atualizar, a qualquer momento, qualquer
        cláusula ou disposição contida nestes Termos e Condições de Uso. <br />
        <strong>47.1.</strong> A continuidade de acesso ou utilização desta
        plataforma, depois da divulgação de quaisquer modificações, confirmará a
        aceitação dos novos Termos e Condições de Uso da ACORDEON® pelo
        COMPOSITOR.
        <br />
        <strong>47.2.</strong> Caso o COMPOSITOR não esteja de acordo com uma
        determinada alteração dos Termos e Condições de Uso da ACORDEON®, poderá
        cancelar seu vínculo coma ACORDEON® por meio de pedido de cancelamento
        de sua conta pelo e-mail suporte@registreacordeon.com.br. <br />
        <strong>47.3.</strong> O cancelamento da conta do COMPOSITOR não o
        eximirá de cumprir com as obrigações assumidas sob as versões
        precedentes do Termos e Condições de Uso da ACORDEON®. <br />
        <strong>48.</strong> Toda a documentação legal da plataforma da
        ACORDEON®, incluindo os presentes Termos e Condições de Uso, foi
        elaborada em língua portuguesa. <br />
        <strong>49.</strong> Caso a ACORDEON® não consiga fazer valer ou cumprir
        qualquer cláusula ou condição contida nestes Termos e Condições de Uso,
        tal fato não configurará desistência, tolerância ou novação dessa
        cláusula ou condição.
        <br />
        <strong>49.1</strong> Se alguma cláusula ou condição contida nestes
        Termos e Condições de Uso for declarada inexequível, no todo ou em
        partes, tal inexequibilidade não afetará as demais cláusulas ou
        condições deste documento, o que, diante disso, a ACORDEON® fará as
        adaptações necessárias para o mais próximo possível ser exequível.
        <br />
        <strong>49.2</strong> Nenhuma das partes será responsabilizada perante a
        outra quando o descumprimento ou o cumprimento extemporâneo de uma
        obrigação prevista nestes Termos e Condições de Uso for causado por
        casos fortuitos ou eventos de força maior, enquanto perduraram as suas
        consequências. <br />
        <strong>50.</strong> A ACORDEON® tem como princípio de sua atuação o
        respeito ao COMPOSITOR, agindo sempre em conformidade com as disposições
        do Marco Civil da Internet Lei Federal n° 12.965/2014.
      </p>
    </Container>
  );
}

export default TermsUse;
