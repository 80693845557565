import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { Link, useHistory } from 'react-router-dom';
import OrderStatusEnum from '~/enums/OrderStatusEnum';
import Index from '~/pages/Index';
import Check from '~/assets/img/check.svg';
import { usePlans } from '~/hooks/plans';
import { useProfile } from '~/hooks/profile';
// import Loading from '~/components/Loading';
import formatValue from '~/utils/formatValue';
import Loading from '~/components/Loading';
import * as S from './styles';

const date = new Date();
const dateFormated = date.toLocaleDateString();

export default function SuccessCard() {
  const history = useHistory();
  const { plans, selectPlan, qtdPortion } = usePlans();
  const { cardData } = useProfile();
  const formatedTotal = formatValue(cardData.total);

  const mult = selectPlan.quantity * plans.price;
  const share = mult / qtdPortion;
  const creditFormattedShare = formatValue(share);
  const [statusOrder, setStatusOrder] = useState('');

  useEffect(() => {
    setStatusOrder(
      cardData.status_id
        ? OrderStatusEnum.properties[cardData.status_id].label
        : ''
    );
  }, [statusOrder, cardData]);

  useEffect(() => {
    if (cardData.length === 0) {
      history.push('/dashboard/inicio/resumo');
    }
  }, [cardData, history]);

  return (
    <Index>
      {cardData.length === 0 ? (
        <Loading />
      ) : (
        <S.SectionNotMusic>
          <div className="sucess">
            <img src={Check} alt="Solicitação realizada com sucesso" />

            <span>Solicitação realizada com sucesso!</span>
          </div>
          <div className="resume">
            <h1>Resumo:</h1>
            <Table borderless>
              <div className="orderService">
                <span>
                  Transação Nº:
                  <strong>
                    {' '}
                    {cardData.payment.transaction.transaction_id}
                  </strong>
                </span>
              </div>
              <tbody>
                <tr>
                  <td>Créditos:</td>
                  <td>{cardData.__meta__.qty_items}</td>
                </tr>

                <tr>
                  <td>Status:</td>
                  <td>{statusOrder}</td>
                </tr>

                <tr>
                  <td>Meio de pagamento:</td>
                  <td>Cartão de crédito</td>
                </tr>

                <tr>
                  <td>Data:</td>
                  <td>{dateFormated}</td>
                </tr>

                <tr>
                  <td>Parcelas:</td>
                  <td>
                    {qtdPortion}x de {creditFormattedShare}
                  </td>
                </tr>

                <tr>
                  <td>Total:</td>
                  <td>{formatedTotal}</td>
                </tr>
              </tbody>
            </Table>
            <Link to="/dashboard/inicio/resumo">Voltar para o início</Link>
          </div>
        </S.SectionNotMusic>
      )}
    </Index>
  );
}
