import React from 'react';

import Header from '~/components/HeaderDashboard';
import Footer from '~/components/Footer';
import { PlansProvider } from '~/hooks/plans';
import { ProfileProvider } from '~/hooks/profile';

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Header />
      <ProfileProvider>
        <PlansProvider>{children}</PlansProvider>
      </ProfileProvider>
      <Footer />
    </>
  );
};

export default DefaultLayout;
