import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiLock, FiLogIn, FiMail } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';


import Button from '../../components/Button';
import Input from '../../components/Input';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import loadingGif from "../../assets/img/loadingMusic.gif";
import * as S from './styles';

export default function SignIn() {
  const formRef = useRef(null);

  const [disable, setDisable] = useState('');
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const { token } = useParams();

  useEffect(() => {
    delete api.defaults.headers.authorization;

    localStorage.removeItem('@AcordeOn:token');
    localStorage.removeItem('@AcordeOn:refreshToken');
    localStorage.removeItem('@AcordeOn:user');

    if (token) {
      api
        .get(`/auth/signup/confirm/${token}`)
        .then(res => {
          const { user, message } = res.data;

          addToast({
            type: 'success',
            title: `${message}`,
            description: `Faça login com o email ${user.email} e sua senha`,
          });
        })
        .catch(error => {
          const { message } = error.response.data;

          addToast({
            type: 'error',
            title: `Erro`,
            description: `${message}`,
          });
        });
    }
  }, [addToast, history, token]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Insira um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setDisable(disable === '' ? 'active' : '');

        setLoading(true);

        await signIn({
          email: data.email.toLowerCase(),
          password: data.password,
        });

        setLoading(false);

        // history.push('/dashboard/inicio/resumo');
      } catch (error) {
        setLoading(false);
        setDisable(disable === 'active' ? '' : '');

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current.setErrors(errors);

          return;
        }

        if (!error.response) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Serviço de dados indisponível no momento, favor tente mais tarde!',
          });
          return;
        }

        const msg = error.response.data.message;
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: `${msg}`,
        });
      }
    },
    [disable, signIn, addToast]
  );

  return (
    <S.BackgroundLogin>
        <S.BackgroundSectionLeftLogin>
          <S.DivLeftLogin>
            <span>Crie uma conta e registre sua música por apenas R$ 19,90</span>
            <Link to="/cadastro" aria-label="Criar conta">Criar conta</Link>
          </S.DivLeftLogin>
        </S.BackgroundSectionLeftLogin>
        <S.BackgroundSectionRightLogin>
          <S.DivRightLogin>
            <span>Acesse</span>
            <p>Sua conta do Acordeon</p>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                icon={FiMail}
                name="email"
                type="email"
                placeholder="Email"
                textTranform="lowercase"
              />
              <Input
                icon={FiLock}
                name="password"
                type="password"
                placeholder="Senha"
              />

              <Link to="/redefinir-senha">Esqueceu a senha?</Link>

              <Button type="submit" disabled={loading} className={disable}>
                Entrar
              </Button>
              {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}
            </Form>
            <Link to="/cadastro" aria-label="Criar conta" className="returnCadaster">
              <FiLogIn /> Criar conta
            </Link>
          </S.DivRightLogin>
        </S.BackgroundSectionRightLogin>
    </S.BackgroundLogin>
  );
}
