import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PlansContext = createContext({});

export const PlansProvider = ({ children }) => {
  const [selectPlan, setSelectPlan] = useState([]);
  const [qtdPortion, setQtdPortion] = useState(1);
  const [qtdCredit, setQtdCredit] = useState(1);
  const history = useHistory();

  const plans = {
    bonus: [],
    code: "CREDACOD1",
    description: "Registre sua música com validade juridica em 167 países",
    id: 1,
    name: "Crédito Acordeon - Registro de Música",
    price: 19.9,
    score: 1,
  }

  const decrementCredit = () => {
    const newCredit = qtdCredit - 1;
    setQtdCredit(newCredit);
  };

  const incrementCredit = () => {
    const newCredit = qtdCredit + 1;
    setQtdCredit(newCredit);
  };

  const plans1 = {
    id: 3,
    quantity: qtdCredit,
    score: qtdCredit,
  };
  const handleChoosePlan = () => {
    setSelectPlan(plans1);
    history.push('/dashboard/adicionar-creditos/finalizar-compra');
  };

  return (
    <PlansContext.Provider
      value={{
        plans,
        plans1,
        qtdCredit,
        selectPlan,
        qtdPortion,
        setQtdPortion,
        decrementCredit,
        incrementCredit,
        handleChoosePlan,
      }}
    >
      {children}
    </PlansContext.Provider>
  );
};

export function usePlans() {
  const context = useContext(PlansContext);

  return context;
}
