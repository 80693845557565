import api from '~/services/api';
// const api = use('~/services/api');

const basicFetch = async endpoint => {
  var result = {}
  await api.get(endpoint).then(response => {
      result = response.data;
    })
    .catch(err => {
      result = 'Erro ao buscar os vídeos!';
    });

  return result;
};

export default {
  getKnowList: async () => {
    return [
      {
        slug: 'popularity',
        title: 'Vídeos populares',
        items: await basicFetch(`/videos`),
      }
    ];
  },

  getVideoInfo: async (videoKey) => {
    let info = {};

    if (videoKey) {
      info = await basicFetch(`/videos/${videoKey}`)
    }

    return info;
  },
};
