import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import Index from '~/pages/Index';
import Posts from '~/components/Posts';
import avatar from '~/assets/img/acordeonAvatar.svg';
import { ReactComponent as UndrawOrganizeResume } from '~/assets/img/undraw_Organize_resume.svg';

import * as S from './styles';
import api from '~/services/api';
import { useProfile } from '~/hooks/profile';
import Loading from '~/components/Loading';

export default function RankingGeneral() {
  const { dataPerson, dataProfile } = useProfile();

  const [listUsersRanking, setListUsersRanking] = useState(null);

  useEffect(() => {
    api.get(`/ranking`).then(response => {
      setListUsersRanking(response.data);
    });
  }, []);

  return (
    <Index>
      <Posts />
      {listUsersRanking === null ? (
        <Loading />
      ) : (
        <S.BackgroundSection>
          {dataPerson.fullname === null ? (
            <div className="notRanking">
              <UndrawOrganizeResume />
              <span>
                Complete seu perfil para visualizar a sua posição no ranking
                geral
              </span>
              <Link to="/dashboard/perfil">Completar perfil</Link>
            </div>
          ) : (
            <>
              <h1>Top 10 - Ranking Geral</h1>
              <Table borderless>
                <tbody>
                  {listUsersRanking.slice(0, 10).map(userRanking => (
                    <tr key={userRanking.key}>
                      <td>
                        <img
                          src={
                            userRanking.avatarUrl
                              ? userRanking.avatarUrl
                              : avatar
                          }
                          alt={userRanking.nickname}
                        />
                        <span className="names">
                          {userRanking.nickname}{' '}
                          <font>
                            {userRanking.fullname === null
                              ? ''
                              : `(${userRanking.fullname})`}
                          </font>
                        </span>
                      </td>

                      <td>
                        <span className="orange">
                          {userRanking.ranking.position}°
                        </span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Total de pontos
                            </Tooltip>
                          }
                        >
                          <span className="grey">
                            {userRanking.ranking.score}
                          </span>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <S.EllipsisContainer>
                <div class="ellipsis-dot"></div>
                <div class="ellipsis-dot"></div>
                <div class="ellipsis-dot"></div>
              </S.EllipsisContainer>

              <Table borderless>
                <tbody>
                    <tr>
                      <td>
                        <img
                          src={
                            dataProfile.avatar
                              ? dataProfile.avatar
                              : avatar
                          }
                          alt={dataProfile.nickname}
                        />
                        <span className="names">
                          {dataProfile.nickname}{' '}
                          <font>
                            {dataPerson.fullname === null
                              ? ''
                              : `(${dataPerson.fullname})`}
                          </font>
                        </span>
                      </td>

                      <td>
                        <span className="orange">
                          {dataProfile?.ranking?.position}°
                        </span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Total de pontos
                            </Tooltip>
                          }
                        >
                          <span className="grey">
                            {dataProfile?.ranking?.score}
                          </span>
                        </OverlayTrigger>
                      </td>
                    </tr>
                </tbody>
              </Table>
            </>
          )}
        </S.BackgroundSection>
      )}
    </Index>
  );
}
