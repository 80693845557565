import styled, { css } from 'styled-components';

import Tooltip from '~/components/Tooltip';

export const Container = styled.div`
    background-color: #222222;
    border-radius: 5px;
    border: 2px solid #363636;
    padding: 16px;
    width: 100%;
    height: 3rem;
    color: #87868b;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 15px;
    }

    ${props =>
    props.isErrored &&
    css`
        border-color: #c53030;
      `}

    ${props =>
    props.isFocused &&
    css`
        color: #fe5812;
        border-color: #fe5812;
      `}

    ${props =>
    props.isFilled &&
    css`
        color: #fe5812;
      `}




    input {
        flex: 1;
        height: 2.5rem;
        background: transparent;
        border: 0;
        width: 0;
        color: #f4ede8;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: #ffffff !important;
        -webkit-box-shadow: 0 0 0px 1000px #272727 inset;
    }

    svg {
        margin-right: 16px;
    }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
