import React from 'react';
// import { Player, Vimeo } from "@vime/react";

import '@vime/core/themes/default.css'
import { BackgroundSection2, DivSection2 } from './styles';

export default function Section2() {
  return (
    <BackgroundSection2 id="certificacao">
      <DivSection2 className="content">
        <div className="divLeft">
          <h2>Certificação com segurança</h2>
          <p>
            Na AcordeOn&reg; o Registro da sua música tem proteção LEGAL,
            obedecendo rigorosamente a LEI 9.610/98 e a medida provisória nº
            2.200-2, que estabeleceu o ICP Brasil.
          </p>
          <p>
            O registro da AcordeOn&reg; lhe garante a declaração de autoria da
            sua obra, com autenticidade e validade em 172 países e total
            validade jurídica.
          </p>
          <h3>
            NÓS GARANTIMOS A AUTENTICIDADE DA SUA COMPOSIÇÃO COM VALIDADE
            JURÍDICA!
          </h3>
        </div>
        {/* <div className="divRight">
          <div className="react-player">
            <Player controls>
              <Vimeo videoId="727537408" byline={false}/>
            </Player>
          </div>
        </div> */}
      </DivSection2>
    </BackgroundSection2>
  );
}
