import styled from 'styled-components';

export const SectionNotMusic = styled.section`
  margin: 70px auto;
  display: flex;
  justify-content: center;

  div {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    img {
      width: 150px;
      color: #43b581;
    }

    span.sucess {
      font-size: 17px;
      color: #43b581;
      background-color: #222222;
      padding: 15px 20px;
      border-radius: 20px;
    }

    span.validation {
      font-size: 20px;
      color: #c5c5c5;
      padding: 30px 0;
    }

    a {
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #f34d21, #f5134b);
      color: white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #222222 inset;
      border-radius: 20px;
      padding: 0.3rem 2rem;
      font-size: 15px;
      outline: none;
      transition: all 0.5s ease;
      font-style: normal;

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }
    }
  }
`;
