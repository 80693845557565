import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const DivModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const appearFromRight = keyframes`
    0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
`;

export const DivModal = styled.div`
  animation: ${appearFromRight} 0.5s;

  /* font-family: 'UniviaRegular'; */
  background-color: #222222;
  color: #fff;
  border-radius: 4px;
  max-width: 800px;
  max-height: 75vh;
  overflow-y: auto;
  margin: 80px auto 0 auto;
  padding: 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #181818;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #696969;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #87868b;
  }

  // Botão fechar modal
  button {
    float: right;
    background: unset;
    border: unset;
    color: #fff;
  }

  // Menu modal
  div {
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0 auto;
    }

    p {
      font-size: 16px;
      margin: 0 auto;
      text-align: justify;
    }

    //Modais de resumo em créditos e pontos
    a.modalCredits,
    a.scores,
    a.completedCadaster,
    a.buttonModal,
    button.buttonModal {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      color: #fafafa;
      font-size: 19px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 20px;
      outline: none;
      border: 0;
      padding: 0.5rem 2rem;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }

    // Button loading confirmar compra
    button.active {
      display: none;
    }

    // imagem loading confirmar compra
    img {
      margin: 20px auto;
    }

    // Ao clicar no botão assistir da tela de tutoriais

    div.knowVideos {
      margin: 50px 65px;
    }
    div.knowVideos img {
      margin: 0px auto;
    }

    // Ao clicar no botão de registrar música

    h1.h1ButtonRegisterMusic {
      font-size: 25px;
      margin: 20px 0 10px;
    }

    // Página de registrar música

    span.addLetter,
    span.addMusic {
      margin: 25px auto;
      max-width: 70%;
      font-size: 22px;
      text-align: center;
    }

    div.addLetter,
    div.addMusic {
      margin: 50px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      button.addLetterLeft,
      button.addMusicLeft {
        border: solid 2px transparent;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #f34d21, #f5134b);
        color: white;
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #222222 inset;
        border-radius: 7px;
        padding: 0.5rem 1rem;
        outline: none;
        transition: all 0.5s ease;

        &:hover {
          cursor: pointer;
          box-shadow: none;
        }
      }

      button.addLetterRight,
      button.addMusicRight {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fafafa;
        background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
        border-radius: 7px;
        outline: none;
        border: 0;
        padding: 0.5rem 1rem;

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 2px 2px #f34d21;
        }
      }
    }

    // Termos da tela de cadastro de usuário

    h6.terms {
      font-size: 20px;
      margin-top: 30px;
    }

    // Pontos das tabs start

    h1.scores {
      margin: 0 auto;
      font-family: 'UniviaRegular';
      display: flex;
      align-items: center;

      > svg {
        margin-left: 10px;
      }
    }
    h2.scores {
      margin-top: 50px;
      margin-bottom: 0px;
      font-size: 1.5rem;
    }
    p.scores {
      font-size: 1rem;
      margin: 20px;
      text-align: justify;
      color: #ccc;
    }
  }

  // Resumo ao clicar em concluir compra na tela de add créditos

  div.resume {
    width: 60%;
    margin: 0 auto;

    p {
      text-align: center;
      margin-top: 30px;
    }

    h1 {
      font-size: 20px;
      margin: 40px 0 5px;
    }

    table {
      color: #ffffff;
      background: #363636;
      border-radius: 5px;
      font-size: 16px;

      tbody {
        tr {
          display: flex;
          justify-content: space-between;
        }
        tr.disable,
        hr.disable {
          display: none;
        }
        hr {
          background-color: #4e4e4e;
          margin: 0 10px;
        }
      }
    }
  }

  // Play de música na tela de minhas música

  div.myMusics {
    color: #ffffff;

    ul {
      margin: 10px auto;
      background: #222222;
      border-radius: 5px;

      border-width: 2px;
      border-style: solid;
      border-color: #363636;

      width: 100%;
      max-width: 650px;

      display: flex;
      align-items: center;

      li {
        padding: 10px 15px;
      }

      li.iconDown {
        display: flex;
        align-items: center;

        svg {
          color: #ffffff;
          font-size: 20px;
          margin: 0 5px;

          &:hover {
            cursor: pointer;
            color: ${shade(0.2, '#ffffff')};
          }
        }
      }

      li.leftItens {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 250px;

        font-size: 15px;
        span.nameMusic {
          color: #ffffff;
        }
        span.nameComposer {
          color: #7c7c7d;
        }
      }

      li.audioPlay {
        display: flex;
        justify-content: center;
        width: 500px;
      }

      div.rhap_container {
        outline: none;
        box-shadow: 0 0 0;

        background-color: transparent;
        border: 0;
        padding: 0;

        & svg {
          color: #f34d21 !important;
        }
      }

      div.rhap_main,
      div.rhap_controls-section,
      div.rhap_progress-section {
        display: flex;
        flex-direction: row;
      }

      div.rhap_time {
        color: #363636 !important;
      }

      div.rhap_progress-bar-show-download,
      div.rhap_download-progress {
        background-color: #181818 !important;
      }

      div.rhap_progress-container {
        height: 7px;
      }

      div.rhap_progress-indicator,
      div.rhap_progress-filled {
        background: linear-gradient(
          251.45deg,
          #f5134b 1.26%,
          #f34d21 96.99%
        ) !important;
      }
    }
  }

  div.cardsSaveModal {
    table {
      width: 100%;
      border-spacing: 0 8px;

      th {
        color: #c5c5c5;
        font-weight: normal;
        padding: 15px 25px;
        text-align: left;
        font-size: 15px;
        line-height: 24px;
      }

      tbody {
        tr {
          /* border-radius: 5px; */
          border-style: solid;
          border-color: #222222;
          border-bottom-width: 20px;

          td {
            padding: 15px 25px;
            font-size: 15px;
            font-weight: normal;
            color: #ffffff;
            background: #272727;

            > svg {
              font-size: 25px;
              margin-right: 5px;
              margin-bottom: 1px;
            }
          }
        }
      }
    }
  }

  div.headerLetter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  // REMOVER COMENTÁRIOS NA TELA DE VÍDEO
  // FORAM UTILIZADOS OS BOTÕES PARA CONFIRMAR AVATAR TELA DE PERFIL

  div.remove {
    span.spanRemove {
      margin: 50px auto;
    }

    div.alignButtonsRemove {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 10px 0;

      button {
        width: 30%;
        border-radius: 20px;
        outline: none;
        border: 0;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fafafa;
        font-size: 19px;
      }

      button.buttonsRemove {
        border: 1px solid rgba(254, 88, 18, 0.15);
        &:hover {
          cursor: pointer;
          background-color: rgba(254, 88, 18, 0.15);
        }
      }

      button.buttonsCancel {
        background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 2px 2px #f34d21;
        }
      }
    }
  }

  // AVATAR NA TELA DE PERFIL
  img.avatar {
    border-radius: 50%;
    width: 120px;
    height: 120px;
  }

  // TIPO DE PAGAMENTO POR CARTÃO DE CRÉDITO NA TELA DE MINHAS COMPRAS
  table.resumePaymentCardCredit {
    color: #ffffff;
    background: #363636;
    border-radius: 5px;
    font-size: 16px;
    margin: 40px auto;
    width: 60%;

    div.orderService {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 10px 0;
    }

    tbody {
      tr {
        display: flex;
        justify-content: space-between;

        border-bottom: 1px solid #4e4e4e;
      }
      tr:last-child {
        border-bottom: none;
      }
      tr.disable,
      hr.disable {
        display: none;
      }
    }
  }

  // Modal da letra da música na página registrar música

  section.letterSection {
    display: flex;
    flex-direction: column;

    div.headerLetter {
      margin: 50px 50px 0 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 18px;
        color: #7e7e7e;

        display: flex;
        align-items: center;

        svg {
          margin: 0 5px;
        }

        font {
          color: #ffffff;
        }
      }

      button {
        font-family: 'Roboto', sans-serif;
        color: #fafafa;
        font-size: 19px;
        background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
        border-radius: 20px;
        outline: none;
        border: 0;
        padding: 0.2rem 2em;
        display: flex;
        align-items: center;
        flex-direction: row;

        svg {
          margin-right: 10px;
        }

        &:hover {
          box-shadow: 0 0 2px 2px #f34d21;
        }

        @media (max-width: 580px) {
          font-size: 16px;
        }
      }
    }

    div.divLetterMusic {
      margin: 20px 50px;
      color: #000;

      textarea {
        padding: 20px;
      }
    }

    @media (max-width: 500px) {
      div.headerLetter {
        margin: 50px 0 20px 0;
      }
      div.divLetterMusic {
        margin: 20px 0;
      }
    }
  }

  /* MODAL DA MÚSICA NA PÁGINA MINHAS MÚSICAS */

  ul.redesSociais {
    position: absolute;
    /* right: 0; */
    display: flex;
    flex-direction: column;
    width: 200px !important;
    background: transparent !important;
    border: 0 !important;
    color: #fff !important;
  }
`;
