import React from 'react';
import { Link } from 'react-router-dom';
// import socket from '~/services/socket';
// import { useToast } from '~/hooks/toast';

import { BackgroundHeader } from './styles';

import { useAuth } from '~/hooks/auth';

import logo from '~/assets/img/logo.svg';

const HeaderDashboard = () => {
  // const { addToast } = useToast();
  const { signOut } = useAuth();

  // useEffect(() => {
  //   socket.connect();
  //   const ws = socket.subscribe(`paymentNotifications:${user.key}`);

  //   ws.on('processing', data => {
  //     // ex resposta: O pagamento da ordem: 0ba0b7a8-eddb-439e-b6ae-6b408fca870a. Está com o status cancelado!
  //     // alert('Ebaaa, seus creditos foram processados com sucesso. '+ data.status);
  //     addToast({
  //       type: `${data.typeResponse}`,
  //       title: `Pagamento`,
  //       description: `________________Transação Nº: ${data.transaction_id}
  //                   Status: ${data.status.name}.
  //                   ${data.message}`,
  //     });

  //     // window.location.reload();
  //   });

  //   // ws.on('error', (error) => {
  //   //   alert('Serviço de dados indisponível.');
  //   //   console.error(error)
  //   // });
  // }, [addToast, user]);

  return (
    <BackgroundHeader>
      <div className="contenTest">
        <Link to="/">
          <img src={logo} alt="Logo Acordeon" />
        </Link>
        <Link to="/login" onClick={signOut}>
          <button type="button" aria-label="Sair">Sair</button>
        </Link>
      </div>
    </BackgroundHeader>
  );
};

export default HeaderDashboard;
