import styled from 'styled-components';

export const ButtonActions = styled.div`

  button.buttonIcon {
    background: none;
    border: 0;
    margin-bottom: 10px;
  }

  span.buttonIcon {
    font-size: 14px;
    display: flex;
    align-items: center;

    > svg {
      font-size: 14px;
      margin-right: 3px;
      margin-left: 3px;
    }
  }
`

export const BackgroundSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 50px;

  div.notShopping {
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 70px auto;

    svg {
      width: 400px;
      height: 270px;

      @media (max-width: 450px) {
        width: 100%;
        height: 100%;
      }
    }

    span {
      color: #fff;
      font-size: 30px;
      padding: 30px 0;
      font-family: 'UniviaRegular';
    }

    a {
      font-family: 'Roboto', sans-serif;
      color: #fafafa;
      font-size: 17px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 15px;
      outline: none;
      border: 0;
      padding: 0.5rem 3rem;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }
  }

  div.scroll {
    h1 {
      font-family: 'UniviaRegular';
    }
    @media (max-width: 900px) {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background-color: #222222;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #696969;
        border-radius: 20px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #87868b;
      }
    }


    @media (max-width: 500px) {
      h1 {
        font-size: 22px;
      }
    }
  }


  .spanExpanded{
    color: #ffffff;
  }
`;
