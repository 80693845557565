import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import styled from 'styled-components';

const Ul = styled.ul`
  margin: auto 0;
  display: flex;
  align-items: center;
  font-family: 'UniviaRegular';
  /* font-family: 'Roboto', sans-serif; */

  li.hashLink {
    font-size: 18px;
    color: #fff;
    margin-right: 30px;

    a {
      color: inherit;
      position: relative;
    }

    a:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -4px;
      left: 0;
      background: linear-gradient(101deg, #f34d21, #f5134b);
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }

    a:hover:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  & button {
    border: solid 2px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #f34d21, #f5134b);
    color: white;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #222222 inset;
    border-radius: 20px;
    padding: 0.3rem 1.5rem;
    font-size: 18px;
    outline: none;
    transition: all 0.5s ease;
    font-style: normal;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      box-shadow: none;
    }
  }

  & button.createCount {
    display: none;
  }

  @media (max-width: 820px) {
    flex-flow: column nowrap;
    background-color: #0e0e0e;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    /* transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')}; */
    display: ${({ open }) => (open ? 'flex' : "none")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 10;
    padding-top: 3.5rem;

    li.hashLink {
      margin: 0;
      transition-duration: 0.5s;
      font-size: 1.8rem;
      line-height: 5rem;
      letter-spacing: 0.03em;
    }

    button {
      font-size: 1.6rem;

      margin-top: 30px;
      padding: 0.3rem 5.9rem;
      box-shadow: 2px 1000px 1px #0e0e0e inset;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }
    }

    button.createCount {
      transition-duration: 0.5s;
      display: block;

      padding: 0.375rem 4.125rem;
      border-radius: 5px;
    }
  }
`;

const Nav = ({ open, setOpen }) => {
  return (
    <Ul open={open}>
      <li onClick={() => setOpen(!open)} className="hashLink">
        <HashLink smooth to="/#certificacao">
          Certificação
        </HashLink>
      </li>
      <li onClick={() => setOpen(!open)} className="hashLink">
        <HashLink smooth to="/#como_funciona">
          Como funciona
        </HashLink>
      </li>
      <li onClick={() => setOpen(!open)} className="hashLink">
        <HashLink smooth to="/validacao/registro/certificado">
          Validação
        </HashLink>
      </li>
      <li onClick={() => setOpen(!open)}>
        <Link to="/cadastro">
          <button
            type="button"
            aria-label="Criar conta"
            className="createCount"
          >
            Criar conta
          </button>
        </Link>
      </li>
      <li onClick={() => setOpen(!open)}>
        <Link to="/login">
          <button type="button" aria-label="Entrar">
            Entrar
          </button>
        </Link>
      </li>
    </Ul>
  );
};

export default Nav;
