/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { MdRemoveCircleOutline, MdAddCircleOutline } from 'react-icons/md';

import { Link } from 'react-router-dom';
import Inicio from '~/subpages/Inicio';
import Loading from '~/components/Loading';
import formatValue from '~/utils/formatValue';

import { usePlans } from '~/hooks/plans';
import { useProfile } from '~/hooks/profile';
import Modal from '~/components/Modal';
import * as S from './styles';

export default function AddPlan() {
  const [modalShow, setModalShow] = useState('');

  const {
    plans,
    plans1,
    qtdCredit,
    decrementCredit,
    incrementCredit,
    handleChoosePlan,
  } = usePlans();
  const { dataPerson } = useProfile();

  const priceCredit = formatValue(
    plans.length === 0 ? <Loading /> : plans.price
  );

  const totalValueCredits = formatValue(
    plans.length === 0 ? <Loading /> : plans.price * qtdCredit
  );

  return (
    <Inicio>
      {plans.length <= 0 ? (
        <Loading />
      ) : (
        <S.BackgroundSection>
          <S.BackgroundDiv>
            <ul>
              <li>
                <span className="typePlan">Créditos</span>
              </li>
              <li>
                <span className="comment">1 crédito = 1 registro de música</span>
              </li>
              <li>
                <table>
                  <tbody>
                    <tr>
                      <td>Quantidade</td>
                      <td className="valueWhite">
                        <div>
                          <button
                            type="button"
                            aria-label="decremento"
                            className="buttonLeft"
                            disabled={qtdCredit <= 1}
                            onClick={decrementCredit}
                          >
                            <MdRemoveCircleOutline />
                          </button>
                          <input type="number" readOnly value={qtdCredit} />
                          <button
                            type="button"
                            aria-label="incremento"
                            className="buttonRight"
                            onClick={incrementCredit}
                          >
                            <MdAddCircleOutline />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Pontos</td>
                      <td className="valueWhite">
                        {plans1.score}{' '}{plans1.score > 1 ? 'pontos' : 'ponto'}

                      </td>
                    </tr>
                      <tr>
                        <td>Preço</td>
                        <td className="valueWhite">{priceCredit}</td>
                      </tr>

                    <tr>
                      <td>Total</td>
                      <td className="valueWhite">{totalValueCredits}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
              {dataPerson.fullname !== null ? (
                <button className="choosePlan" onClick={handleChoosePlan}>
                  Continuar
                </button>
              ) : (
                <button
                  className="choosePlan"
                  type="button"
                  onClick={() => setModalShow('showNotProfile')}
                >
                  Continuar
                </button>
              )}
            </ul>
          </S.BackgroundDiv>
        </S.BackgroundSection>
      )}
      {modalShow === 'showNotProfile' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <h1 className="h1ButtonRegisterMusic">
            Falta pouco para você se tornar um grande compositor de sucesso
          </h1>
          <p className="pButtonRegisterMusic">
            Complete seu cadastro para adicionar créditos
          </p>

          <Link className="buttonModal" to="/dashboard/perfil">
            Completar cadastro
          </Link>
        </Modal>
      )}
    </Inicio>
  );
}
