/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
// import pagarme from 'pagarme';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Form } from '@unform/web';
import { IoIosCard, IoIosBarcode } from 'react-icons/io';
import { MdOutlinePix } from 'react-icons/md';
import { BsCardChecklist } from 'react-icons/bs';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';
import loadingGif from '~/assets/img/loadingMusic.gif';

import { useToast } from '~/hooks/toast';
import MaskedInput from '~/components/MaskedInput';
import Modal from '~/components/Modal';
import { usePlans } from '~/hooks/plans';
import formatValue from '~/utils/formatValue';
import Inicio from '~/subpages/Inicio';
import musicBar from '~/assets/img/boleto.png';
import logoPix from '~/assets/img/logo-pix-png-930x616.png';
import api from '~/services/api';
import helpers from '~/utils/helpers';
import { useProfile } from '~/hooks/profile';

import TermsUse from '~/components/TermsUse';
import CreditCard from './CreditCard';
import * as S from './styles';

// const date = new Date();
// const dateFormated = date.toLocaleDateString();

export default function AddCredit() {
  const formRef = useRef(null);
  const history = useHistory();
  const [payment, setPayment] = useState('pix');
  const { addToast } = useToast();
  const { dataPerson, setcardData } = useProfile();

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [saveCard, setSaveCard] = useState(false);
  const [modalShow, setModalShow] = useState('');

  const { plans, selectPlan, qtdPortion, setQtdPortion } = usePlans();

  const [mask, setMask] = useState(true);
  const [styleDisabled] = useState({
    cursor: 'no-drop',
    color: '#7C7C7D',
  });

  const [loadingModal, setLoadingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableModal, setDisableModal] = useState('');
  const [disable, setDisable] = useState('');

  const [numberOptions, setNumberOptions] = useState([{value: 1, name: '1x'}]);

  const onChange = e => {
    const { value } = e.target;
    setQtdPortion(value);
  };

  // ####### MODELO DE TESTE PARA GERAR O HASH DA PAGARME

  useEffect(() => {
    const valueTotal = selectPlan.quantity * plans.price ? selectPlan.quantity * plans.price : 0

    if (selectPlan.length === 0) {
      history.push('/dashboard/adicionar-creditos/planos');
    }

    if(valueTotal){
      api.get('/rolePaymentCredits/getRole', {
        params: {
          total: valueTotal
        }
      }).then(response => {

        if(response.data){
          const qtd = response.data.quantity
          const arrayQtdValue = []

          let n = 1;

          while (n <= qtd) {
            arrayQtdValue.push({value: n, name: `${n}x`})
            n++;
          }
          setNumberOptions(arrayQtdValue)
        }
      });
    }
  }, [history, selectPlan, plans, setQtdPortion]);

  useEffect(() => {
     setQtdPortion(1)
  }, [setQtdPortion])

  async function abrirModalCompra() {
    if (payment === 'credit_card') {
      abrirModalfinalizarCompraCard();
    }else if (payment === 'boleto') {
      abrirModalfinalizarCompraBillet();
    }else if (payment === 'pix') {
      abrirModalfinalizarCompraPix();
    }
  }

  async function abrirModalfinalizarCompraCard() {
    setLoading(true);
    setDisable(disable === '' ? 'active' : '');
    if (mask) {
      if (number === '' || name === '' || expiry === '' || cvc === '') {
        if (number === '') {
          document.getElementById('number_card').className = 'activeValidation';
          setLoading(false);
          setDisable('');
          return;
        }
        document.getElementById('number_card').className = '';
        if (name === '') {
          document.getElementById('name_card').className = 'activeValidation';
          setLoading(false);
          setDisable('');
          return;
        }
        document.getElementById('name_card').className = '';
        if (expiry === '' || number < 4) {
          document.getElementById('expiry_card').className = 'activeValidation';
          setLoading(false);
          setDisable('');
          return;
        }
        document.getElementById('expiry_card').className = '';

        if (cvc === '' || number < 3) {
          document.getElementById('cvc_card').className = 'activeValidation';
          setLoading(false);
          setDisable('');
          return;
        }
        document.getElementById('cvc_card').className = '';
        return;
      }
    }

    // const card = {
    //   card_number: number,
    //   card_holder_name: name,
    //   card_expiration_date: expiry,
    //   card_cvv: cvc,
    // };

    // const pagarmeService = new PagarmeService();

    // // setHash('');

    // if (mask) {
    //   try {
    //     pagarmeService
    //       .getCard(card, saveCard)
    //       .then(res => {
    //         if (saveCard) {
    //           setCardId(res);
    //         } else {
    //           setHash(res);
    //         }
    //         setModalShow('credit_card');
    //         setLoading(false);
    //         setDisable('');
    //       })
    //       .catch(result => {
    //         if (result.message) {
    //           addToast({
    //             type: 'error',
    //             title: `Erro`,
    //             description: `${result.message}`,
    //           });
    //         }
    //         if (result.errors) {
    //           addToast({
    //             type: 'error',
    //             title: `Erro`,
    //             description: `${result.errors.message}`,
    //           });
    //         }
    //       });
    //   } catch (result) {

    //     setLoading(false);
    //     setDisable('');

    //     if (result.message) {
    //       addToast({
    //         type: 'error',
    //         title: `Erro`,
    //         description: `${result.message}`,
    //       });
    //     }
    //     if (result.errors) {
    //       addToast({
    //         type: 'error',
    //         title: `Erro`,
    //         description: `${result.errors.message}`,
    //       });
    //     }
    //   }

    setModalShow('credit_card');
    setLoading(false);
    setDisable('');
    await buscarDadosUsuario();
  }

  const mult = selectPlan.quantity * plans.price;
  const share = mult / qtdPortion;
  const creditFormattedShare = formatValue(share);

  const amount = formatValue(mult);

  async function handleSubmit() {
    setLoadingModal(true);
    setDisableModal(disableModal === '' ? 'active' : '');

    const ipClient = await helpers.getIpClient();

    api
      .post('/payments/store', {
        holder: true,
        payment_method: payment,
        installments: qtdPortion,
        save_card: saveCard,
        creditCard: {
          holderName: name,
          number: number,
          expiryMonth: expiry.slice(0, 2),
          expiryYear: `20${expiry.slice(2)}`,
          ccv: cvc
        },
        ip: ipClient,
        order: {
          items: [
            {
              product_id: plans.id,
              quantity: selectPlan.quantity,
            },
          ],
        },
      })
      .then(response => {
        setcardData(response.data);
        history.push('/dashboard/adicionar-creditos/compra-efetuada-cartao');
      })
      .catch(error => {
        console.log('error:', error);
        setLoadingModal(false);
        setDisableModal('');
        setModalShow('');
        addToast({
          type: 'error',
          title: 'Erro no pagamento',
          description: error.response.data.message,
        }); 
      });
  }


  function handleSelectCard({
    number,
    holder_name,
    expiration_date,
  }) {
    setMask(false);
    setNumber(number);
    setName(holder_name);
    setExpiry(expiration_date);
    setCvc('');
  }

  async function abrirModalfinalizarCompraBillet() {
    setModalShow('billet');
    setPayment('boleto');
    await buscarDadosUsuario();
    // setLoading(false);
    // setDisable('');
  }

  async function abrirModalfinalizarCompraPix() {
    setModalShow('pix');
    setPayment('pix');
    await buscarDadosUsuario();    
  }

  async function buscarDadosUsuario() {
    setLoadingModal(true);
    api.get(`/profile/customer`)
    .then(response => {
      if(response.data){
        setLoadingModal(false);
      }
    })
    .catch(error => {
      console.log('error:', error);
      setLoadingModal(false);
      setDisableModal('');
      setModalShow('');
      addToast({
        type: 'error',
        title: 'Erro no pagamento',
        description: error.response.data.message,
      });      
    });
  }

  async function handleSubmitBillet(formData) {
    setLoadingModal(true);
    setDisableModal(disableModal === '' ? 'active' : '');

    const ipClient = await helpers.getIpClient();

    api
      .post('/payments/store', {
        holder: true,
        payment_method: payment,
        ip: ipClient,
        order: {
          items: [
            {
              product_id: plans.id,
              quantity: selectPlan.quantity,
            },
          ],
        },
        fake_boleto: true,
      })
      .then(response => {
        setcardData(response.data);

        history.push('/dashboard/adicionar-creditos/compra-efetuada-boleto');
      })
      .catch(error => {
        console.log('error:', error);
        setLoadingModal(false);
        setDisableModal('');
        setModalShow('');
        addToast({
          type: 'error',
          title: 'Erro no pagamento',
          description: error.response.data.message,
        }); 
      });
  }

  async function handleSubmitPix(formData) {
    setLoadingModal(true);
    setDisableModal(disableModal === '' ? 'active' : '');

    const ipClient = await helpers.getIpClient();

    api
      .post('/payments/store', {
        holder: true,
        payment_method: payment,
        ip: ipClient,
        order: {
          items: [
            {
              product_id: plans.id,
              quantity: selectPlan.quantity,
            },
          ],
        },
      })
      .then(response => {
        setcardData(response.data);

        history.push('/dashboard/adicionar-creditos/compra-efetuada-pix');
      })
      .catch(error => {
        console.log('error:', error);
        setLoadingModal(false);
        setDisableModal('');
        setModalShow('');
        addToast({
          type: 'error',
          title: 'Erro no pagamento',
          description: error.response.data.message,
        }); 
      });
  }

  function handleCleanCard() {
    setMask(true);
    setNumber('');
    setName('');
    setExpiry('');
    setCvc('');
  }

  return (
    <Inicio>
      <S.BackgroundSection>
        <div className="divHeader">
          <h1>Formas de pagamento:</h1>

          <div className="pag">
            <button
              onClick={() => setPayment('pix')}
              className={payment === 'pix' ? 'pix active' : 'pix'}
              type="submit"
            >
              <MdOutlinePix />
              PIX
            </button>
            <button
              onClick={() => setPayment('credit_card')}
              className={
                payment === 'credit_card' ? 'credit_card active' : 'credit_card'
              }
              type="submit"
            >
              <IoIosCard />
              Cartão de crédito
            </button>
            <button
              onClick={() => setPayment('boleto')}
              className={payment === 'boleto' ? 'boleto active' : 'boleto'}
              type="submit"
            >
              <IoIosBarcode />
              Boleto
            </button>
          </div>
        </div>
        <div className="divBody">
          <S.BackgroundDivLeft>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              // initialData={data}
              className={
                payment === 'credit_card' ? 'credit_card active' : 'credit_card'
              }
            >
              <div className="cardsData">
                <h2>Dados do cartão</h2>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Meus cartões salvos</Tooltip>}
                >
                  <BsCardChecklist onClick={() => setModalShow('cardsData')} />
                </OverlayTrigger>
                {modalShow === 'cardsData' && (
                  <Modal
                    isOpen={modalShow}
                    onClickClose={() => setModalShow('')}
                  >
                    <div className="cardsSaveModal">
                      <div className="cards">
                        <CreditCard
                          onChange={handleSelectCard}
                          setModalShow={setModalShow}
                        />
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
              <Cards
                number={number}
                name={name}
                expiry={expiry}
                cvc={cvc}
                focused={focus}
              />

              <div className="inputsRow">
                <MaskedInput
                  label="Número do cartão:"
                  type="text"
                  mask={mask ? '9999 9999 9999 9999' : ''}
                  disabled={!mask}
                  style={!mask ? styleDisabled : { color: '#fff' }}
                  name="number"
                  id="number_card"
                  value={number}
                  onChange={e => setNumber(e.target.value)}
                  onFocus={e => setFocus(e.target.name)}
                  className=""
                />
              </div>

              <div className="inputsRow">
                <label htmlFor="name">Nome do titular:</label>
                <input
                  type="text"
                  name="name"
                  id="name_card"
                  value={name}
                  disabled={!mask}
                  style={!mask ? styleDisabled : { color: '#fff' }}
                  onChange={e => setName(e.target.value)}
                  onFocus={e => setFocus(e.target.name)}
                  className=""
                />
              </div>

              <div className="twoInputsInLine">
                <div className="inputVality">
                  <MaskedInput
                    label="Validade:"
                    type="text"
                    name="expiry"
                    id="expiry_card"
                    mask="99/99"
                    value={expiry}
                    disabled={!mask}
                    style={!mask ? styleDisabled : { color: '#fff' }}
                    onChange={e => setExpiry(e.target.value)}
                    onFocus={e => setFocus(e.target.name)}
                    className=""
                  />
                </div>

                <div className="inputCvv">
                  {/* <label htmlFor="cvv">Código de segurança:</label> */}
                  <MaskedInput
                    type="tel"
                    name="cvc"
                    id="cvc_card"
                    label="Código de segurança:"
                    value={cvc}
                    placeholder={!mask ? '***' : ''}
                    disabled={!mask}
                    style={!mask ? styleDisabled : { color: '#fff' }}
                    // maxLength="4"
                    onChange={e => setCvc(e.target.value)}
                    onFocus={e => setFocus(e.target.name)}
                    className=""
                  />
                </div>
              </div>
              {mask && (
                <div className="saveCardInputCheckbox">
                  <input
                    type="checkbox"
                    checked={saveCard}
                    onChange={e => setSaveCard(e.target.checked)}
                    name="saveCard"
                    id="save-card"
                  />{' '}
                  <label htmlFor="save-card">
                    Salvar cartão para as próximas compras
                  </label>
                </div>
              )}
              {/* <div className="patternCardInputCheckbox">
                <input
                  type="checkbox"
                  id="pattern-card"
                  checked={patternCard}
                  onChange={e => setPatternCard(e.target.checked)}
                  name="patternCard"
                />{' '}
                <label htmlFor="pattern-card">
                  Definir cartão padrão para as próximas compras
                </label>
              </div> */}
              {!mask && (
                <button
                  type="button"
                  className="cleanCard"
                  onClick={handleCleanCard}
                >
                  Limpar dados acima
                </button>
              )}
            </Form>

            <Form className={payment === 'boleto' ? 'boleto active' : 'boleto'}>
              <h2>Boleto</h2>
              <img src={musicBar} alt="Código de barras música" />

              <div className="inputsRow">
                <label htmlFor="Nome completo">Nome completo:</label>
                <input
                  type="text"
                  name="name"
                  value={dataPerson.fullname}
                  disabled
                  style={{
                    cursor: 'no-drop',
                    color: '#7C7C7D',
                  }}
                />
              </div>

              <div className="inputsRow">
                <label htmlFor="Nome completo">CPF:</label>
                <MaskedInput
                  mask="999.999.999-99"
                  value={dataPerson.cpf}
                  // onChange={e => setCpf(e.target.value)}
                  type="CPF"
                  name="CPF"
                  id="exampleCPF"
                  maxLength="11"
                  disabled
                  style={{
                    cursor: 'no-drop',
                    color: '#7C7C7D',
                  }}
                />
              </div>
            </Form>

            <Form className={payment === 'pix' ? 'pix active' : 'pix'}>
              <h2>PIX</h2>
              <img src={logoPix} alt="Imagem pix" />

              <div className="inputsRow">
                <label htmlFor="Nome completo">Nome completo:</label>
                <input
                  type="text"
                  name="name"
                  value={dataPerson.fullname}
                  disabled
                  style={{
                    cursor: 'no-drop',
                    color: '#7C7C7D',
                  }}
                />
              </div>

              <div className="inputsRow">
                <label htmlFor="Nome completo">CPF:</label>
                <MaskedInput
                  mask="999.999.999-99"
                  value={dataPerson.cpf}
                  // onChange={e => setCpf(e.target.value)}
                  type="CPF"
                  name="CPF"
                  id="exampleCPF"
                  maxLength="11"
                  disabled
                  style={{
                    cursor: 'no-drop',
                    color: '#7C7C7D',
                  }}
                />
              </div>
            </Form>

          </S.BackgroundDivLeft>
          <S.BackgroundDivRight>
            <div>
              <Form
                className={
                  (payment === 'boleto' || payment === 'pix') ? 'qtdPortion disable' : 'qtdPortion'
                }
              >
                <span>Quantidade de parcelas:</span>
                <div>
                  <select
                    label="Quantidade de parcelas:"
                    type="select"
                    name="select"
                    id="selectPortion"
                    onChange={onChange}
                  >
                    {numberOptions.map((e, key) => {
                      return <option key={key} value={e.value}>{e.name}</option>;
                    })}
                  </select>
                </div>
                <span>
                  {qtdPortion}x de <font>{creditFormattedShare}</font>
                </span>
              </Form>
              <h3>Resumo:</h3>
              <div className="table">
                <div>
                  <div className="spaceBetween">
                    <span>Total de Créditos:</span>
                    <span>{selectPlan.quantity}</span>
                  </div>
                  <hr />
                  <div
                    className={
                      payment === 'boleto'
                        ? 'spaceBetween portion disable'
                        : 'spaceBetween portion'
                    }
                  >
                    <span>Parcelas:</span>
                    <span>
                      {qtdPortion}x de {creditFormattedShare}
                    </span>
                  </div>
                  <hr
                    className={
                      payment === 'boleto' ? 'portion disable' : 'portion'
                    }
                  />
                  <div className="spaceBetween">
                    <span>Total da compra:</span>
                    <span>{amount}</span>
                  </div>
                </div>
              </div>

              <div className="terms">
                Ao clicar em concluir compra você concorda com{' '}
                <button
                  className="termsButton"
                  type="button"
                  onClick={() => setModalShow('termsModal')}
                >
                  nossos termos e serviços.
                </button>
                {modalShow === 'termsModal' && (
                  <Modal
                    isOpen={modalShow}
                    onClickClose={() => setModalShow('')}
                  >
                    <TermsUse />
                  </Modal>
                )}
              </div>

              <button
                disabled={loading}
                className={`conclude ${disable}`}
                type="button"
                onClick={abrirModalCompra}
              >
                <span>Comprar</span>
              </button>

              {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}

              {/* Modais */}

              {modalShow === 'credit_card' && (
                <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
                  <Table borderless className="resumePaymentCardCredit">
                    <tbody>
                      <tr>
                        <td>Total de créditos:</td>
                        <td>{selectPlan.quantity}</td>
                      </tr>

                      <tr>
                        <td>Parcelas:</td>
                        <td>
                          {qtdPortion}x de {creditFormattedShare}
                        </td>
                      </tr>

                      <tr>
                        <td>Total da compra:</td>
                        <td>{amount}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <button
                    type="button"
                    disabled={loadingModal}
                    onClick={() => handleSubmit()}
                    className={`buttonModal ${disableModal}`}
                  >
                    Confirmar compra
                  </button>
                  {loadingModal && (
                    <img src={loadingGif} alt="Carregando..." width="50px" />
                  )}
                </Modal>
              )}

              {modalShow === 'billet' && (
                <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
                  <div className="resume">
                    <p>Valor total: {amount}</p>
                    <p>Deseja confirmar compra no boleto?</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleSubmitBillet()}
                    disabled={loadingModal}
                    className={`buttonModal ${disableModal}`}
                  >
                    Confirmar compra
                  </button>
                  {loadingModal && (
                    <img src={loadingGif} alt="Carregando..." width="50px" />
                  )}
                </Modal>
              )}

              {modalShow === 'pix' && (
                <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
                  <div className="resume">
                    <p>Valor total: {amount}</p>
                    <p>Deseja confirmar compra no pix?</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleSubmitPix()}
                    disabled={loadingModal}
                    className={`buttonModal ${disableModal}`}
                  >
                    Confirmar compra
                  </button>
                  {loadingModal && (
                    <img src={loadingGif} alt="Carregando..." width="50px" />
                  )}
                </Modal>
              )}
            </div>
          </S.BackgroundDivRight>
        </div>
      </S.BackgroundSection>
    </Inicio>
  );
}
