import styled from 'styled-components';

import backgroundImgSection4 from '../../../assets/img/backgroundSection4.jpg';
import backgroundFooter from '../../../assets/img/backgroundFooter.jpg';

export const BackgroundSection4 = styled.section`
    width: 100%;
    height: 95vh;
    background: url(${backgroundImgSection4}) no-repeat;
    background-size: cover;
    position: relative;
    display: flex;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(
            263.7deg,
            rgba(119, 55, 198, 0.8) 1.26%,
            rgba(188, 14, 165, 0.8) 96.99%
        );
    }

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(
            357.69deg,
            #222222 1.81%,
            rgba(0, 0, 0, 0.4) 97.97%
        );
    }

    /* Responsividade */

    @media (max-width: 450px) {
        background: url(${backgroundFooter}) no-repeat;
        background-size: cover;
        width: 100%;
        height: 95vh;

        &::before {
            background: none;
        }
    }
`;

export const DivSection4 = styled.div`
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        color: #fff;
        font-family: 'UniviaBook';
        font-size: 55px;
        line-height: 4rem;
        text-align: center;
        max-width: 600px;
    }

    button {
        font-family: 'Roboto', sans-serif;
        color: #fafafa;
        font-size: 18px;
        background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
        border-radius: 30px;
        outline: none;
        border: 0;
        margin-top: 3rem;
        padding: 1rem 4rem;

        &:hover {
            box-shadow: 0 0 2px 2px #f34d21;
        }
    }

    /* Responsividade */

    @media (max-width: 450px) {
        h2 {
            font-size: 2rem;
            line-height: 1.7em;
        }

        button {
            margin-top: 1rem;
            padding: 1rem 5rem;
        }
    }
`;
