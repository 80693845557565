import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const BackgroundDiv = styled.div`
  width: 272px;
  height: 345px;
  background: #222222;
  border-radius: 10px;
  margin: 50px 10px;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    button.clasLink {
      background: none;
      border: 0;
      margin-top: -17px;
    }

    span.clasLink {
      color: #fe5812;
      font-size: 16px;
      display: flex;
      align-items: center;

      > svg {
        font-size: 20px;
        margin-right: 7px;
      }

      &:hover {
        cursor: pointer;
        color: ${shade(0.2, '#f25812')};
      }
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 17px 0;

      align-items: center;

      span.clasName {
        padding: 4px 20px;
        background: #1d1d1d;
        border-radius: 20px;
        color: #ffffff;
        font-size: 19px;
      }

      span.clasNumber {
        font-size: 85px;
        color: #c5c5c5;
      }

      span.clasNumberCred {
        font-size: 85px;

        background: -webkit-linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #f34d21, #f5134b);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      /* span.top {
        margin-top: -13px;
      } */

      aside {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: -20px;

        font#fontName {
          color: #7c7c7d;
          font-size: 15px;
        }

        svg {
          color: #00d401;
          margin-left: 10px;
        }
      }

      a.directionTab {
        border: solid 2px transparent;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #f34d21, #f5134b);
        color: white;
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #222222 inset;
        border-radius: 20px;
        padding: 0.4rem 1.2rem;
        font-size: 15px;
        outline: none;
        transition: all 0.5s ease;
        font-style: normal;
        /* font-weight: 500; */

        &:hover {
          cursor: pointer;
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 580px) {
    width: 80%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 10px;
  }
`;
