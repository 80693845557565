import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Home from '~/pages/Home';
import ForgotPassword from '~/pages/ForgotPassword';
import Avatar from '~/pages/Avatar';
import Terms from '~/pages/Terms';
// import Erro from '~/pages/Erro';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';

import Dashboard from '~/pages/Dashboard';
import Index from '~/pages/Index';

import PageNotFound from '~/pages/PageNotFound';
import EmailSent from '~/pages/EmailSent';
import EmailValidation from '~/pages/EmailValidation';
import NewPass from '~/pages/NewPass';

import RegistrarMusica from '~/subpages/RegistrarMusica';
import Registered from '~/subpages/Registered';
import RegistryError from '~/subpages/RegistryError';

import Resume from '~/subpages/Inicio/Resume';
import Credit from '~/subpages/Inicio/Credit';
import AddPlan from '~/subpages/Inicio/Credit/AddPlan';
import AddCredit from '~/subpages/Inicio/Credit/AddCredit';
import SuccessCard from '~/subpages/Inicio/Credit/AddCredit/SuccessCard';
import SuccessBillet from '~/subpages/Inicio/Credit/AddCredit/SuccessBillet';
import SuccessPix from '~/subpages/Inicio/Credit/AddCredit/SuccessPix';
import Score from '~/subpages/Inicio/Score';
import AdicionarPontos from '~/subpages/Inicio/Score/EarnPoints';
import Ranking from '~/subpages/Inicio/Ranking';
import RankingGeral from '~/subpages/RankingGeneral';

import Perfil from '~/subpages/Perfil';
import AlterarSenha from '~/subpages/Perfil/AlterPassword';
import MinhasMusicas from '~/subpages/MinhasMusicas';
import RecordedMusicDashboard from '~/subpages/RecordedMusicDashboard';
import MinhasCompras from '~/subpages/MinhasCompras';
import Usuarios from '~/subpages/Dashboard/Usuarios';
import Tutoriais from '~/subpages/Tutoriais';
import Video from '~/subpages/Tutoriais/Video';
import {
  General,
  Register,
  Market,
  Juridico,
  Launch,
  News,
} from '~/subpages/Perguntas';
// import Playlists from '~/subpages/Playlists';
// import CreatePlayList from '~/subpages/Playlists/CreatePlayList';
// import PlayListSelected from '~/subpages/Playlists/PlayListSelected';
import RecordedMusic from '~/pages/RecordedMusic';
import ValidacaoCertificado from '~/pages/ValidacaoCertificado';
import ValidacaoCertificadoDashboard from '~/subpages/ValidacaoCertificadoDashboard';
import ValidacaoVoucher from '~/subpages/ValidacaoVoucher';
import Configuracoes from '~/subpages/Dashboard/Configuracoes';

export default function Routes() {
  return (
    <Switch>
      {/* Rotas Públicas */}

      <Route path="/" exact component={Home} />

      <Route path="/cadastro" exact component={SignUp} />
      <Route path="/validation/:message" exact component={EmailValidation} />

      <Route path="/login/:token" exact component={SignIn} />
      <Route path="/login/senha-alterada/:message" exact component={SignIn} />
      <Route path="/login" exact component={SignIn} />
      <Route path="/redefinir-senha" exact component={ForgotPassword} />
      <Route path="/avatar" exact component={Avatar} />
      <Route path="/termos" exact component={Terms} />
      <Route
        path="/email-enviado/:title/:message"
        exact
        component={EmailSent}
      />
      <Route path="/alterar-senha/:hash" exact component={NewPass} />
      <Route path="/musica-registrada/:key" exact component={RecordedMusic} />

      <Route path="/validacao/registro/certificado/:hashAcordeon" exact component={ValidacaoCertificado} />
      <Route path="/validacao/registro/certificado" exact component={ValidacaoCertificado} />


      {/* Rotas Privadas */}

      <Route
        path="/dashboard/validacao/registro/certificado/:hashAcordeon"
        exact
        component={ValidacaoCertificadoDashboard}
        isPrivate
      />

      <Route
        path="/dashboard/validacao/voucher"
        exact
        component={ValidacaoVoucher}
        isPrivate
      />

      <Route
        path="/dashboard/inicio/resumo"
        exact
        component={Dashboard}
        isPrivate
      />
      <Route path="/dashboard/index" exact component={Index} isPrivate />
      <Route
        path="/dashboard/registrar-musica"
        exact
        component={RegistrarMusica}
        isPrivate
      />
      <Route
        path="/dashboard/registrar-musica/registro-enviado"
        exact
        component={Registered}
        isPrivate
      />
      <Route
        path="/dashboard/registrar-musica/registro-erro"
        exact
        component={RegistryError}
        isPrivate
      />
      <Route
        path="/dashboard/inicio/resumo"
        exact
        component={Resume}
        isPrivate
      />
      <Route
        path="/dashboard/inicio/creditos"
        exact
        component={Credit}
        isPrivate
      />
      <Route
        path="/dashboard/adicionar-creditos/planos"
        exact
        component={AddPlan}
        isPrivate
      />
      <Route
        path="/dashboard/adicionar-creditos/finalizar-compra"
        exact
        component={AddCredit}
        isPrivate
      />
      <Route
        path="/dashboard/adicionar-creditos/compra-efetuada-cartao"
        exact
        component={SuccessCard}
        isPrivate
      />
      <Route
        path="/dashboard/adicionar-creditos/compra-efetuada-boleto"
        exact
        component={SuccessBillet}
        isPrivate
      />
      <Route
        path="/dashboard/adicionar-creditos/compra-efetuada-pix"
        exact
        component={SuccessPix}
        isPrivate
      />
      <Route
        path="/dashboard/inicio/pontos"
        exact
        component={Score}
        isPrivate
      />
      <Route
        path="/dashboard/adicionar-pontos"
        exact
        component={AdicionarPontos}
        isPrivate
      />
      <Route
        path="/dashboard/inicio/ranking"
        exact
        component={Ranking}
        isPrivate
      />
      <Route
        path="/dashboard/ranking"
        exact
        component={RankingGeral}
        isPrivate
      />
      {/* <Route path="/dashboard/pagSuccess" exact component={Inicio} isPrivate /> */}
      <Route path="/dashboard/perfil" exact component={Perfil} isPrivate />
      <Route
        path="/dashboard/perfil/alterar-senha"
        exact
        component={AlterarSenha}
        isPrivate
      />
      <Route
        path="/dashboard/minhas-musicas"
        exact
        component={MinhasMusicas}
        isPrivate
      />
      <Route
        path="/dashboard/musica-registrada/:key"
        exact
        component={RecordedMusicDashboard}
        isPrivate
      />
      <Route
        path="/dashboard/minhas-compras"
        exact
        component={MinhasCompras}
        isPrivate
      />
      <Route
        path="/dashboard/admin/lista-usuarios"
        exact
        component={Usuarios}
        isPrivate
      />
      <Route
        path="/dashboard/admin/configuracoes"
        exact
        component={Configuracoes}
        isPrivate
      />
      <Route
        path="/dashboard/tutoriais"
        exact
        component={Tutoriais}
        isPrivate
      />
      <Route
        path="/dashboard/tutoriais/video/:key"
        exact
        component={Video}
        isPrivate
      />
      <Route
        path="/dashboard/perguntas/geral"
        exact
        component={General}
        isPrivate
      />
      <Route
        path="/dashboard/perguntas/registro-de-musica"
        exact
        component={Register}
        isPrivate
      />
      <Route
        path="/dashboard/perguntas/mercado-para-compositores"
        exact
        component={Market}
        isPrivate
      />
      <Route
        path="/dashboard/perguntas/juridico"
        exact
        component={Juridico}
        isPrivate
      />
      <Route
        path="/dashboard/perguntas/lancamento"
        exact
        component={Launch}
        isPrivate
      />
      <Route
        path="/dashboard/perguntas/novidades"
        exact
        component={News}
        isPrivate
      />

      <Route path="*" component={PageNotFound} />


      {/* Play Lists */}

      {/* <Route
        path="/dashboard/playlists"
        exact
        component={Playlists}
        isPrivate
      />
      <Route
        path="/dashboard/playlists/criar-playlist"
        exact
        component={CreatePlayList}
        isPrivate
      />
      <Route
        path="/dashboard/playlists/playlist-selecionada/:key"
        exact
        component={PlayListSelected}
        isPrivate
      /> */}
      {/* Tratar erro de rota */}
      {/* <Route path="*" component={Erro} /> */}
    </Switch>
  );
}
