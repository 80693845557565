import styled from 'styled-components';

export const BackgroundSectionForgotPass = styled.section`
    /* display: flex;
    justify-content: center;
    align-items: center; */
`;

export const DivForgotPass = styled.div`
    margin: 150px auto;
    max-width: 300px;

    color: #ffffff;

    h1 {
        font-size: 28px;
        padding-bottom: 1rem;
        display: flex;
        justify-content: center;
    }

    form {
        label {
            margin: 10px 0px 0px;
        }

        button {
            height: 2.8rem;
            margin-top: 25px;
        }
    }
`;
