import React from 'react';
import { Link } from 'react-router-dom';

import { BackgroundSection4, DivSection4 } from './styles';

export default function Section4() {
    return (
        <BackgroundSection4>
            <DivSection4 className="content">
                <h2>
                    Letra, ritmo, <br /> inspiração, transpiração<br />e emoção.
                </h2>
                <Link to="/login">
                    <button type="button" aria-label="Registrar música">Registrar música</button>
                </Link>
            </DivSection4>
        </BackgroundSection4>
    );
}
