import React from 'react';
import InputMask from 'react-input-mask';
// import { useField } from '@unform/core';
// import { FiAlertCircle } from 'react-icons/fi';

// import { Container, Error } from './styles';

const onlyNumbers = str => str.replace(/[^0-9]/g, '');

const MaskedInput = ({
  value,
  onChange,
  name,
  mask,
  placeholder,
  onFocus,
  label,
  className,
  id,
  style,
  disabled,
  // ...props
}) => {
  function handleChange(ev) {
    onChange({
      ...ev,
      target: {
        ...ev.target,
        name,
        onFocus,
        value: onlyNumbers(ev.target.value),
      },
    });
  }

  // const inputRef = useRef(null);
  // const [isFocused, setIsFocused] = useState(false);
  // const [isFilled, setIsFilled] = useState(false);

  // const { fieldName, defaultValue, error, registerField } = useField(name);

  // const handleInputFocus = useCallback(() => {
  //   setIsFocused(true);
  // }, []);

  // const handleInputBlur = useCallback(() => {
  //   setIsFocused(false);

  //   setIsFilled(!!inputRef.current.value);
  // }, []);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: 'value',
  //   });
  // }, [fieldName, registerField]);

  return (
    <>
      <label htmlFor="label">{label}</label>
      <InputMask
        placeholder={placeholder}
        name={name}
        mask={mask}
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        label={label}
        className={className}
        id={id}
        style={style}
        disabled={disabled}
      />
      {/* <Container isFilled={isFilled} isErrored={!!error} isFocused={isFocused}>
        <InputMask
          placeholder={placeholder}
          name={name}
          mask={mask}
          value={value}
          onChange={handleChange}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          label={label}
          defaultValue={defaultValue}
          ref={inputRef}
          {...props}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container> */}
    </>
  );
};

export default MaskedInput;
