import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

export default function Posts() {
  return (
    <S.DivPosts>
      <div className="center">
        <h1>Registre sua música por R$ 19,90</h1>

        <Link to="/dashboard/adicionar-creditos/planos">Adicionar créditos</Link>
      </div>
    </S.DivPosts>
  );
}
