import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import backgroundImg from '../../assets/img/backgroundLogin1.jpg';

export const BackgroundLogin = styled.section`
  display: flex;
`;

export const BackgroundSectionLeftLogin = styled.section`
  width: 50%;
  height: 90vh;
  background: url(${backgroundImg}) no-repeat;
  background-size: cover;
  background-position: center center;

  display: flex;
  justify-content: center;
  align-items: center;

  /* Responsividade */

  @media (max-width: 710px) {
    display: none;
    /* width: 100%;
        background-position: center center; */
  }
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const DivLeftLogin = styled.div`
  animation: ${appearFromLeft} 1s;

  max-width: 410px;
  margin: 0 20px;

  font-family: 'UniviaRegular';
  color: #fff;

  span {
    font-size: 36px;
    line-height: 3rem;
    max-width: 420px;
  }

  a {
    width: 250px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;

    background: transparent;
    border-radius: 17px;
    border: 2px solid #f34d21;

    margin-top: 2rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 2px 2px #f34d21;
    }
  }
`;

export const BackgroundSectionRightLogin = styled.section`
  width: 50%;
  height: 90vh;
  background: #181818 no-repeat;
  background-size: cover;
  position: absolute;
  top: 70px;
  right: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  /* Início Responsividade */

  @media (max-width: 710px) {
    position: relative;
    top: 0px;
    width: 100%;
  }

  /* Fim Responsividade */
`;

const appearFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const DivRightLogin = styled.div`
  animation: ${appearFromRight} 1s;

  color: #fff;

  span {
    font-size: 28px;
  }
  p {
    font-size: 20px;
  }

  form {
    width: 300px;
    display: flex;
    flex-direction: column;

    label {
      font-size: 15px;
      margin-bottom: 12px;
      color: #87868b;
    }

    a {
      margin-top: 1rem;
      color: #fe5812;
      font-size: 15px;
      cursor: pointer;

      &:hover {
        color: ${shade(0.2, '#f25812')};
      }
    }

    button.active {
      display: none;
    }

    img {
      margin: 0 auto;
    }
  }

  a.returnCadaster {
    display: none;
  }

  @media (max-width: 710px) {
    a.returnCadaster {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      font-size: 15px;
      color: #fe5812;

      > svg {
        margin-right: 5px;
      }

      &:hover {
        color: ${shade(0.2, '#f25812')};
      }
    }
  }
`;
