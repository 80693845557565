import styled from 'styled-components';

import backgroundImg from '../../../assets/img/backgroundHome.jpg';
import backgroundCel from '../../../assets/img/backgroundCel.jpg';

export const BackgroundSection1 = styled.main`
  width: 100%;
  background: url(${backgroundImg}) no-repeat;
  background-size: cover;
  position: relative;
  display: flex;

  /* background-position: center center; */

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      263.7deg,
      rgba(119, 55, 198, 0.8) 1.26%,
      rgba(188, 14, 165, 0.8) 96.99%
    );
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(359deg, #181818 1.81%, rgba(0, 0, 0, 0) 97.97%);
  }

  @media (min-width: 991px) {
    height: 95vh;
  }
  @media (max-width: 700px) {
    height: auto;
  }

  @media (max-width: 450px) {
    background: url(${backgroundCel}) no-repeat;
    background-size: cover;

    /* background-position: center center; */

    &::before {
      background: none;
    }
  }
`;

export const DivSection1 = styled.div`
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div.divLeftSection1 {
    margin-bottom: 40px;
  }

  div {
    h1 {
      max-width: 600px;
      font-family: 'UniviaBold';
      color: #ffffff;
      font-size: 50px;
      letter-spacing: 0.05em;
      line-height: 1.3;
    }

    a {
      width: 20rem;
      height: 3rem;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.7rem;

      font-family: 'Roboto', sans-serif;
      color: #fafafa;
      font-size: 18px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 20px;
      outline: none;
      border: 0;

      &:hover {
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }

    aside {
      font-family: 'Roboto', sans-serif;
      color: #fff;
      font-size: 15px;
      margin: 0.8rem 1.1rem;
    }
  }

  span#rs {
    color: #fff;
    font-size: 2.2rem;
    font-family: 'UniviaRegular';
    text-align: right;
    margin: 0 0 100px 240px;

    @media (max-width: 1140px) {
      margin: -100px 0px 0 60px;
    }
  }
  div#value {
    color: #fff;

    span#thirty-four {
      font-size: 10.5rem;
      font-family: 'BoldItalic';
    }

    span#cents {
      font-size: 1.5rem;
      font-family: 'BoldItalic';
    }

    span#by-music {
      font-family: 'UniviaLight';
      font-size: 1.1rem;
      float: right;
      margin-top: -2rem;
    }
  }

  /* Responsividade */

  @media (min-width: 800px) and (max-width: 990px) {
    div {
      h1 {
        font-size: 40px;
      }
      a {
        width: 15rem;
        height: 2.5rem;
        font-size: 17px;
      }
      aside {
        font-size: 13px;
        margin: 0.8rem auto;
      }
    }
    div#value {
      span#thirty-four {
        font-size: 10rem;
      }
    }
    div.divLeftSection1 {
      margin-top: 30px;
    }
  }

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    a {
      margin: 0 auto;
    }

    span#rs {
      position: relative;
      top: 100px;
      right: 180px;
    }

    div#value {
      span#thirty-four {
        font-size: 8.5rem;
      }
    }
  }

  @media (min-width: 700px) and (max-width: 799px) {
    div.divLeftSection1 {
      margin-top: 80px;
    }
  }

  @media (max-width: 699px) {
    div.divLeftSection1 {
      margin-top: 30px;
    }
  }

  @media (max-width: 775px) {
    span#rs {
      position: relative;
      top: 80px;
      right: 150px;
    }
  }

  @media (width: 812px) and (height: 375px) {
    /* div#value, span#rs {
      display: none;
    } */
    div.divLeftSection1 {
      margin-top: 30px;
    }
  }
  @media (width: 740px) and (height: 360px) {
    /* div#value, span#rs {
      display: none;
    } */
  }

  @media (max-width: 450px) {
    div {
      h1 {
        font-family: 'UniviaMedium';
        letter-spacing: 0.08em;
        line-height: 3.5rem;
        font-size: 3rem;
      }
      button {
        font-size: 1.3rem;
        letter-spacing: 0.05em;
        padding: 1rem 5.6rem;
      }
      aside {
        font-size: 1rem;
      }
    }

    div#value {
      margin-top: 20px;
    }
  }
`;
