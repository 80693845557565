import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

// import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import { Link, useLocation } from 'react-router-dom';
// import ReactPlayer from 'react-player';
import Index from '~/pages/Index';
import Posts from '~/components/Posts';

import * as S from './styles';
// import Modal from '~/components/Modal';

export function Perguntas({ children }) {
  const location = useLocation();

  return (
    <Index>
      <Posts />
      <S.BackgroundSection>
        <nav>
          <ul>
            <li
              className={
                location.pathname.split('/')[3] === 'geral' ? 'isSelected' : ''
              }
            >
              <Link to="/dashboard/perguntas/geral">Dúvidas</Link>
            </li>
            {/* <li
              className={
                location.pathname.split('/')[3] === 'registro-de-musica'
                  ? 'isSelected'
                  : ''
              }
            >
              <Link to="/dashboard/perguntas/registro-de-musica">
                Registro de música
              </Link>
            </li>
            <li
              className={
                location.pathname.split('/')[3] === 'mercado-para-compositores'
                  ? 'isSelected'
                  : ''
              }
            >
              <Link to="/dashboard/perguntas/mercado-para-compositores">
                Mercado para compositores
              </Link>
            </li>
            <li
              className={
                location.pathname.split('/')[3] === 'juridico'
                  ? 'isSelected'
                  : ''
              }
            >
              <Link to="/dashboard/perguntas/juridico">Jurídico</Link>
            </li>
            <li
              className={
                location.pathname.split('/')[3] === 'lancamento'
                  ? 'isSelected'
                  : ''
              }
            >
              <Link to="/dashboard/perguntas/lancamento">Lançamento</Link>
            </li>
            <li
              className={
                location.pathname.split('/')[3] === 'novidades'
                  ? 'isSelected'
                  : ''
              }
            >
              <Link to="/dashboard/perguntas/novidades">Novidades</Link>
            </li> */}
          </ul>
        </nav>
      </S.BackgroundSection>
      {children}
    </Index>
  );
}

export function General() {
  // const [modalShow, setModalShow] = useState('');

  return (
    <Perguntas>
      <S.DivSection>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>O registro da AcordeOn® tem validade jurídica?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Sim! O registro da AcordeOn® utiliza a certificação de* Infraestrutura de Chaves Públicas Brasileira, ICP-Brasil, instituída através da MEDIDA PROVISÓRIA No 2.200-2, DE 24 DE AGOSTO DE 2001., que garante ao certificado de registro de música da AcordeOn® autenticidade, integridade e validade jurídica, obedecendo rigorosamente a lei!
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span>
                Como verificar se o Certificado da AcordeOn® tem validade
                jurídica?
              </span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
              Para conferir se o certificado de registro da sua música está em conformidade com a legislação nacional e consequentemente a sua validade jurídica, basta confirmar diretamente no “Verificador de Conformidade” diretamente no site do ITI – Instituto Nacional de Tecnologia da Informação, autarquia federal responsável pela Infraestrutura de Chaves Públicas Brasileira, ICP-Brasil. Para isso é necessário anexar o arquivo Ps7. ou o certificado de registro em PDF e submeter a verificação. Dessa forma você poderá conferir diretamente no site da autarquia federal se o Certificado de Registro da sua música está assinado em conformidade com a legislação nacional e, dessa forma, sua validade jurídica!
Pra ficar ainda mais transparente e cômodo pra você, a AcordeOn® disponibiliza todos esses documentos e links no seu próprio certificado. Onde você pode acessá-los lendo com a câmera do seu celular o QR-Code “Validação ICP-Brasil”, ou clicando diretamente no Código Hash, sempre iniciado em “AON-...” no seu certificado no formato digital. Acesse o
                <a
                  target="_blank"
                  href="https://validar.iti.gov.br"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Verificador de Conformidade aqui.{' '}
                </a>
                {/* e veja o passo a passo de como fazer:{' '}
                <button type="button" onClick={() => setModalShow('show')}>
                  {' '}
                  Vídeo tutorial{' '}
                </button>
                {modalShow === 'show' && (
                  <Modal
                    isOpen={modalShow}
                    onClickClose={() => setModalShow('')}
                  >
                    {/* <ReactPlayer
                      controls
                      className="knowVideos"
                      url="https://www.youtube.com/watch?v=Pfm8M3q-4TY&t=9008s&ab_channel=CozyRain"
                    /> */}{/*
                    <div className="knowVideos">
                      <LiteYoutubeEmbed id="Pfm8M3q-4TY" mute={false} isMobile imageAltText="Imagem alternativa para vídeo" iframeTitle="Se torne um grande compositor de sucesso!"/>
                    </div>
                  </Modal>
                )} */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Registrei uma música de outra pessoa! Ela é minha?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Não! Segundo a Lei de Direitos Autorais o registro de Direito Autoral é declaratório, com validade jurídica e prova de anterioridade, e não uma concessão, como nos casos de marcas e patentes. Dessa forma se você registrou uma música de autoria de outra pessoa em seu nome, se o verdadeiro autor comprovar documentalmente a autoria a música será dele e você responderá por isso.
                Então, só registre uma música se ela for de sua autoria!
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <span>Qual registro tem mais validade? AcordeOn® ou biblioteca Nacional?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                Ambos possuem a mesma validade jurídica e garantem autenticidade, integridade e inviolabilidade da obra, e nenhum dos dois apresenta mais validade que o outro.
                Tanto o registro na Biblioteca Nacional quanto o registro da AcordeOn® possuem proteção legal e são aceitos judicialmente!
                A MP-2.200-2, garante a validade jurídica do registro que utilize o padrão ICP-Brasil, para justamente facilitar, desburocratizar e dar garantia jurídica aos documentos assinados com tal padrão.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <span>O Certificado de Registro de Música da AcordeOn® vale internacionalmente?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                SIM! A partir da Convenção de Berna, em que o Brasil faz parte, o direito do autor quando válido em um dos países membros, se torna válido, também, nos demais países do tratado. Hoje, dos 193 países do mundo, mais de 170 países fazem parte da convenção.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              <span>Como altero meus dados?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
              Para alterar seus dados clique na aba “Perfil” ou <Link to="/dashboard/perfil">clique aqui</Link>.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              <span>Como coloco minha foto no perfil?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                Acesse a aba “perfil”, vá em “Avatar” na aba superior da página e clique em “Enviar Foto”, escolha a sua melhor foto e em seguida clique em “salvar informações” na parte inferior da página.
                Lembrando que para escolher a foto, a imagem deve estar no aparelho celular ou computador que você está usando e estar nos formatos JPG, PNG ou GIF, de até 5 MB.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              <span>Como alterar minha senha?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
                Acesse a aba “perfil”, na lateral esquerda, e na parte inferior da página vá em “senha” e clique em Redefinir senha. Após isso, na página de redefinição de senha, digite a sua senha atual, em seguida a nova senha e depois clique em ALTERAR SENHA.
                Lembre-se de salvar a nova senha em um lugar seguro!
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="8">
              <span>Meus créditos têm validade?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                Não. Os créditos adquiridos não têm validade e não expiram! Você pode comprar os créditos agora e só usar futuramente. Isso te dá ainda mais segurança e te possibilita poder comprar os créditos com o valor muito mais em conta sem ter obrigatoriedade de usá-los todos de uma vez.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="9">
              <span>Como faço pra subir no Ranking?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="9">
              <Card.Body>
                O ranking é baseado no total de pontos que você ganhou até o momento, sendo o primeiro colocado o compositor que fez mais pontos e os demais em ordem crescente. Ou seja, pra subir no Ranking você tem que ganhar o máximo de pontos que puder.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="10">
              <span>Como emitir meu certificado?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="10">
              <Card.Body>
                Todos os seus certificados de registro de música são enviados por email e ficam disponíveis também na aba “Minhas Músicas” -&gt; “certificado”, ao lado de cada música registrada.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="11">
              <span>Tenho que pagar algo a mais para manter minha música registrada?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="11">
              <Card.Body>
                Não! Na AcordeOn® você adquire o crédito, efetua o registro da música, recebe o certificado e não precisa pagar mais nada! E ainda usufrui de todos os benefícios sem custo algum!
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="12">
              <span>Como compro créditos?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="12">
              <Card.Body>
                Para comprar créditos <Link to="/dashboard/adicionar-creditos/planos">clique aqui</Link>.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/* <Card>
            <Accordion.Toggle as={Card.Header} eventKey="13">
              <span>Até quando posso usar meu bônus de música Grátis?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="13">
              <Card.Body>
                O bônus de música grátis para primeiro cadastro não tem validade e pode ser usado quando você quiser!
              </Card.Body>
            </Accordion.Collapse>
          </Card> */}
        </Accordion>
      </S.DivSection>
    </Perguntas>
  );
}

export function Register() {
  return (
    <Perguntas>
      <S.DivSection>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>Como Registrar uma música?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span>Como registrar músicas com pontos?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Como consultar minhas músicas registradas?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </S.DivSection>
    </Perguntas>
  );
}

export function Market() {
  return (
    <Perguntas>
      <S.DivSection>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>Como o mercado da música estar pós pandemia?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span>Como me destacar no mercado?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Como ter criatividade para compôr?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </S.DivSection>
    </Perguntas>
  );
}

export function Juridico() {
  return (
    <Perguntas>
      <S.DivSection>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>Como funciona o Jurídico?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span>Qual selo no certificado?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Posso ficar seguro?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </S.DivSection>
    </Perguntas>
  );
}

export function Launch() {
  return (
    <Perguntas>
      <S.DivSection>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>Quando lançam vídeos novos?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span>Como participar dos vídeos?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Como ser mais reconhecido entre compositores?</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </S.DivSection>
    </Perguntas>
  );
}

export function News() {
  return (
    <Perguntas>
      <S.DivSection>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>Novidades</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span>Novas músicas</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Novas músicas</span>
              <span>+</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Starting in macOS version 10.15 (Catalina), Apple has dropped
                support for 32-bit applications. For macOS 10.15 and all
                versions moving forward, AcordeonX 10.1.4 and later must be
                used. However, earlier versions of MEGA can be ran on macOS
                10.15 and later by using a Windows version of Acordeon with
                virtualization software such as VMWareFusion.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </S.DivSection>
    </Perguntas>
  );
}
