import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const BackgroundSectionRegister = styled.section`
  z-index: -1;
  width: 100%;
  height: 90vh;
  background: #181818 no-repeat;

  /* position: relative; */

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const DivSectionLeftRegister = styled.div`
  animation: ${appearFromLeft} 1s;

  margin: 30px;

  font-family: 'UniviaRegular';
  color: #fff;

  span {
    font-size: 55px;
  }

  p {
    font-size: 1.3rem;

    a {
      color: #fe5812;

      &:hover {
        color: ${shade(0.2, '#f25812')};
      }
    }
  }

  /* Responsividade */

  @media (max-width: 820px) {
    display: none;
  }
`;

const appearFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const DivSectionRightRegister = styled.div`
  animation: ${appearFromRight} 1s;

  color: #fff;
  margin: 30px;

  p {
    font-size: 16px;
    font-family: 'UniviaRegular';
    max-width: 300px;
  }

  form {
    width: 300px;
    display: flex;
    flex-direction: column;

    div.termos {
      display: flex;
      align-items: center;
      margin: 8px 0;

      span {
        font-size: 16px;
        padding-left: 10px;
        button {
          border: 0;
          background: none;
          color: #fe5812;

          &:hover {
            color: ${shade(0.2, '#f25812')};
          }
        }
      }
    }
    button {
      margin-top: 0 !important;
    }

    button.active {
      display: none;
    }

    img {
      margin: 0 auto;
    }
  }

  div {
    h6 {
      font-size: 10px;
    }
  }

  a.returnLogin {
    display: none;
  }

  @media (max-width: 710px) {
    a.returnLogin {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      font-size: 15px;
      color: #fe5812;

      > svg {
        margin-right: 5px;
      }

      &:hover {
        color: ${shade(0.2, '#f25812')};
      }
    }

  }

  @media (max-width: 650px) {
    margin: 30px;
  }
`;
