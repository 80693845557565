import React, { useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import * as S from './styles';

const MAX_ITEMS = 3;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

const Pagination = ({ limit, total, setCurrentPages }) => {
  const [offset, setOffset] = useState(0);
  const divi = offset / limit;
  const current = offset ? divi + 1 : 1;
  const pages = Math.ceil(total / limit);
  const first = Math.max(current - MAX_LEFT, 1);

  function onPageChange(page) {
    setOffset((page - 1) * limit);
    setCurrentPages(page);
  }

  return (
    <S.UlPagination>
      <li className={pages <= 1 ? 'paginatin__item--disabled' : ''}>
        <button
          type="button"
          onClick={() => onPageChange(current - 1)}
          disabled={current === 1}
        >
          <BiChevronLeft />
        </button>
      </li>
      {Array.from({ length: Math.min(MAX_ITEMS, pages) })
        .map((_, index) => index + first)
        .map(page => (
          <li
            key={page}
            className={pages <= 1 ? 'paginatin__item--disabled' : ''}
          >
            <button
              type="button"
              onClick={() => onPageChange(page)}
              className={page === current ? 'paginatin__item--active' : ''}
              disabled={page === current}
            >
              {page}
            </button>
          </li>
        ))}
      <li className={pages <= 1 ? 'paginatin__item--disabled' : ''}>
        <button
          type="button"
          onClick={() => onPageChange(current + 1)}
          disabled={current === pages}
        >
          <BiChevronRight />
        </button>
      </li>
    </S.UlPagination>
  );
};

export default Pagination;
