import styled from 'styled-components';

export const SectionNotMusic = styled.section`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 550px;

  div.sucess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    img {
      width: 150px;
      color: #43b581;
    }

    span {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #fff;

      background-color: #222222;
      padding: 5px 60px;
      border-radius: 20px;
    }
  }

  div.resume {
    width: 80%;
    margin: 0 auto;

    h1 {
      font-size: 20px;
      margin: 40px 0 5px;
    }

    table {
      color: #ffffff;
      background: #363636;
      border-radius: 5px;
      font-size: 16px;

      div.orderService {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 10px 0;
      }

      tbody {
        tr {
          display: flex;
          justify-content: space-between;

          border-bottom: 1px solid #4e4e4e;
        }
        tr:last-child {
          border-bottom: none;
        }
        tr.disable,
        hr.disable {
          display: none;
        }
      }
    }

    a {
      max-width: 210px;
      height: 40px;
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #f34d21, #f5134b);
      color: white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #222222 inset;
      border-radius: 20px;
      margin: 40px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      outline: none;
      transition: all 0.5s ease;
      font-style: normal;

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }
    }
  }
`;
