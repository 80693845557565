import styled from 'styled-components';

export const BackgroundSectionAvatar = styled.section`
    width: 100%;
    height: 90vh;
    background: #181818 no-repeat;
    z-index: -1;

    display: flex;
    justify-content: center;
`;

export const DivAvatar = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    font-family: 'UniviaRegular';
    color: #ffffff;

    ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
            cursor: pointer;
            margin: 10px;

            img {
                width: 5rem;
            }
        }
    }

    h1 {
        font-size: 55px;
        padding-bottom: 1rem;
    }

    & button {
        width: 12rem;
        align-self: flex-end;

        display: flex;
        justify-content: center;
        align-items: center;

        border: solid 1px transparent;
        background-image: linear-gradient(
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0)
            ),
            linear-gradient(101deg, #f34d21, #f5134b);
        color: white;
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #181818 inset;
        border-radius: 20px;
        padding: 0.6rem;
        font-size: 18px;
        outline: none;
        transition: all 0.5s ease;
        font-style: normal;
        font-weight: 500;

        &:hover {
            cursor: pointer;
            box-shadow: none;
        }

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 7px;
        }
    }
`;
