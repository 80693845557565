/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
// import { BsXCircle } from 'react-icons/bs';
// import { BiChevronDown } from 'react-icons/bi';
import Moment from 'moment';
import CPF from 'cpf';

import * as Yup from 'yup';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';
import Index from '~/pages/Index';
import api from '~/services/api';
import { useProfile } from '~/hooks/profile';
import { useToast } from '~/hooks/toast';

import Posts from '~/components/Posts';
import Input from '~/components/Input';
import InputM from '~/components/InputM';

import { useAuth } from '~/hooks/auth';


import getValidationErrors from '~/utils/getValidationErrors';

import loadingGif from '~/assets/img/loadingMusic.gif';
import Avatar1 from '~/assets/img/Avatar1.jpg';
import Avatar2 from '~/assets/img/Avatar2.jpg';
import Avatar3 from '~/assets/img/Avatar3.jpg';
import Avatar4 from '~/assets/img/Avatar4.jpg';
import Avatar5 from '~/assets/img/Avatar5.jpg';
import ShowError from '~/components/ShowError';
import Modal from '~/components/Modal';
import { formatTextCapitalize } from '~/utils/formatTextCapitalize';
import * as S from './styles';

export default function Perfil() {
  const formRef = useRef(null);
  const history = useHistory();
  const {
    dataPerson,
    dataProfile,
    setDataPerson,
    listTypeContacts,
  } = useProfile();
  const { updateUser } = useAuth();
  const { addToast } = useToast();

  const [loadingUploadAvatar, setloadingUploadAvatar] = useState(false);
  const [genre, setGenre] = useState('');
  const [stateUF, setStateUF] = useState('');
  const [contact, setContact] = useState(7);
  const [disable, setDisable] = useState('');
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState(false);
  const [erroUF, setErroUF] = useState(false);
  const [modalShow, setModalShow] = useState('');
  const [avatar, setAvatar] = useState(null);


  useEffect(() => {
    setGenre(dataPerson.gender);

    if (formRef.current !== null) {
      formRef.current.setFieldValue('nickname', dataProfile.nickname);
      formRef.current.setFieldValue('email', dataProfile.email);
      const { addresses } = dataPerson;
      const { contacts } = dataPerson;
      setStateUF(addresses?.state);

      if (contacts !== undefined) {
        setContact(Number(contacts.category_id));
      }

      if (addresses) {
        formRef.current.setData({
          fullname: dataPerson.fullname,
            gender: dataPerson.gender,
            birthday:
              dataPerson.birthday === null
                ? ''
                : Moment(dataPerson.birthday).format('DD/MM/YYYY'),
          cpf: dataPerson.cpf,
          rg: dataPerson.rg,
          addresses: {
            zipcode: addresses.zipcode,
            street: addresses.street,
            street_number: addresses.street_number,
            complement: addresses.complement,
            neighborhood: addresses.neighborhood,
            city: addresses.city,
            state: addresses.state,
            // country: addresses.country,
          },
        });
      }

      if (dataPerson.contacts) {
        formRef.current.setFieldValue('contacts.category_id', Number(contacts.category_id));
        formRef.current.setFieldValue('contacts.number', contacts.number);
      }
    }
  }, [dataPerson, dataProfile]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const date = Moment(data.birthday, 'DD/MM/YYYY');
        const validCPF = data.cpf.replace(/[^0-9]/g, '');
        const validContact = data.contacts.number.replace(/[^0-9]/g, '');

        const schema = Yup.object().shape({
          fullname: Yup.string().required('Nome completo obrigatório'),
          cpf: Yup.string()
            .required('CPF obrigatório')
            .min(11, 'No mínimo 11 dígitos'),
          // gender: Yup.string().required('Gênero obrigatório'),
          birthday: Yup.string().required('Data de nascimento obrigatório'),
          addresses: Yup.object().shape({
            zipcode: Yup.string().required('CEP obrigatório'),
            street: Yup.string().required('Rua/Av obrigatória'),
            street_number: Yup.string().required('Número obrigatório'),
            neighborhood: Yup.string().required('Bairro obrigatório'),
            city: Yup.string().required('Cidade obrigatório'),
            // state: Yup.string().required('Estado obrigatório'),
            // country: Yup.string().required('País obrigatório'),
          }),
          contacts: Yup.object().shape({
            number: Yup.string().required('Número obrigatório'),
          }),
          // nickname: Yup.string().required('Apelido obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (genre === '') {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'Você precisa preencher todos os campos indicados!',
          });
          return setErro(true);
        }
        setErro(false);
        if (stateUF === '') {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'Você precisa preencher todos os campos indicados!',
          });
          return setErroUF(true);
        }
        setErroUF(false);

        if (!CPF.isValid(validCPF, true)) {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'Você precisa preencher todos os campos indicados!',
          });
          return formRef.current.setFieldError('cpf', 'CPF incompleto');
        }

        if (!CPF.isValid(validCPF)) {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'O CPF fornecido é inválido, tente novamente!',
          });
          return formRef.current.setFieldError('cpf', 'CPF inválido');
        }

        setDisable(disable === '' ? 'active' : '');
        setLoading(true);

        await api.put('/profile/completed', {
          ...data,
          cpf: validCPF,
          fullname: formatTextCapitalize(data.fullname),
          birthday: date.format('YYYY-MM-DD'),
          gender: genre,
          contacts: {
            category_id: contact,
            name: dataProfile.nickname,
            number: `+55${validContact}`,
          },
          addresses: {
            ...data.addresses,
            state: stateUF,
          }
        });

        await setDataPerson(data);

        history.push('/dashboard/adicionar-creditos/planos');

        addToast({
          type: 'success',
          title: `Perfil atualizado`,
          description: `Seus dados foram atualizados!`,
        });
        setLoading(false);
        setDisable(disable === 'active' ? '' : '');
      } catch (error) {
        setLoading(false);
        setDisable(disable === 'active' ? '' : '');

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          addToast({
            type: 'error',
            title: 'Erro ao tentar atualizar perfil',
            description: 'Você precisa preencher todos os campos indicados!',
          });

          return formRef.current.setErrors(errors);
        }

        // const msg = error.response.data.message;

        addToast({
          type: 'error',
          title: 'Erro',
          description: `Erro ao tentar completar perfil`,
        });
      }
    },
    [addToast, contact, dataProfile.nickname, disable, genre, history, setDataPerson, stateUF]
  );


  const handleAvatarChange = useCallback(
    e => {
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const selectedFile = e.target.files[0];
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        return addToast({
          type: 'error',
          title: 'Tipo de arquivo inválido. Por favor carregue uma foto (jpg, jpeg, png).',
        });
      }

      if (e.target.files) {
        setloadingUploadAvatar(true);
        const data = new FormData();

        data.append('avatar', selectedFile);

        api
          .post('/profile/avatar', data, {
            onUploadProgress: progressEvent => {
              console.log(
                `Upload Progress: ${Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                )}%`
              );
            },
          })
          .then(response => {
            updateUser(response.data);

            // history.push('/dashboard/inicio/resumo');
            setloadingUploadAvatar(false);

            addToast({
              type: 'success',
              title: 'Foto do perfil atualizada!',
            });

            document.location.reload(true);
          });
      }
    },
    [addToast, updateUser]
  );

  const handleClickAvatar = id => {
    setModalShow('show');
    setAvatar(id);
  };

  const handleUpdateAvatar = () => {
    api.post(`/profile/user/avatar/fake/${avatar}`).then(_ => {
      addToast({
        type: 'success',
        title: 'Foto do perfil atualizada!',
      });
      document.location.reload(true);
    });
  };



  return (
    <Index>
      <Posts />
      <S.BackgroundSection>
        <S.DivAvatar>
          <div className="data">
            <h1>Avatar:</h1>
            <ul>
              <li>
                <button type='button' className='buttonAvatar' onClick={() => handleClickAvatar(1)}>
                  <img src={Avatar1} alt="Avatar1" />
                </button>
              </li>
              <li>
                <button type='button' className='buttonAvatar' onClick={() => handleClickAvatar(2)}>
                  <img src={Avatar2} alt="Avatar2" />
                </button>
              </li>
              <li>
                <button type='button' className='buttonAvatar' onClick={() => handleClickAvatar(3)}>
                  <img src={Avatar3} alt="Avatar3" />
                </button>
              </li>
              <li>
                <button type='button' className='buttonAvatar' onClick={() => handleClickAvatar(4)}>
                  <img src={Avatar4} alt="Avatar4" />
                </button>
              </li>
              <li>
                <button type='button' className='buttonAvatar' onClick={() => handleClickAvatar(5)}>
                  <img src={Avatar5} alt="Avatar5" />
                </button>
              </li>
              <label
                htmlFor="avatar"
                className={loadingUploadAvatar ? 'active' : ''}
              >
                Enviar foto
                <input type="file" id="avatar" accept="image/*" onChange={handleAvatarChange} />
              </label>
              {loadingUploadAvatar && (
                <img src={loadingGif} alt="Carregando..." width="50px" />
              )}
            </ul>
          </div>
        </S.DivAvatar>
        <S.DivPersonal>
          <Form onSubmit={handleSubmit} ref={formRef} autoComplete="off">
            <div className="data">
              <h1>Dados pessoais:</h1>
              <div className="divColumn100">
                <Input
                  label="Nome completo:"
                  type="text"
                  name="fullname"
                  id="fullname"
                  textTranform="capitalize"
                />
              </div>
              <div className="inputsRow">
                <div className="divColumn48">
                  <InputM
                    mask="999.999.999-99"
                    label="CPF:"
                    type="text"
                    name="cpf"
                  />
                </div>
                <div className="divColumn48">
                  <Input label="RG:" type="text" name="rg" maxLength="15" />
                </div>
              </div>
              <div className="inputsRow">
                <div className="divColumn48">
                  <Input
                    type="select"
                    name="gender"
                    id="genderNone"
                  />
                  <label htmlFor="selectGender">Gênero:</label>
                  <div className="select">
                    <select
                      label="Gênero:"
                      type="select"
                      id="selectGender"
                      value={genre}
                      onChange={e => setGenre(e.target.value)}
                    >
                      <option value="" />
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                      <option value="O">Outro</option>
                    </select>
                    {erro && <ShowError description="Selecione um gênero" />}
                  </div>
                </div>
                <div className="divColumn48">
                  <InputM
                    mask="99/99/9999"
                    label="Data de nascimento:"
                    type="text"
                    name="birthday"
                    id="birthday"
                  />
                </div>
              </div>
            </div>
            <div className="data">
              <h1>Endereço:</h1>
              <Scope path="addresses">
                <div className="inputsRow">
                  <div className="divColumn30">
                    <InputM
                      mask="99999-999"
                      label="CEP:"
                      type="text"
                      name="zipcode"
                      id="zipcode"
                    />
                  </div>
                  <div className="divColumn67">
                    <Input
                      label="Rua/Av.:"
                      type="text"
                      name="street"
                      id="street"
                    />
                  </div>
                </div>
                <div className="inputsRow">
                  <div className="divColumn30">
                    <Input
                      label="Número:"
                      type="text"
                      name="street_number"
                      id="street_number"
                    />
                  </div>
                  <div className="divColumn67">
                    <Input
                      label="Complemento:"
                      type="text"
                      name="complement"
                      id="complement"
                    />
                  </div>
                </div>
                <div className="inputsRow">
                  <div className="divColumn30">
                    <Input
                      label="Bairro:"
                      type="text"
                      name="neighborhood"
                      id="neighborhood"
                      textTranform="capitalize"
                    />
                  </div>
                  <div className="divColumn20">
                    <Input label="Cidade:" type="text" name="city" id="city" textTranform="capitalize" />
                  </div>
                  <div className="divColumn20">
                    <label htmlFor="selectStateUF">Estado:</label>
                    <div className="select">
                      <select
                        type="select"
                        id="selectStateUF"
                        value={stateUF}
                        onChange={e => setStateUF(e.target.value)}
                      >
                        <option value="" />
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </select>
                      {erroUF && <ShowError description="Selecione um estado" />}
                    </div>
                  </div>
                  <div className="divColumn20">
                    <Input
                      label="País:"
                      type="text"
                      name="country"
                      id="country"
                      value='Brasil'
                      disabled
                      style={{
                        cursor: 'no-drop',
                        color: '#7C7C7D',
                      }}
                    />
                  </div>
                </div>
              </Scope>
            </div>
            <div className="data">
              <h1>Contato:</h1>
              <div className="inputsRow">
                <Scope path="contacts">
                  <div className="divColumn48">
                    <Input type="select" name="category_id" id="genderNone" />
                    <label htmlFor="contato">Tipo de Contato:</label>
                    <div className="select">
                      <select
                        type="select"
                        // id="selectPortion"
                        // name="category_id"
                        value={contact}
                        onChange={e => setContact(Number(e.target.value))}
                      >
                        {listTypeContacts.map(cont => (
                          <option key={cont.id} value={cont.id}>
                            {cont.description}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="divColumn48">
                    <InputM
                      label="Fone:"
                      type="tel"
                      name="number"
                      mask={
                        contact === 7 ? '(99) 99999-9999' : '(99) 9999-9999'
                      }
                    />
                    {/* )} */}
                    {/* {contact ===
                      6(
                        <InputM
                          label="Celular:"
                          type="tel"
                          name="contacts.number"
                          mask="(99) 99999-9999"
                        />
                      )}
                    {contact ===
                      7(
                        <InputM
                          label="Telefone fixo:"
                          type="tel"
                          name="contacts.number"
                          mask="(99) 9999-9999"
                        />
                    )} */}
                  </div>
                </Scope>
              </div>
            </div>
            <div className="data">
              <div className="headerProfileName">
                <h1>Seu perfil na Acordeon:</h1>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      O nome artístico após criado, só poderá ser alterado com
                      solicitação à ACORDEON® pelo seguinte contato:
                      suporte@registreacordeon.com.br
                    </Tooltip>
                  }
                >
                  <span variant="secondary" className="know">
                    <BsQuestionCircle />
                    Saiba mais
                  </span>
                </OverlayTrigger>
              </div>
              <div className="inputsRow">
                <div className="divColumn48">
                  <Input
                    label="Nome artístico:"
                    type="name"
                    name="nickname"
                    id="nickname"
                    // maxLength="20"
                    // value={dataProfile?.nickname}
                    disabled
                    style={{
                      cursor: 'no-drop',
                      color: '#7C7C7D',
                    }}
                  />
                </div>
                <div className="divColumn48">
                  <Input
                    label="Email:"
                    type="email"
                    name="email"
                    id="email"
                    // value={dataProfile?.email}
                    disabled
                    style={{
                      cursor: 'no-drop',
                      color: '#7C7C7D',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="divPassword">
              <div className="hr">
                <div className="line">
                  <div className="passColumn">
                    <span className="pass">Senha</span>
                    <span>
                      Para redefinir senha basta clicar no link ao lado.
                    </span>
                  </div>
                  <div>
                    <Link
                      to="/dashboard/perfil/alterar-senha"
                      className="clasLink"
                    >
                      Redefinir senha
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <button
              className={`${disable} saveData`}
              type="submit"
              disabled={loading}
            >
              Salvar informações
            </button>
            {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}
          </Form>
        </S.DivPersonal>
      </S.BackgroundSection>
      {/* Modais */}
      {modalShow === 'show' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <div>
            {avatar === 1 && (
              <img className="avatar" src={Avatar1} alt="Avatar1" />
            )}
            {avatar === 2 && (
              <img className="avatar" src={Avatar2} alt="Avatar2" />
            )}
            {avatar === 3 && (
              <img className="avatar" src={Avatar3} alt="Avatar3" />
            )}
            {avatar === 4 && (
              <img className="avatar" src={Avatar4} alt="Avatar4" />
            )}
            {avatar === 5 && (
              <img className="avatar" src={Avatar5} alt="Avatar5" />
            )}
            {/* {avatar === 6 && (
              <img className="avatar" src={Avatar4} alt="Avatar6" />
            )}
            {avatar === 7 && (
              <img className="avatar" src={Avatar5} alt="Avatar7" />
            )} */}
          </div>
          <div className="remove">
            <div className="alignButtonsRemove">
              <button
                className="buttonsRemove"
                type="button"
                onClick={() => setModalShow('')}
              >
                Cancelar
              </button>
              <button
                className="buttonsCancel"
                type="button"
                onClick={() => handleUpdateAvatar()}
              >
                Confirmar
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Index>
  );
}
