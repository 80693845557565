import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Moment from 'moment';

import Inicio from '~/subpages/Inicio';
import Loading from '../Loading';

import * as S from './styles';
import { useProfile } from '~/hooks/profile';
import Pagination from '~/components/Pagination';

export default function ContentStart({
  title,
  textButton,
  textTooltip,
  tooltip,
  historyName,
  histories,
  link,
  children,
  limit,
  inteiro,
  setCurrentPages,
  total,
  typeObject,
}) {
  const { dataProfile } = useProfile();
  const genertorIDs = Math.random()
    .toString(36)
    .substring(7);
  // const [inteiro, setInteiro] = useState();

  // const limit = 5;
  // const [currentPages, setCurrentPages] = useState(1);

  // const value = total - before;
  // const sinal = value < 0 ? '-' : '+';

  const date = histories?.map(history =>
    Moment(history.updated_at).format('DD/MM/YYYY')
  );
  const novoArray = [];

  for (let i = 0; i < histories?.length; i += 1) {
    const beforeElement = histories[i - 1];
console.log('beforeElement', beforeElement)
    if (typeObject === 'creditUsed') {
      if (!beforeElement) {
        if (total - histories[i]?.creditUsed) {
          novoArray.push({
            value: total - histories[i]?.creditUsed,
            date: date[i],
            id: genertorIDs,
          });
        }
      } else if (beforeElement.creditUsed - histories[i]?.creditUsed) {
        novoArray.push({
          value: beforeElement.creditUsed - histories[i]?.creditUsed,
          date: date[i],
          id: genertorIDs,
        });
      }
    }

    if (typeObject === 'scoreUsed') {
      if (!beforeElement) {
        if (total - histories[i]?.scoreUsed) {
          novoArray.push({
            value: total - histories[i]?.scoreUsed,
            date: date[i],
            id: genertorIDs,
          });
        }
      } else if (beforeElement.scoreUsed - histories[i]?.scoreUsed) {
        novoArray.push({
          value: beforeElement.scoreUsed - histories[i]?.scoreUsed,
          date: date[i],
          id: genertorIDs,
        });
      }
    }

    if (typeObject === 'score') {
      if (!beforeElement) {
        novoArray.push({
          value: total - histories[i]?.score,
          date: date[i],
          id: genertorIDs,
        });
      } else {
        novoArray.push({
          value: beforeElement.score - histories[i]?.score,
          date: date[i],
          id: genertorIDs,
        });
      }
    }
  }

  novoArray.forEach((item, i) => {
    item.id = i + 1;
  }, []);

  // const data = value <= 9 ? `${sinal} 0${value}` : `${sinal} ${value}`;

  return (
    <Inicio>
      <S.BackgroundSection>
        {dataProfile.length === 0 ? (
          <Loading />
        ) : (
          <S.BackgroundDiv>
            <div className="divleft">
              <h1>{title}</h1>

              <div className="center">{children}</div>

              <Link to={link}>{textButton}</Link>

              <div className="alignTooltip">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{tooltip}</Tooltip>}
                >
                  <span className="clasLink" variant="secondary">
                    <BsQuestionCircle />
                    {textTooltip}
                  </span>
                </OverlayTrigger>
              </div>
            </div>
            {(novoArray.length > 1) && (
              <>
                <div className="line" />
                <div className="divright">
                  <h1 className="h1Historic">Histórico:</h1>
                  <div className="historic">
                    <table>
                      <tbody>
                        <tr className="thead">
                          <td>DATA</td>
                          <td>{historyName}</td>
                        </tr>
                      </tbody>
                      <tbody>
                        {novoArray?.map(history => (
                          <tr key={history.id}>
                            <td>{history.date}</td>
                            <td>
                              {history.value <= 9
                                ? `${history.value < 0 ? '' : '+'}${history.value}`
                                : `${history.value < 0 ? '' : '+'}${history.value}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div>
                      {inteiro && (
                        <Pagination
                          limit={limit}
                          total={inteiro}
                          setCurrentPages={setCurrentPages}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </S.BackgroundDiv>
        )}
      </S.BackgroundSection>
    </Inicio>
  );
}
