import styled, { css } from 'styled-components';

import Tooltip from '~/components/Tooltip';

export const Container = styled.div`
  width: 100%;
  height: 2rem;
  color: #87868b;

  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    input {
      width: 30px;
      height: 20px;
      cursor: pointer;
      margin-bottom: 1px;
    }
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
`;

export const Error = styled(Tooltip)`
  margin-left: 24px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
