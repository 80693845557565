import styled from 'styled-components';

export const DivVideoRow = styled.div`
  margin-bottom: 35px;

  h2 {
    font-size: 19px;
    margin: 0px 0px 0px 30px;
  }

  .divArea {
    .videoRowLeft,
    .videoRowRight {
      position: absolute;
      width: 50px;
      height: 250px;
      z-index: 99;
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0;
      transition: all ease 0.5s;
    }

    .videoRowLeft {
      left: 1;
      background: linear-gradient(to right, #111111 15%, transparent 85%);
      display: flex;
      justify-content: flex-start;
    }

    .videoRowRight {
      right: 0;
      background: linear-gradient(to left, #111111 15%, transparent 85%);
      display: flex;
      justify-content: flex-end;
      svg {
        font-weight: 900px;
      }
    }
  }

  .divArea:hover .videoRowLeft,
  .divArea:hover .videoRowRight {
    opacity: 1;
  }
`;

export const DivVideoRowListArea = styled.div`
  overflow-x: hidden;
  padding-left: 30px;
`;

export const DivVideoRowList = styled.div`
  transition: all ease 0.5s;
`;

export const DivVideoRowItem = styled.div`
  display: inline-block;
  width: 200px;
  cursor: pointer;
  margin: 10px;

  img {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  div.cardVideo {
    width: 100%;
    transform: scale(0.9);
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1);

      div.titleTema {
        background-color: #222222;
      }
    }
  }

  div.titleTema {
    width: 100%;

    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    display: flex;
    flex-direction: column;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;

    span.title {
      font-size: 15px;
    }
    span.tema {
      font-size: 12px;
      color: #c5c5c5;
    }
  }
`;
