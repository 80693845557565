import React from 'react';
import ReactDOM from 'react-dom';
import { BsX } from 'react-icons/bs';

import * as S from './styles';

const postalRoot = document.getElementById('portal-root');

const Modal = ({ children, isOpen, onClickClose }) => {
  if (!isOpen) {
    return '';
  }

  return ReactDOM.createPortal(
    <S.DivModalOverlay>
      <S.DivModal>
        <button type="button" onClick={onClickClose}>
          <BsX size="20px" />
        </button>
        <div>{children}</div>
      </S.DivModal>
    </S.DivModalOverlay>,
    postalRoot
  );
};

export default Modal;
