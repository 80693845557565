import styled from 'styled-components';

export const BackgroundSectionForgotPass = styled.section`
    width: 100%;
    height: 90vh;
    background: #181818 no-repeat;
    z-index: -1;

    display: flex;
    justify-content: center;
`;

export const DivForgotPass = styled.div`
    margin: 0 60px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;

    h1 {
        font-size: 28px;
        padding-bottom: 1rem;
    }

    form {
        label {
            margin: 10px 0px 0px;
        }

        button {
            height: 2.8rem;
            margin-top: 25px;
        }

        button.active {
            display: none;
        }

        img {
            margin: 10px 100px;
        }
    }
`;
