import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { useHistory, useParams } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';

import api from '~/services/api';
import loadingGif from '~/assets/img/loadingMusic.gif';
import { useToast } from '~/hooks/toast';
import Input from '~/components/Input';
import Button from '~/components/Button';
import getValidationErrors from '~/utils/getValidationErrors';

import { BackgroundSectionForgotPass, DivForgotPass } from './styles';

const NewPass = () => {
  const { hash } = useParams();

  const formRef = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [disable, setDisable] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hash) {
      api
        .get(`/auth/forgot/password/${hash}`)
        .then(res => {
          const { message } = res.data;

          addToast({
            type: 'success',
            title: `${message}`,
            description: `Insira uma nova senha`,
          });
        })
        .catch(error => {
          history.push('/redefinir-senha');
          const { message } = error.response.data;

          addToast({
            type: 'error',
            title: `${message}`,
            description: `Tente novamente enviando uma nova solicitação ao seu e-mail.`,
          });
        });
    }
  }, [addToast, hash, history]);

  const handleSubmit = useCallback(
    async data => {
      // console.log(hash);
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string()
            .required('Insira uma nova senha')
            .min(6, 'No mínimo 6 dígitos'),
          password_confirmation: Yup.string()
            .required('Insira uma senha')
            .oneOf([Yup.ref('password'), null], 'As senhas devem corresponder'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setDisable(disable === '' ? 'active' : '');

        setLoading(true);

        const response = await api.put('/auth/change/forgot/password', {
          token: hash,
          password: data.password,
          password_confirmation: data.password_confirmation,
        });

        const { message } = response.data;

        setLoading(false);

        history.push(`/login/senha-alterada/${message}`);

        addToast({
          type: 'success',
          title: 'Senha alterada!',
          description: `${message}`,
        });
      } catch (err) {
        setLoading(false);
        setDisable(disable === 'active' ? '' : '');

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        const { message } = err.response.data;

        addToast({
          type: 'error',
          title: `${message}`,
          description:
            'Tente novamente enviando uma nova solicitação ao seu e-mail.',
        });
      }
    },
    [addToast, hash, history, disable]
  );

  return (
    <BackgroundSectionForgotPass>
      <DivForgotPass className="cont">
        <h1>Criar uma nova senha</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            type="password"
            name="password"
            label="Nova senha:"
            icon={FiLock}
          />
          <Input
            type="password"
            name="password_confirmation"
            label="Confirme a nova senha:"
            icon={FiLock}
          />
          <Button type="submit" disabled={loading} className={disable}>
            Alterar senha
          </Button>
          {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}
        </Form>
      </DivForgotPass>
    </BackgroundSectionForgotPass>
  );
};

export default NewPass;
