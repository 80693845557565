const PaymentMethodEnum = {

  BILLET:           4,          // boleto
  CREDIT_CARD:      5,          // cartão de crédito
  VOUCHER:         28,          // voucher
  PIX:             29,          // pix

  properties: {
    4: {label: 'boleto',                value: 'boleto'},
    5: {label: 'cartão de crédito',     value: 'credit_card'},
   28: {label: 'voucher',               value: 'voucher'},
   29: {label: 'pix',                   value: 'pix'},
  }
};

module.exports = PaymentMethodEnum;
