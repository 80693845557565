import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection4 = styled.section`
  width: 100%;
  height: auto;
  background: #222222;
`;

export const DivSection4 = styled.div`
  .barCenter {
    display: flex;
    justify-content: space-between;

    ul {
      width: 200px;
    }

    ul.center {
      @media (max-width: 900px) {
        width: 255px;
        li {
          margin-bottom: 8px;
        }
      }
    }

    ul li h3 {
      font-family: 'UniviaMedium';
      color: #fff;
      font-size: 20px;
      margin-top: 4.375rem;
      margin-bottom: 1.875rem;
    }

    span, a {
      font-family: 'UniviaRegular';
      color: hsl(0deg 0% 70%);
      font-size: 16px;
      transition: 0.3s;
      text-align: left;

      line-height: 2.4rem;
      &:hover {
        color: ${shade(0, '#fff')};
      }
    }
  }

  button {
    background-color: transparent;
    border: 0;
    outline: 0;

    font-family: 'UniviaRegular';
    color: hsl(0deg 0% 70%);
    font-size: 16px;
    transition: 0.3s;
    text-align: left;

    cursor: pointer;
    line-height: 2.4rem;

    &:hover {
      color: ${shade(0, '#fff')};
    }

    @media (max-width: 900px) {
      margin-bottom: 8px;
    }
  }

  hr {
    width: 100%;
    border: 0.01rem solid #363636;
    margin: 3rem 0 1.25rem 0;
  }

  .barBottom {
    display: flex;
    justify-content: space-between;

    font-family: 'UniviaRegular';
    color: hsl(0deg 0% 70%);
    font-size: 0.938rem;

    figure {
      display: flex;
      align-items: center;

      svg {
        max-width: 30px;
        max-height: 30px;
      }

      figcaption {
        margin-left: 10px;
      }
    }

    nav ul {
      display: flex;
      align-items: center;

      li {
        margin: 0 0.5rem;
        cursor: pointer;

        svg {
          transition: transform 0.3s;

          &:hover {
            transform: translateY(-10px);
          }
        }
      }
    }
  }

  @media (max-width: 730px) {
    div.barCenter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    div.barBottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
