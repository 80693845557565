import styled from 'styled-components';
import { shade } from 'polished';

export const Header = styled.header`
  width: 100%;
  height: 150px;
  background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);

  color: #fff;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;

  @media (max-width: 1200px) {
    padding: 0 150px;
  }
  @media (max-width: 1000px) {
    padding: 0 100px;
  }
  @media (max-width: 600px) {
    padding: 0 50px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }

  div.nameMusic {
    display: flex;
    flex-direction: column;
    font-size: 'Roboto';

    h1 {
      font-weight: 500;
      font-size: 28px;
    }
    span {
      font-weight: normal;
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  div.author {
    display: flex;
    align-items: center;
    font-family: 'UniviaRegular';

    font-style: normal;
    font-weight: normal;

    div.holder {
      height: 120px;
      width: 120px;
      object-fit: cover;
      img {
        /* overflow: hidden; */
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    div {
      margin-left: 20px;
      span {
        font-size: 16px;
      }

      h1 {
        font-size: 30px;
        margin: 0;
      }

      span.contact {
        font-size: 14px;
      }
    }
  }
`;

export const BackgroundSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #181818;
  /* height: 590px; */

  div.activeAlignHeight {
    height: 90vh;
  }

  div.align {
    padding: 30px 70px;

    div.share {
      float: right;

      button {
        color: #ffffff;
        background-color: transparent;
        border: 0;
        font-size: 15px;

        &:hover {
          color: ${shade(0.2, '#ffffff')};
        }

        svg {
          margin-left: 10px;
        }
      }

      @media (max-width: 1200px) {
        margin-top: 20px;
      }
    }

    table {
      font-size: 16px;
      display: flex;
      justify-content: space-around;

      tbody {
        color: #ffffff;
        width: 100%;
        tr {
          margin-top: 20px;
          background: #222222;
          border-radius: 5px;

          border-width: 2px;
          border-style: solid;
          border-color: #363636;

          display: flex;
          justify-content: space-between;
          align-items: center;

          td.leftItens {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 250px;

            @media (max-width: 700px) {
              width: 100%;
              display: contents;
            }

            font-size: 15px;
            span.nameMusic {
              color: #ffffff;
            }
            span.nameComposer {
              color: #7c7c7d;
              /* height: 20px; */
            }
          }

          td.audioPlay {
            display: flex;
            justify-content: center;
            width: 500px;

            @media (max-width: 700px) {
              width: 100%;
            }
          }

          td.iconDownloadMusic {
            display: flex;
            justify-content: flex-end;
            width: 50px;

            button {
              background: none;
              color: #f34d21;
              border: 0;
              margin-right: 25px;
              font-size: 15px;

              &:hover {
                color: ${shade(0.2, '#f34d21')};
              }
            }

            @media (max-width: 700px) {
              width: 100%;
              display: flex;
              justify-content: center;
                button {
                  margin: 0;
                }
            }
          }

          .rhap_horizontal .rhap_controls-section {
              width: 1rem !important;
          }

          div.rhap_container {
            outline: none;
            box-shadow: 0 0 0;

            background-color: transparent;
            border: 0;
            padding: 0;

            & svg {
              color: #f34d21 !important;
            }
          }

          div.rhap_time {
            color: #363636 !important;
          }

          div.rhap_progress-bar-show-download,
          div.rhap_download-progress {
            background-color: #181818 !important;
          }

          div.rhap_progress-indicator,
          div.rhap_progress-filled {
            background: linear-gradient(
              251.45deg,
              #f5134b 1.26%,
              #f34d21 96.99%
            ) !important;
          }

          @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
            padding: 1rem 0.5rem;
          }
        }
      }
    }

    div.divAlignMusics {
      display: flex;
      color: #ffffff;
      font-size: 15px;

      div.co-authors {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
      }
      div.interprets {
        display: flex;
        flex-direction: column;
      }
    }

    div.letterMusic {
      margin-bottom: 2rem;
      span {
        color: #f34d21;
        margin: 20px 0;
        display: block;
      }
      p {
        color: #ffffff;
        margin: 0;
      }
    }

    @media (max-width: 1200px) {
      padding: 0 150px;
    }
    @media (max-width: 1000px) {
      padding: 0 100px;
    }
    @media (max-width: 700px) {
      padding: 0 50px;
    }
  }
`;
