import React, { useState } from 'react';
import styled from 'styled-components';

import Nav from './nav';

const StyledBurger = styled.div`
  /* display: none; */
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 24px;
  right: 36px;
  z-index: 20;
  cursor: pointer;

  @media (max-width: 450px) {
    display: block;
  }

  @media (max-width: 820px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;

    font-size: 100% !important;
  }

  div {
    width: 3rem;
    height: 0.3rem;
    background-color: #fff;
    transform-origin: 3px;
    transition: all 0.3s linear;
    display: none;
    border-radius: 10px;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }

    @media (max-width: 820px) {
      display: block;
    }
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Nav open={open} setOpen={setOpen} />
    </>
  );
};

export default Burger;
