import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const BackgroundSectionForgotPass = styled.section`
    width: 100%;
    height: 90vh;
    background: #181818 no-repeat;
    z-index: -1;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const appearFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const DivRightLogin = styled.div`
    animation: ${appearFromRight} 1s;

    margin: 0 30px;

    color: #fff;

    h1 {
        font-size: 28px;
        padding-bottom: 1rem;
    }

    p {
        font-size: 16px;
        margin-bottom: -0.01rem;
    }

    form {
        max-width: 7250px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        input {
            width: 100%;
        }

        button {
            width: 10rem;
            align-self: flex-end;

            font-size: 18px;
            color: #ffffff;

            background: linear-gradient(
                251.45deg,
                #f5134b 1.26%,
                #f34d21 96.99%
            );
            border-radius: 17px;
            outline: none;
            border: 0;
            padding: 0.4rem 0;

            &:hover {
                box-shadow: 0 0 2px 2px #f34d21;
            }
        }
        button.active {
            display: none;
        }

        img {
            align-self: flex-end;
            margin: 10px 20px 0 0;
        }
    }

    a {
        position: relative;
        top: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fe5812;
        font-size: 15px;
        cursor: pointer;

        > svg {
            margin-right: 10px;
        }

        &:hover {
            color: ${shade(0.2, '#f25812')};
        }
    }
`;
