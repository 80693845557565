import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import * as S from './styles';

export default function MovieRow({ title, items }) {
  const [scrollX, setScrollX] = useState(0);

  const handleLeftArrow = () => {
    let x = scrollX + Math.round(window.innerWidth / 2);

    if (x > 0) {
      x = 0;
    }

    setScrollX(x);
  };

  const handleRightArrow = () => {
    let x = scrollX - Math.round(window.innerWidth / 2);
    const listW = items.results.length * 200;
    if (window.innerWidth - listW > x) {
      x = window.innerWidth - listW - 60;
    }

    setScrollX(x);
  };

  return (
    <S.DivVideoRow>
      <div className="divArea">
        <h2>
          {title} <FiChevronRight />{' '}
        </h2>
        {items.results?.length >= 5 && (
          <>
            <div className="videoRowLeft" onClick={handleLeftArrow}>
              <FiChevronLeft size={60} />
            </div>
            <div className="videoRowRight" onClick={handleRightArrow}>
              <FiChevronRight size={60} />
            </div>
          </>
        )}
        <S.DivVideoRowListArea>
          <S.DivVideoRowList
            style={{
              marginLeft: scrollX,
              width: items.results?.length * 250,
            }}
          >
            {items.results.length > 0 &&
              items.results.map(item => (
                <S.DivVideoRowItem key={item.key}>
                  <Link to={`/dashboard/tutoriais/video/${item.key}`}>
                    <div className="cardVideo">
                      <img src={item.backdrop[1].link} alt={item.title} />
                      <div className="titleTema">
                        <span className="title">{item.title}</span>
                        <span className="tema">Tema: {item.genres}</span>
                      </div>
                    </div>
                  </Link>
                </S.DivVideoRowItem>
              ))}
          </S.DivVideoRowList>
        </S.DivVideoRowListArea>
      </div>
    </S.DivVideoRow>
  );
}
