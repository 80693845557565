import React from 'react';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

const AuthLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default AuthLayout;
