import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  font-family: 'Roboto', sans-serif;
  margin: 50px 100px;
  color: #ffffff;

  @media (max-width: 700px) {
    margin: 50px 20px;
  }

  h1 {
    font-size: 35px;
  }

  form {
    margin-bottom: 55px;
    label {
      margin-top: 20px;
      margin-bottom: 3px;
    }

    div.twoInputsRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div.divColumn {
        display: flex;
        flex-direction: column;
        width: 49%;
      }

      div.divAddColumn {
        display: flex;
        flex-direction: column;
        width: 47%;
      }

      button {
        height: 0;
        border: 0;
        margin-top: 21px;
        color: #87868b;

        &:hover {
          cursor: pointer;
          color: ${shade(0.2, '#f5134b')};
        }
      }
    }

    div.buttonsInserts {
      button {
        margin-top: 25px;
        background: none;
        border: none;

        font-size: 18px;
        color: #fff;

        &:hover {
          color: ${shade(0.2, '#fff')};
        }
      }
    }

    div.hr:after {
      content: '';
      width: 100%;
      height: 1px;
      background: #363636;
      display: block;
      margin: 15px 0;
    }
  }

  div.divAlignItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
    height: 20px;

    div.styleMusic {
      display: flex;
      align-items: center;
      font-size: 16px;

      span {
        margin-right: 10px;
      }

      div.inputIcon {
        position: relative;

        input {
            width: 105px;
            height: 30px;
            background: #222222;
            border: none;
            border-radius: 5px;

            margin-right: 7px;
            padding-left: 30px;
            color: #ffffff;
            font-size: 14px;
        }
        svg {
          position: absolute;
          left: 10px;
          top: 8px;
        }
      }
      @media (max-width: 500px) {
          span {
            font-size: 14px;
          }
      }
    }
    div.alignTooltip {
      span {
        display: flex;
        align-items: center;

        color: #fe5812;
        font-size: 14px;

        svg {
          margin: 0 5px 3px 0;
        }

        &:hover {
          cursor: help;
          color: ${shade(0.2, '#f25812')};
        }
      }
    }
    @media (max-width: 500px) {
      div.alignTooltip {
        display: none;
      }
    }
  }

  div.genre {
    display: flex;
    flex-wrap: wrap;
    max-height: 110px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 20px;
    }

    div {
      li,
      button {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #222222;
        padding: 5px 10px;
        border-radius: 20px;
        border: 1px solid #363636;
        margin: 5px;
        cursor: pointer;

        font-size: 14px;

        &:hover {
          background: ${shade(0.2, '#222222')};
        }

        svg {
          margin-left: 10px;
        }
      }
      button.active {
        background: ${shade(0.5, '#f34d21')};
      }
      li {
        background: ${shade(0.5, '#f34d21')};
      }
    }
  }

  div.active {
    border: solid 2px #f91717 !important;
    padding: 10px;
  }
  div.know {
    width: 360px;
    display: flex;
    flex-direction: column;

    & + div.know {

      @media (max-width: 700px) {
        margin-left: 0;
      }
    }

    span.know {
      margin-left: auto;
      font-size: 14px;
      color: #f34d21;
      margin-bottom: 5px;

      svg {
        margin: 0 5px 2px 0;
      }

      &:hover {
        cursor: help;
        color: ${shade(0.2, '#f34d21')};
      }
    }
  }
`;

export const DivButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;

  span.checkmark {
    font-size: 16px;
    padding-left: 10px;
    margin-top: 1px;

    button {
      border: 0;
      background: none;
      color: #fe5812;

      &:hover {
        color: ${shade(0.2, '#f25812')};
      }
    }
  }

  button.registerMusic {
    font-family: 'Roboto', sans-serif;
    color: #fafafa;
    font-size: 12px;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 20px;
    outline: none;
    border: 0;
    padding: 0.7rem 7rem;
    margin-top: 10px;

    &:hover {
      box-shadow: 0 0 2px 2px #f34d21;
    }
  }

  @media (max-width: 500px) {
    div.checkbox {
      margin: 10px auto;
      max-width: 300px;
    }
  }
  @media (max-width: 500px) {
    span.checkmark {
      font-size: 12px;
    }
  }
`;

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  height: 104px;
  width: 100%;
  max-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    267.25deg,
    rgba(245, 19, 75, 0.1) 1.26%,
    rgba(243, 77, 33, 0.1) 96.99%
  );

  border: 1px solid rgba(243, 77, 33, 0.1);
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;

  transition: height 0.2s ease;

  ${props => props.isDragActive && dragActive}
  ${props => props.isDragReject && dragReject}

  ${({ activeMusic }) =>
    activeMusic &&
    css`
      background: linear-gradient(
        267.25deg,
        rgba(41, 178, 28, 0.1) 1.26%,
        rgba(33, 142, 22, 0.1) 96.99%
      );
      border: 1px solid rgba(41, 178, 28, 0.1);
    `}

  svg {
    margin-right: 10px;
    min-width: 34px;
  }

  &:hover {
    box-shadow: 0 0 2px 2px #f34d21;
  }

  p.invisible {
    display: none;
  }

  p.nameMusic {
    color: #78e5d5;
    margin: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5',
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${props => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  height: 104px;
  width: 100%;
  max-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    267.25deg,
    rgba(245, 19, 75, 0.1) 1.26%,
    rgba(243, 77, 33, 0.1) 96.99%
  );
  color: #999;
  border: 1px solid rgba(243, 77, 33, 0.1);
  border-radius: 4px;
  cursor: pointer;

  ${({ activeLetter }) =>
    activeLetter &&
    css`
      background: linear-gradient(
        267.25deg,
        rgba(41, 178, 28, 0.1) 1.26%,
        rgba(33, 142, 22, 0.1) 96.99%
      );
      border: 1px solid rgba(41, 178, 28, 0.1);
    `}

  .add {
    box-shadow: 0 0 2px 2px #f34d21;
  }

  svg {
    margin: 0 10px;
    color: #f34d21;
    font-size: 20px;
    min-width: 20px;
  }

  font.let {
    margin-top: 1px;
    font-size: 17px;
    color: #999;
  }

  @media (max-width: 775px) {
    font.let {
      font-size: 14px;
    }
  }

  &:hover {
    box-shadow: 0 0 2px 2px #f34d21;
  }

  input[type='file'] {
    display: none;
  }

  label.selectMP3 {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  gap: 20px;

  .rhap_horizontal .rhap_controls-section {
      width: 1rem !important;
  }


  div.rhap_container {
    outline: none;
    box-shadow: 0 0 0;

    background-color: transparent;
    border: 0;
    padding: 0;

    & svg {
      color: #f34d21 !important;
    }
  }

  .rhap_volume-controls {
    display: none;
  }

  div.rhap_time {
    color: #363636 !important;
  }

  div.rhap_progress-bar-show-download,
  div.rhap_download-progress {
    background-color: #181818 !important;
  }

  div.rhap_progress-indicator,
  div.rhap_progress-filled {
    background: linear-gradient(
      251.45deg,
      #f5134b 1.26%,
      #f34d21 96.99%
    ) !important;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 1rem 0.5rem;
  }
`;

export const SectionMusicSubmetido = styled.section`
  margin: 70px auto;
  display: flex;
  justify-content: center;

  div {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    img {
      width: 150px;
      color: #43b581;
    }

    span.sucess {
      font-size: 17px;
      color: #43b581;
      background-color: #222222;
      padding: 15px 20px;
      border-radius: 20px;
    }

    span.validation {
      font-size: 20px;
      color: #c5c5c5;
      padding: 30px 0;
    }

    a {
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #f34d21, #f5134b);
      color: white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #222222 inset;
      border-radius: 20px;
      padding: 0.3rem 2rem;
      font-size: 15px;
      outline: none;
      transition: all 0.5s ease;
      font-style: normal;

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }
    }
  }
`;
