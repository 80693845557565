/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route as ReactDOMRoute, Redirect, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/auth';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
  const locations = useLocation();
  const { user } = useAuth();

  const Layout = user ? DefaultLayout : AuthLayout;

  // useEffect(() => {
    // setKeyMusic(locations.pathname.split('/')[3])
  // }, [keyMusic])

  // function verificandoRotas() {
  //       if (isPrivate) {
  //         return '/'
  //       }
  //       return locations.pathname.split('/')[1] === 'musica-registrada' ? `/dashboard/musica-registrada/:key` : '/dashboard/inicio/resumo'
  //   }

  function verificandoRotas() {
    if (locations.pathname.split('/')[1] === 'musica-registrada') {
      const keyMusic = locations.pathname.split('/')[2];
      return `/dashboard/musica-registrada/${keyMusic}`
    }
    if (locations.pathname.split('/')[1] === 'validacao') {
      const hashAcordeon = locations.pathname.split('/')[4];
      return `/dashboard/validacao/registro/certificado/${hashAcordeon}`
    }
      return '/dashboard/inicio/resumo'
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : verificandoRotas(),
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
