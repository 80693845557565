import React, { useEffect } from 'react';

import socket from '~/services/socket';
import { useToast } from '~/hooks/toast';
import { useAuth } from '~/hooks/auth';
// import Section1 from '~/components/Dashboard/Section1';
// import Index from '~/pages/Index';
import Resume from '~/subpages/Inicio/Resume';
import { useProfile } from '~/hooks/profile';
// import Loading from '~/components/Loading';

export default function Dashboard() {
  const { dataProfile, setDataProfile, setDataRanking } = useProfile();

  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    socket.connect();
    const ws = socket.subscribe(`paymentNotifications:${user.key}`);

    ws.on('processing', data => {
      // ex resposta: O pagamento da ordem: 0ba0b7a8-eddb-439e-b6ae-6b408fca870a. Está com o status cancelado!
      // alert('Ebaaa, seus creditos foram processados com sucesso. '+ data.status);
      addToast({
        type: `${data.typeResponse}`,
        title: `Pagamento`,
        description: `________________Transação Nº: ${data.transaction_id}
                    Status: ${data.status.name}.
                    ${data.message}`,
      });

      // window.location.reload();
    });

    // ws.on('error', (error) => {
    //   alert('Serviço de dados indisponível.');
    //   console.error(error)
    // });
  }, [addToast, user]);

  useEffect(() => {
    socket.connect();
    const ws = socket.subscribe(`notificationsClient:${user.key}`);
    ws.on('clientAcquire', data => {
      if (data) {
        setDataRanking(data.ranking);
        dataProfile.wallet = data.wallet;
        setDataProfile(dataProfile.wallet);
      }
    });
  }, [dataProfile, setDataProfile, setDataRanking, user]);

  return <Resume />;
  // return <>{dataProfile.key ? <Resume /> : <Loading />}</>;
  // return <>{dataProfile.key ? <Resume /> : '...'}</>;
}
