const OrderStatusEnum = {

  PROCESSING:       6,          // processando
  AUTHORIZED:       7,          // autorizado
  PAID:             8,          // pago
  REFUNDED:         9,          // estornado
  WAITING_PAYMENT:  10,         // aguardando pagamento
  PENDING_REFUND:   11,         // estorno pendente
  REFUSED:          12,         // recusado
  CHARGEDBACK:      13,         // chargedback
  ANALYZING:        14,         // analisando
  PENDING_REVIEW:   15,         // revisão pendente

  properties: {
    6: {label: 'processando',           value: 'processing'},
    7: {label: 'autorizado',            value: 'authorized'},
    8: {label: 'pago',                  value: 'paid'},
    9: {label: 'estornado',             value: 'refunded'},
   10: {label: 'aguardando pagamento',  value: 'waiting_payment'},
   11: {label: 'estorno pendente',      value: 'pending_refund'},
   12: {label: 'recusado',              value: 'refused'},
   13: {label: 'chargedback',           value: 'chargedback'},
   14: {label: 'analisando',            value: 'analyzing'},
   15: {label: 'revisão pendente',      value: 'pending_review'},
  }
};

module.exports = OrderStatusEnum;
