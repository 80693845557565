import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import Index from '~/pages/Index';
import Posts from '~/components/Posts';
import { useToast } from '~/hooks/toast';

import * as S from './styles';

export default function RegistryError() {
  const { addToast } = useToast();

  useEffect(() => {
    addToast({
      type: 'error',
      title: 'Erro no registro',
      description: 'Falha no registro da música, caso persista entre em contato com nosso suporte!',
    });
  }, [addToast]);

  return (
    <Index>
      <Posts />
      <S.SectionNotMusic>
        <div>
          <span className="x">X</span>
          <span className="sucess">
            Houve um erro ao tentar registrar sua música!
          </span>
          <span className="validation">
            Confira se todos os dados estão corretamente preenchidos.
          </span>
          <Link to="/dashboard/registrar-musica">
            Voltar para o registro de música
          </Link>
        </div>
      </S.SectionNotMusic>
    </Index>
  );
}
