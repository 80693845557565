import React from 'react';

import { MdCloudUpload } from 'react-icons/md';
import Avatar1 from '~/assets/img/Avatar1.jpg';
import Avatar2 from '~/assets/img/Avatar2.jpg';
import Avatar3 from '~/assets/img/Avatar3.jpg';
import Avatar4 from '~/assets/img/Avatar4.jpg';
import Avatar5 from '~/assets/img/Avatar5.jpg';

import { BackgroundSectionAvatar, DivAvatar } from './styles';

export default function Avatar() {
  return (
    <BackgroundSectionAvatar>
      <DivAvatar className="cont">
        <h1>Escolha seu avatar</h1>
        <ul>
          <li>
            <img src={Avatar1} alt="Avatar1" />
          </li>
          <li>
            <img src={Avatar2} alt="Avatar2" />
          </li>
          <li>
            <img src={Avatar3} alt="Avatar3" />
          </li>
          <li>
            <img src={Avatar4} alt="Avatar4" />
          </li>
          <li>
            <img src={Avatar5} alt="Avatar5" />
          </li>
          <li>
            <img src={Avatar4} alt="Avatar6" />
          </li>
          <li>
            <img src={Avatar5} alt="Avatar7" />
          </li>
        </ul>
        <button type="button" aria-label="Submeter sua foto para o perfil">
          <i>
            <MdCloudUpload />
          </i>
          Enviar foto
        </button>
      </DivAvatar>
    </BackgroundSectionAvatar>
  );
}
