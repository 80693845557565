import React from 'react';
import { Link } from 'react-router-dom';

import { BackgroundHeader } from './styles';

import logo from '../../assets/img/logo.svg';
import Burger from './burger';

const Header = () => {
  return (
    <>
      <BackgroundHeader>
        <div className="content">
          <Link to="/">
            <div className="logo">
              <img src={logo} srcSet={`${logo} 300w, ${logo} 768w, ${logo} 1280w`} alt="Logo Acordeon" />
            </div>
          </Link>
          <Burger />
        </div>
      </BackgroundHeader>
    </>
  );
};

export default Header;
