import React, { useEffect, useState } from 'react';

import { useProfile } from '~/hooks/profile';
import ContentStart from '~/components/ContentStart';
import Loading from '~/components/Loading';
import api from '~/services/api';

export default function Score() {
  const { dataProfile } = useProfile();
  const [historiesScore, setHistoriesScore] = useState();
  const [inteiro, setInteiro] = useState();

  const limit = 5;
  const [currentPages, setCurrentPages] = useState(1);

  useEffect(() => {
    api
      .get(`/user/history/wallet?page=${currentPages}&limit=${limit}`)
      .then(response => {
        setHistoriesScore(response.data.data);
        const { total } = response.data;
        setInteiro(total);
      });
  }, [currentPages]);

  const scoreTotal = dataProfile?.wallet?.scoreUsed;

  // const value = scoreTotal - scoreBefore;

  // const sinal = value < 0 ? '-' : '+';

  return (
    <>
      {dataProfile.length === 0 ? (
        <Loading />
      ) : (
        <ContentStart
          title="Seus pontos:"
          textButton="Ganhar mais pontos"
          textTooltip="O que são os pontos?"
          tooltip="Pontos são bonificações concedidas pela AcordeOn® em determinadas ações executadas na plataforma, sendo as principais formas: comprar um crédito e registrar uma música, gerando 1 ponto cada. Esses pontos são somados e compõem o ranking."
          historyName="PONTOS"
          total={scoreTotal}
          typeObject="scoreUsed"
          histories={historiesScore}
          // historyDataValue={value}
          inteiro={inteiro}
          limit={limit}
          setCurrentPages={setCurrentPages}
          link="/dashboard/adicionar-pontos"
        >
          <div>
            <span className="clasNumberCred">
              {scoreTotal <= 9
                ? `0${scoreTotal}`
                : scoreTotal}
            </span>
            <aside>
              {scoreTotal <= 1 ? 'Ponto' : 'Pontos'}
            </aside>
          </div>
        </ContentStart>
      )}
    </>
  );
}
