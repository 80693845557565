import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 70px;

  @media (max-width: 600px) {
    margin: 0 20px;
  }

  h1 {
    margin: 40px auto 20px !important;
    font-size: 1.7rem;
    color: #f34d21;
  }
  h2 {
    font-size: 1.4rem;
    margin: 20px 0 10px;
  }
  font {
    font-size: 18px;
  }
`;
