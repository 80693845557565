import React from 'react';
import { Link } from 'react-router-dom';
import { BackgroundSection1, DivSection1 } from './styles';

export default function Section1() {
  return (
    <BackgroundSection1>
      <DivSection1 className="content">
        <div className="divLeftSection1">
          <h1>Registre sua música com validade jurídica em 172 países!</h1>
          <Link to="/login">Registrar música</Link>
        </div>
        <span id="rs">R$</span>
        <div id="value">
          <span>
            <span id="thirty-four">19</span>
            <span id="cents">,90</span>
            <br />
            <span id="by-music">Por música</span>
          </span>
        </div>
      </DivSection1>
    </BackgroundSection1>
  );
}
