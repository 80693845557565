import styled, { css } from 'styled-components';

export const BackgroundSection = styled.div`
  display: flex;
  z-index: -1;
  color: #ffffff;
`;

export const SectionLeft = styled.section`
  background-color: #181818;
  width: 25%;
  height: auto;
  display: flex;
  flex-direction: column;

  div {
    font-family: 'UniviaRegular';
    display: flex;
    flex-direction: column;

    img {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      object-fit: cover;

      margin: 20px auto;
    }

    h1 {
      font-size: 25px;
      text-align: center;
      margin: auto 20px;
      color: #ffffff;
      text-transform: capitalize;
    }
  }

  div.tab-links {
    margin-bottom: 3rem;
    button.buttonRegister {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      color: #fafafa;
      font-size: 19px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 15px;
      outline: none;
      border: 0;
      width: 80%;
      height: 40px;

      &:hover {
        box-shadow: 0 0 2px 2px #f34d21;
      }
      &.isSelected {
        color: #fafafa;
        box-shadow: 0 0 2px 2px #f34d21;
        background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      }
    }

    a {
      text-align: left;
      font-size: 20px;
      padding: 15px 0 15px 30px;
      cursor: pointer;
      background-color: transparent;
      border: 0;

      transition: all ease 0.3s;

      text-decoration: none;
      color: #7c7c7d;

      &:hover {
        color: #ffffff;
      }
      &.isSelected {
        color: #e84c3d;
        background: #222222;
      }
    }
  }

  @media (max-width: 1130px) {
    flex-flow: column nowrap;
    background-color: #0e0e0e;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-150%)')};
    position: absolute;
    top: 70px;
    left: 0;
    height: auto;
    width: 250px;
    z-index: 10;
    padding-top: 3.5rem;
    transition: 0.7s all;
    border-radius: 0 20px 20px 0;

    div.tab-links a.isSelected {
      background: none;
    }

    ${({ open }) =>
    !open &&
    css`
        position: absolute;
      `}
  }
`;

export const SectionRight = styled.section`
  width: 75%;
  background: #272727;

  @media (max-width: 1130px) {
    width: 100%;
    /* ${({ open }) => !open && css``} */
  }
`;

export const StyledBurger = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 94px;
  left: 50px;
  z-index: 20;
  cursor: pointer;

  @media (max-width: 450px) {
    display: block;
  }

  @media (max-width: 775px) {
    top: 97px;
    left: 40px;
  }

  @media (max-width: 1130px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;

    font-size: 100% !important;
  }

  div {
    width: 2.5rem;
    height: 0.3rem;
    background-color: #fff;
    transform-origin: 3px;
    transition: all 0.3s linear;
    display: none;
    border-radius: 10px;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(40deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ open }) =>
    open ? 'translateX(-100%)' : 'translateX(0)'};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-40deg)' : 'rotate(0)')};
    }

    @media (max-width: 1130px) {
      display: block;
    }
  }
`;
