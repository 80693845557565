import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 50px;

  div.notMusic {
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 70px auto;

    svg {
      width: 400px;
      height: 270px;

      @media (max-width: 450px) {
        width: 100%;
        height: 100%;
      }
    }

    span {
      color: #fff;
      font-size: 30px;
      padding: 30px 0;
      font-family: 'UniviaRegular';
    }

    button {
      font-family: 'Roboto', sans-serif;
      color: #fafafa;
      font-size: 17px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 15px;
      outline: none;
      border: 0;
      padding: 0.5rem 3rem;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7rem;

    color: #ffffff;

    h1 {
      font-size: 35px;
      font-family: 'UniviaRegular';
    }

    div.inputIcon {
      position: relative;

      input {
        width: 150px;
        height: 30px;
        background: #222222;
        border: none;

        padding-left: 30px;
        color: #ffffff;
        font-size: 14px;
      }
      svg {
        position: absolute;
        left: 10px;
        top: 8px;
      }
    }

    @media (max-width: 500px) {
      h1 {
        font-size: 22px;
      }
    }
  }
  div.scroll {
    @media (max-width: 700px) {
      overflow-x: scroll;
      overflow-x: scroll;
      ::-webkit-scrollbar {
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background-color: #222222;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #696969;
        border-radius: 20px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #87868b;
      }
    }

    table {
      width: 100%;
      border-spacing: 0 8px;

      th {
        color: #c5c5c5;
        font-weight: normal;
        padding: 15px 25px;
        text-align: center;
        font-size: 15px;
        line-height: 24px;
        text-transform: uppercase;

        svg {
          margin: 0 5px 3px -20px;
        }
      }

      th.date {
        width: 190px;
      }

      tbody {
        tr {
          border-radius: 5px;
          border-style: solid;
          border-color: #272727;
          border-bottom-width: 20px;

          td {
            /* max-width: 200px; */
            padding: 15px 25px;
            font-size: 15px;
            font-weight: normal;
            text-align: center;
            color: #ffffff;
            background: #222222;

            svg {
              margin: 0 5px 0 5px;
              cursor: pointer;

              transition: transform 0.3s;

              &:hover {
                transform: translateY(-10px);
              }
            }
          }

          td#orange {
            color: #fe5812;

            button span {
              cursor: pointer;
              &:hover {
                color: ${shade(0.2, '#f25812')};
              }
            }
          }
          td#green {
            display: flex;
            flex-direction: column;

            button {
              border: 0;
              border-radius: 15px;
              display: flex;
              align-items: center;
              flex-direction: column;
              background: #32cd16;
              padding: 5px;

              span {
                color: #ffffff;
              }

              &:hover {
                span {
                  color: ${shade(0.2, '#ffffff')};
                }
              }
            }

          }
          td.icon {
            > button {
              background: none;
              border: 0;
            }
          }
        }
      }
    }
  }
`;
