import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.request.use(config => {
    const token = localStorage.getItem('@AcordeOn:token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('@AcordeOn:refreshToken');
        const response = await api.post('/auth/refresh', { refresh_token: refreshToken });
        const { data } = response.data;

        localStorage.setItem('@AcordeOn:token', data.token);
        localStorage.setItem('@AcordeOn:refreshToken', data.refreshToken);

        originalRequest.headers.Authorization = `Bearer ${data.token}`;
        return axios(originalRequest);
      } catch (err) {
        console.error('error in refreshToken', err)
      }
    }

    return Promise.reject(error);
  }
);

export default api;
