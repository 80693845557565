import styled from 'styled-components';

export const BackgroundSection3 = styled.section`
    width: 100%;
    height: 100%;
    background: #181818;
    font-family: 'UniviaRegular';
    position: relative;

    h2 {
        color: #fafafa;
        font-size: 30px;
        text-align: center;
    }

    h2::before {
        content: '';
        width: 100%;
        height: 1px;
        background: #363636;
        display: block;
        margin-bottom: 3rem;
    }

    /* Responsividade */

    @media (max-width: 450px) {
        h2 {
            font-size: 2rem;
            margin-bottom: 50px;
        }
    }
`;

export const DivNumber = styled.div`
    ul {
        font-family: 'UniviaLight';
        color: #7c7c7d;
        font-size: 40px;
        width: 90%;
        display: flex;
        justify-content: space-around;
        margin: 60px auto 40px auto;
    }

    /* Responsividade */

    @media (max-width: 1060px) {
        display: none;
    }
`;

export const DivSteps = styled.div`
    padding-bottom: 50px;

    div {
        display: flex;
        justify-content: space-around;
    }
    ul {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 2rem;
    }
    ul li {
        margin: 0 20px;
    }

    ul:after {
        content: '';
        display: block;
        width: 1px;
        height: 60px;
        background: #363636;
        margin: 0 10px 0 23px;
    }
    ul#last:after {
        content: '';
        display: none;
    }

    /* Responsividade */

    @media (max-width: 1060px) {
        div {
            display: flex;
            flex-wrap: wrap;
            /* flex-direction: column; */
        }
        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px 60px;
        }

        ul:after {
            content: '';
            display: none;
        }
    }

    /* @media (max-width: 1000px) {
        ul {
            display: flex;
            flex-direction: column;
        }
    } */
`;
