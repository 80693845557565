import styled from 'styled-components';

export const Container = styled.button`
    font-size: 18px;

    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    height: 3rem;
    width: 100%;
    color: #fff;
    border: 0;
    border-radius: 17px;
    outline: 0;
    margin-top: 15px;

    &:hover {
        box-shadow: 0 0 2px 2px #f34d21;
    }
`;
