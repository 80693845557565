import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackgroundDiv = styled.div`
  width: 80%;
  background: #222222;
  border-radius: 10px;
  margin: 20px 10px;
  padding: 20px;

  display: flex;

  div.divleft {
    width: 210px;
    height: 320px;

    div.center {
      height: 165px;
    }
  }
  div.divright {
    width: 300px;
  }

  div {
    margin: auto;
    h1 {
      color: #ffffff;
      font-size: 25px;
      display: flex;
      justify-content: center;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 2px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #f34d21, #f5134b);
      color: white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #222222 inset;
      border-radius: 20px;
      padding: 0.4rem 2rem;
      font-size: 15px;
      outline: none;
      transition: all 0.5s ease;
      font-style: normal;
      margin: 30px auto 5px auto;

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      text-align: center;

      span.clasNumberCred {
        font-size: 120px;

        background: -webkit-linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #f34d21, #f5134b);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      aside {
        margin: -30px 0px 0 0;
        color: #898989;
        text-align: center;
        font-size: 20px;
      }
    }

    div.alignTooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      span.clasLink {
        color: #fe5812;
        font-size: 16px;

        svg {
          font-size: 27px;
          padding: 0 7px 3px 0;
        }

        &:hover {
          cursor: help;
          color: ${shade(0.2, '#f25812')};
        }
      }
    }

    h1.h1Historic {
      margin-top: -30px;
    }

    div.historic {
      height: auto;
      width: 300px;
      /* overflow-y: scroll; */

      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        background-color: #1d1d1d;
        border-radius: 20px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #696969;
        border-radius: 20px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #87868b;
      }

      table {
        margin: 20px 0;

        color: #979797;

        tr.thead {
          padding: 5px 20px;
          /* width: 300px; */
          background: #1d1d1d;
        }

        tbody tr {
          font-size: 18px;
          padding: 5px 20px;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        tr + tr {
          border-top: 1px solid #424242;
        }
      }
      @media (max-width: 500px) {
        width: 250px;
      }
    }
  }

  div.line:after {
    display: flex;
    content: '';
    width: 1px;
    height: 300px;
    background: #363636;
    margin: 50px 0;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 25px 0;

    div.line:after {
      display: flex;
      content: '';
      width: 200px;
      height: 1px;
      background: #363636;
      margin: 30px 0;
    }

    div {
      h1.h1Historic {
        margin: 0;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
