import React, { useState } from 'react';

import Modal from "../Modal";
import { ReactComponent as Facebook } from "../../assets/img/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/img/instagram.svg";
import { ReactComponent as LogoCopyright } from "../../assets/img/logoCopyright.svg";

import { BackgroundSection4, DivSection4 } from './styles';
import TermsUse from '../TermsUse';

export default function Footer() {
  const [modalShow, setModalShow] = useState('');

  return (
    <BackgroundSection4>
      <DivSection4 className="content">
        <div className="barCenter">
          <ul>
            <li>
              <h3>INFORMAÇÕES</h3>
            </li>
            <li>
              <button type="button" onClick={() => setModalShow('termos')}>
                Termos e condições de uso
              </button>
              {modalShow === 'termos' && (
                <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
                  <TermsUse />
                </Modal>
              )}
            </li>
            <li>
              <button
                type="button"
                onClick={() => setModalShow('certificacao')}
              >
                Validade
              </button>

              {modalShow === 'certificacao' && (
                <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
                  <h3
                    style={{
                      margin: '40px auto 20px',
                      fontSize: '1.7rem',
                      color: '#f34d21',
                    }}
                  >
                    Validade
                  </h3>
                  <p style={{ margin: '0 70px 20px' }}>
                    O registro da AcordeOn® utiliza a certificação da
                    Infraestrutura de Chaves Públicas Brasileira, ICP-Brasil,
                    instituída através da MEDIDA PROVISÓRIA No 2.200-2, DE 24 DE
                    AGOSTO DE 2001, que garante ao certificado de registro de
                    música da AcordeOn® a autenticidade, integridade e validade
                    jurídica da autoria da obra, obedecendo rigorosamente a lei!
                  </p>
                </Modal>
              )}
            </li>
          </ul>
          <ul className="center">
            <li>
              <h3>CONTATO</h3>
            </li>
            <li>
              <button
                  type="button"
                  aria-label="Facebook"
                  onClick={() => window.open('http://api.whatsapp.com/send?1=pt_BR&phone=5584981261131&text=Olá,%20AcordeOn®.%20Tudo%20bem?')}
                >
                  <span>(84) 98126-1131</span>
                </button>
            </li>
            <li>
              <span>contato@registreacordeon.com.br</span>
            </li>
          </ul>
          <ul>
            <li>
              <h3>ENDEREÇO</h3>
            </li>
            <li>
              <span>
                Av. Amintas Barros, 3700, <br /> Corporate Trade Center, sala
                2104, Natal-RN, Brasil
              </span>
            </li>
          </ul>
        </div>
        <hr />
        <div className="barBottom">
          <figure>
            <LogoCopyright />
            <figcaption>
              Copyright © {new Date().getFullYear()} - Acordeon Registro de Música Online LTDA - CNPJ: 39.305.821/0001-71. Todos os direitos reservados
            </figcaption>
          </figure>
          <nav>
            <ul>
              <li>
                <button
                  type="button"
                  aria-label="Facebook"
                  onClick={() => window.open('https://www.facebook.com/registreacordeon')}
                >
                  <Facebook />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  aria-label="Instagram"
                  onClick={() => window.open('https://www.instagram.com/registreacordeon/')}
                >
                  <Instagram />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  aria-label="Whatsapp"
                  onClick={() => window.open('http://api.whatsapp.com/send?1=pt_BR&phone=5584981261131&text=Olá,%20AcordeOn®.%20Tudo%20bem?')}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{fill: 'hsl(0deg 0% 70%)'}}><path fillRule="evenodd" clipRule="evenodd" d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263" /></svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </DivSection4>
    </BackgroundSection4>
  );
}
