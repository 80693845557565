import React from 'react';
import { Link, useParams } from 'react-router-dom';

import Check from '~/assets/img/check.svg';

import * as S from './styles';

export default function EmailSent() {
    const { title, message } = useParams();

    return (
        <S.SectionNotMusic>
            <div>
                <img src={Check} alt="Email enviado com sucesso" />
                <span className="sucess">{title}</span>
                <span className="validation">{message}</span>
                <Link to="/">Voltar para o início</Link>
            </div>
        </S.SectionNotMusic>
    );
}
