import styled from 'styled-components';

export const SectionLists = styled.section`
  margin-top: -60px;
  background-color: #111111;
  padding-bottom: 5px;
`;

export const DivLoading = styled.div`
  /* position: fixed; */
  /* margin-top: 30px; */
  height: 770px;
  z-index: 99;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
