import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as UndrawTaken } from '~/assets/img/undraw_Taken.svg';

import * as S from './styles';

export default function PageNotFound() {
  return (
    <S.SectionErro>
      <div>
        <UndrawTaken />
        <span>
          Ops, parece que a página que você está tentando acessar não está mais
          disponível.
        </span>
        <Link to="/">Voltar para o início</Link>
      </div>
    </S.SectionErro>
  );
}
