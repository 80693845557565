import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #ffffff;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const BackgroundDiv = styled.div`
  width: 300px;
  height: 360px;
  background: #222222;
  border-radius: 10px;
  margin: 50px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      display: flex;
      flex-direction: column;
      justify-content: space-around;


      align-items: center;

      span.typePlan {
        font-size: 30px;
        text-transform: uppercase;
      }
      span.comment {
        font-size: 16px;
        color: #828282;
        margin: 20px 0;
      }

      table {
        color: #828282;

        tbody {
          tr {
            font-size: 16px;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 270px;

            td.valueWhite {
              color: #ffffff;

              div {
                label {
                  font-size: 16px;
                  margin-right: 10px;
                }
                input {
                  background-color: #1d1d1d;
                  border: 1px solid #363636;
                  border-radius: 4px;
                  color: #fff;
                  padding: 6px;
                  width: 56px;
                  height: 30px;
                }

                button {
                  background: none;
                  border: 0;

                  > svg {
                    font-size: 22px;
                    color: #f34d21;
                  }
                }

                button.buttonLeft {
                  padding-right: 6px;

                  &:disabled {
                    svg {
                      color: ${shade(0.3, '#f34d21')};
                      cursor: not-allowed;
                    }
                  }
                }

                button.buttonRight {
                  padding-left: 6px;
                }
              }
            }
          }
        }
      }
    }

    a.liButton {
      color: #fafafa;
    }

    button.choosePlan {
      display: block;
      font-family: 'Roboto', sans-serif;
      color: #fafafa;
      font-size: 19px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 15px;
      outline: none;
      border: 0;
      padding: 0.5rem 2rem;
      margin-top: 20px;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }
  }
`;

export const BackgroundDivCenter = styled.div`
  width: 270px;
  height: 400px;
  background: #222222;
  border-radius: 10px;
  margin: 50px 10px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 13px 0;

      align-items: center;

      span.clasName {
        margin-top: 12px;
        padding: 1px 30px;
        background: linear-gradient(251.45deg, #32cd32 1.26%, #7fff00 96.99%);
        border-radius: 20px;
        color: #ffffff;
        font-size: 16px;
        box-shadow: 0 0 1em green;
      }

      span.typePlan {
        font-size: 30px;
        text-transform: uppercase;
      }

      table {
        color: #828282;

        tbody {
          tr {
            font-size: 16px;
            padding: 5px 10px;
            display: flex;
            justify-content: space-between;
            width: 270px;

            td.valueWhite {
              color: #ffffff;
            }
          }
        }
      }
    }

    a.liButton {
      color: #fafafa;
    }

    button.choosePlan {
      display: block;
      font-family: 'Roboto', sans-serif;
      color: #fafafa;
      font-size: 19px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 15px;
      outline: none;
      border: 0;
      padding: 0.5rem 2rem;
      /* margin: 30px auto 10px; */

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }
  }
`;
