import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
// import { Form } from '@unform/web';
import { Link } from 'react-router-dom';

import Inicio from '~/subpages/Inicio';
import { ReactComponent as Processing } from '~/assets/img/undraw_processing.svg';
// import Insta from '~/assets/img/insta.svg';
// import Youtube from '~/assets/img/YouTube_.svg';

// import Comp from '~/assets/img/comp.svg';
// import Share from '~/assets/img/share.svg';

import * as S from './styles';
// import Input from '~/components/Input';

export default function EarnPoints() {
  // const formRef = useRef(null);
  // const [disabledInstagram, setDisabledInstagram] = useState('');
  // const [disabledYoutube, setDisabledYoutube] = useState('');

  // function onChangeInstagram(value) {
  //   setDisabledInstagram(value)
  //   if (value !== "") {
  //     document.getElementById("instagram").disabled = false;
  //   }
  // }

  // function onChangeYoutube(value) {
  //   setDisabledYoutube(value)
  //   if (value !== "") {
  //     document.getElementById("youtube").disabled = false;
  //   }
  // }

  // function handleSubmitInstagram(event) {
  //   console.log('handleSubmitInstagram', event.instagram)
  //   document.getElementById('formInstagram').className = "submitted"
  //   document.getElementById('paragraphInstagram').className = "submitted"

  //   window.open("https://www.instagram.com/registreacordeon/", '_blank')
  // }

  // function handleSubmitYoutube(event) {
  //   console.log('handleSubmitYoutube', event.youtube)
  //   document.getElementById('formYoutube').className = "submitted"
  //   document.getElementById('paragraphYoutube').className = "submitted"

  //   window.open("https://www.youtube.com/channel/UCrrbtxG-JrjVaFd8gqMrZvg/featured", '_blank')
  // }

  return (
    <Inicio>
      <S.BackgroundSection>
        <span>
          <Link to="/dashboard/inicio/pontos" className='returnPoint'> Pontos </Link> <IoIosArrowForward /> <font> Ganhar Pontos </font>
        </span>
        <S.BackgroundDiv>
          <div className="soon">
            <Processing />
            <h2>Em breve novidades!</h2>
          </div>
          {/* <ul>
            <li>
              <img src={Insta} alt="instagram" />
            </li>
            <li style={{ marginBottom: '10px' }}>
              Siga-nos no instagram <br />e ganhe 1 ponto
            </li>
            <li id='formInstagram'>
              <Form onSubmit={handleSubmitInstagram} ref={formRef}>
                <Input
                  label="Informe sua conta do instagram:"
                  type="text"
                  name="instagram"
                  id="instagram"
                  onChange={e => onChangeInstagram(e.target.value)}
                />
                <button
                  disabled={disabledInstagram === ''}
                  type='submit'
                  className={disabledInstagram === '' ? 'disabled' : 'notDisabled'}
                >
                  Seguir no instagram
                </button>
              </Form>
            </li>
            <li id='paragraphInstagram'>
              <p>
                Seu instagram foi submetido para análise. <br /> Processo de validação em até 48h para liberação do ponto.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <img src={Youtube} alt="YouTube" className="youtube" />
            </li>
            <li style={{ marginBottom: '10px' }}>
              Inscreva-se no YouTube <br />e ganhe 1 ponto
            </li>
            <li id='formYoutube'>
              <Form onSubmit={handleSubmitYoutube} ref={formRef}>
                <Input
                  label="Informe seu e-mail do YouTube:"
                  type="text"
                  name="youtube"
                  id="youtube"
                  onChange={e => onChangeYoutube(e.target.value)}
                />
                <button
                  disabled={disabledYoutube === ''}
                  type='submit'
                  className={disabledYoutube === '' ? 'disabled' : 'notDisabled'}
                >
                  Se inscrever no canal
                </button>
              </Form>
            </li>
            <li id='paragraphYoutube'>
              <p>
                Seu e-mail foi submetido para análise. <br /> Processo de validação em até 48h para liberação do ponto.
              </p>
            </li>
          </ul> */}
        </S.BackgroundDiv>
      </S.BackgroundSection>
    </Inicio>
  );
}
