import Ws from '@adonisjs/websocket-client';

import api from '~/services/api';

export class SocketConnection {
  connect() {
    const token = localStorage.getItem('@AcordeOn:token');

    this.ws = Ws(`${process.env.REACT_APP_WS_URL}`, {
      path: `${process.env.REACT_APP_WS_PATH}`,
    })
      .withApiToken(token)
      .connect();

    this.ws.on('open', () => {
      console.log('Connection initialized');
    });

    this.ws.on('close', () => {
      console.log('Connection closed');
    });

    return this;
  }

  close() {
    this.ws.close();
  }

  // subscribe (channel, handler) {
  subscribe(channel) {
    const refreshToken = localStorage.getItem('@AcordeOn:refreshToken');

    if (!this.ws) {
      setTimeout(() => this.subscribe(channel), 1000);
    } else {
      const result = this.ws.subscribe(channel);

      result.on('message', message => {
        // handler(message)
        alert('Ebaaa, seus creditos foram processados com sucesso.');
        console.log('Incoming', message);
      });

      result.on('error', error => {
        const arrayError = error.message.split(': ')
        arrayError.filter((value) => value==='E_JWT_TOKEN_EXPIRED')
        if(arrayError.length && arrayError[0] === 'E_JWT_TOKEN_EXPIRED') {

          api.post(`/auth/refresh`, { refresh_token: refreshToken })
          .then((res) => {

            if (res.status === 200) {
              localStorage.setItem('@AcordeOn:token', res.data.data.token);
              localStorage.setItem('@AcordeOn:refreshToken', res.data.data.refreshToken);
              // localStorage.setItem('@AcordeOn:refreshToken', "2854b27fdf9f243073797056c2cd9140/GEziyNTjDj53ePxPzuhA+5x5Sh3SSowFrxXym4aW1CAgh9OO7JoGYDkfznZqRkV");
              // window.location.reload();
            }
          })
          .catch(() => {
            localStorage.removeItem("@AcordeOn:token");
            localStorage.removeItem("@AcordeOn:refreshToken");
            localStorage.removeItem("@AcordeOn:user");
            window.location.reload();
          });
        }
      });
      return result;
    }
  }

  async sendEvent(event, data) {
    // console.log("Event")
    const result = await this.ws.emit(event, data);
    // console.log(result)
    return result;
  }
}

export default new SocketConnection();
