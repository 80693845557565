import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 50px;

  div.notShopping {
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 70px auto;

    svg {
      width: 400px;
      height: 270px;

      @media (max-width: 450px) {
        width: 100%;
        height: 100%;
      }
    }

    span {
      color: #fff;
      font-size: 30px;
      padding: 30px 0;
      font-family: 'UniviaRegular';
    }

    a {
      font-family: 'Roboto', sans-serif;
      color: #fafafa;
      font-size: 17px;
      background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
      border-radius: 15px;
      outline: none;
      border: 0;
      padding: 0.5rem 3rem;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #f34d21;
      }
    }
  }

  div.scroll {
    h1 {
      font-family: 'UniviaRegular';
    }

    @media (max-width: 1450px) {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background-color: #222222;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #696969;
        border-radius: 20px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #87868b;
      }
    }

    table {
      width: 100%;
      border-spacing: 0 8px;

      th {
        color: #c5c5c5;
        font-weight: normal;
        padding: 15px 25px;
        text-align: left;
        font-size: 15px;
        line-height: 24px;
        text-transform: uppercase;

        svg {
          margin: 0 5px 3px -20px;
        }
      }

      tbody {
        tr {
          border-radius: 5px;
          border-style: solid;
          border-color: #272727;
          border-bottom-width: 20px;

          td {
            padding: 15px 25px;
            font-size: 15px;
            font-weight: normal;
            color: #ffffff;
            background: #222222;

            svg {
              cursor: pointer;
              font-size: 25px;

              transition: transform 0.3s;

              &:hover {
                transform: translateY(-10px);
              }
            }
          }

          td#orange {
            > button {
              background: none;
              border: 0;
              color: #fe5812;

              &:hover {
                color: ${shade(0.2, '#f25812')};
              }
            }
          }

          td.yellow {
            color: yellow;
          }
          td.green {
            color: green;
          }
          td.red {
            color: red;
          }
        }
      }
    }
    @media (max-width: 500px) {
      h1 {
        font-size: 22px;
      }
    }
  }

  div.pagination {
    display: flex;
    min-width: 500px;
  }

  div.paginationItem {
    margin: 0 10px;
  }
`;
