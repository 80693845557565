import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import videoService from '~/services/videoService';
import Index from '~/pages/Index';

// import NavigationVideo from './NavigationVideo';
import Loading from '~/components/Loading';
import MovieRow from './MovieRow';
import FeaturedVideo from './FeaturedVideo';

import * as S from './styles';

export default function Tutoriais() {
  const history = useHistory();
  const [movieList, setMovieList] = useState([]);
  const [featuredData, setfeaturedData] = useState(null);

  useEffect(() => {
    const loadAll = async () => {
      // Pegando a lista total
      const list = await videoService.getKnowList();
      const verifyList = list.map(item => item.items.results?.length);
      if (verifyList[0] === 0) {
        return history.push(
          '/dashboard/tutoriais/video/em-breve'
        );
      }
      setMovieList(list);

      // Pegando o Featured
      const popularity = list.filter(i => i.slug === 'popularity');
      const randomChosen = Math.floor(
        Math.random() * (popularity[0].items.results?.length - 1)
      );
      const chosen = popularity[0]?.items.results[randomChosen];
      const chosenInfo = await videoService.getVideoInfo(chosen.key);

      return setfeaturedData(chosenInfo);
    };

    loadAll();
  }, [history]);

  return (
    <Index>
      {featuredData ? (
        <div className="page">
          {/* <NavigationVideo /> */}
          <FeaturedVideo item={featuredData} />

          <S.SectionLists>
            {movieList.map(item => (
              <MovieRow key={item.slug} title={item.title} items={item.items} />
            ))}
          </S.SectionLists>
        </div>
      ) : (
        <S.DivLoading>
          <Loading />
        </S.DivLoading>
      )}
    </Index>
  );
}
