import { createGlobalStyle } from 'styled-components';

import UniviaBook from '~/assets/fonts/UniviaProBook.woff';
import UniviaRegular from '~/assets/fonts/UniviaProRegular.woff';
import UniviaBold from '~/assets/fonts/UniviaPro-Bold.woff';
import BoldItalic from '~/assets/fonts/UniviaProBold-BoldItalic.woff';
import UniviaLight from '~/assets/fonts/UniviaPro-Light.woff';
import UniviaMedium from '~/assets/fonts/UniviaPro-Medium.woff';

export default createGlobalStyle`
    @font-face {
       font-family: 'UniviaBook';
       src: url(${UniviaBook});
   }

    @font-face {
        font-family: 'UniviaRegular';
        src: url(${UniviaRegular});
        font-display: swap;
    }

    @font-face {
        font-family: 'UniviaBold';
        src: url(${UniviaBold});
        font-display: swap;
    }

    @font-face {
       font-family: 'BoldItalic';
       src: url(${BoldItalic});
       font-display: swap;
    }

    @font-face {
       font-family: 'UniviaLight';
       src: url(${UniviaLight});
    }

    @font-face {
       font-family: 'UniviaMedium';
       src: url(${UniviaMedium});
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    .content {
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 3rem;
    }

    .cont {
        max-width: 1000px;
        padding: 0 3rem;

    }

    /*A cada 1rem será considerado 10px*/
    /* html {
        font-size: 62.5%;
    } */

    /*Retirar o azul das bordas do input*/
    *:focus {
        outline: 0;
    }

    html, body, #root {
        min-height: 100%;
        width: 100%;
        overflow-x: hidden;
        font-family: 'Roboto', sans-serif;
    }

    body {
        /* font-size: 1.6rem; */
        background-color: #222222;
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;

        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-track {
            background-color: #222222;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #696969;
            border-radius: 20px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #87868b;
        }
    }

    @media (max-width: 775px) {
        html {
            font-size: 80%;
        }
    }

    @media (max-width: 450px) {
        html {
            font-size: 75%;
        }
    }

    a:link {
        text-decoration: none;
        outline: none;
    }

    a {
      text-decoration: none
    }

    ul, li {
        list-style: none;
    }

    label {
        color: #87868b;
    }

    button {
        cursor: pointer;
        outline: 0;

        &:focus {
            outline: 0px;
        }
    }
`;
