import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Index from '~/pages/Index';
import * as S from './styles';
import api from '~/services/api';
import Moment from 'moment';
import { Button, OverlayTrigger, Tooltip, Form, Row, Col, Card} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { BsEnvelope, BsTrash, BsPencil, BsWallet } from 'react-icons/bs';
import Modal from '~/components/Modal';

import loadingGif from '~/assets/img/loadingMusic.gif';
import { useToast } from '~/hooks/toast';
import { useProfile } from '~/hooks/profile';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


const objFilter = {
  list: [
    { name: 'Data de cadastro', value: 'dataCadastro', select: false },
    { name: 'Perfil ativo', value: 'perfilAtivo', select: false },
    { name: 'Cadastro ativo', value: 'cadastroAtivo', select: false },
    { name: 'Créditos disponíveis', value: 'creditoDisponivel', select: false },
    { name: 'Música Registrada', value: 'maiorPontuacao', select: false },
  ]
};

const Usuarios = () => {

  const [data, setData] = useState([]);
  // const [dataCSV, setDataCSV] = useState(data)
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [hideDirector] = useState(true);
  const [filtrosBusca, setFiltrosBusca] = useState(objFilter.list);
  const [filterSelected, setFilterSelected] = useState({ 
    name: '', 
    value: '', 
    select: false 
  });
  const [questionarioBusca, setQuestionarioBusca] = useState(false);
  const [inputDataCadastro, setInputDataCadastro] = useState('');

  const [filterText] = useState('');
  const [resetPaginationToggle] = useState(false);
  const filteredItems = data.filter(
    item => item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase()),
  );

  const [filter, setFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('nickname');

  const [rowActionUser, setRowActionUser] = useState({});
  const [modalShow, setModalShow] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const { addToast } = useToast();
  const { dataProfile } = useProfile();
  const history = useHistory();
  
  const [isRoleScope, setIsRoleScope] = useState(false);
  const [isRoleScopeAdmin, setIsRoleScopeAdmin] = useState(false);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [dataExcel, setDataExcel] = useState([]);
  const [userEdit, setUserEdit] = useState({
    email: '',
    nickname: '',
    is_active: false
  });


  const columns = useMemo(
    () =>
      [
        {
          name: 'Data Cadastro',
          selector: row => row.created_at,
          sortable: true,
        },
        {
          name: 'Nickname',
          selector: row => row.nickname,
          sortable: true,
          style: {
            width: '50px'
          },
        },
        {
          name: 'Contato',
          center: true,
          button: true,
          cell: row => (
            (row.contact && row.contact.length >= 10) ?
              <a href={row.contactLink} target="_blank" rel="noopener noreferrer">
                {row.contact}
              </a> : row.contact
          ),
        },
        {
          name: 'Ativo',
          selector: row => row.is_active,
          center: true,
        },
        {
          name: 'Musicas Registradas',
          selector: row => row.musics,
          sortable: true,
          center: true,
        },
        {
          name: 'Créditos Disponíveis',
          selector: row => row.creditUsed,
          sortable: true,
          center: true,

        },
        {
          name: 'Créditos Comprados',
          selector: row => row.creditTotal,
          sortable: true,
          center: true,
          omit: hideDirector,
        },
        {
          name: 'Perfil Completo',
          selector: row => row.profile,
          center: true,
          omit: hideDirector,
        },
        {
          name: 'Nome Completo',
          selector: row => row.person.name,
          omit: hideDirector,
        },
        {
          name: 'CPF',
          selector: row => row.person.cpf,
          omit: hideDirector,
        },
        {
          name: 'Ações',
          center: true,
          button: true,
          cell: row => {
            var elementsAction = [
            ]
            if (isRoleScope) {
              elementsAction.push(
                <div>&nbsp;
                  <OverlayTrigger
                    key={`OverlayTrigger-${row.id}`}
                    placement="top"
                    overlay={<Tooltip>{'Editar'}</Tooltip>}
                  >
                    <S.ButtonActions>
                      <button
                        className="buttonIcon"
                        type="button"
                        onClick={() => handleActionButtons(row, 'edit')}
                      >
                        <span className="buttonIcon" variant="secondary" style={{ color: "#ffc107" }}>
                          <BsPencil />
                        </span>
                      </button>
                    </S.ButtonActions>
                  </OverlayTrigger>
                </div>
              )
            }

            if (row.btnAction.linkEmail) {
              elementsAction.push(
                <div>&nbsp;
                  <OverlayTrigger
                    key={`OverlayTrigger-email-${row.id}`}
                    placement="top"
                    overlay={<Tooltip>{'Confirmar Email'}</Tooltip>}
                  >
                    <S.ButtonActions>
                      <button
                        className="buttonIcon"
                        type="button"
                        onClick={() => handleActionButtons(row, 'emailConfirm')}
                      >
                        <span className="buttonIcon" variant="secondary" style={{ color: "#007bff" }}>
                          <BsEnvelope />
                        </span>
                      </button>
                    </S.ButtonActions>
                  </OverlayTrigger>
                </div>
              )
            }
            if (row.btnAction.linkRemove && row.person && row.person.cpf && isRoleScope) {
              elementsAction.push(
                <div>&nbsp;
                  <OverlayTrigger
                    key={`OverlayTrigger-voucher-${row.id}`}
                    placement="top"
                    overlay={<Tooltip>{'Enviar Voucher'}</Tooltip>}
                  >
                    <S.ButtonActions>
                      <button
                        className="buttonIcon"
                        type="button"
                        onClick={() => handleActionButtons(row, 'voucherUser')}
                      >
                        <span className="buttonIcon" variant="secondary" style={{ color: "green" }}>
                          <BsWallet />
                        </span>
                      </button>
                    </S.ButtonActions>
                  </OverlayTrigger>
                </div>
              )
            }
            if (row.btnAction.linkRemove && isRoleScopeAdmin) {
              elementsAction.push(
                <div>&nbsp;
                  <OverlayTrigger
                    key={`OverlayTrigger-remove-${row.id}`}
                    placement="top"
                    overlay={<Tooltip>{'Excluir'}</Tooltip>}
                  >
                    <S.ButtonActions>
                      <button
                        className="buttonIcon"
                        type="button"
                        onClick={() => handleActionButtons(row, 'remove')}
                      >
                        <span className="buttonIcon" variant="secondary" style={{ color: "red" }}>
                          <BsTrash />
                        </span>
                      </button>
                    </S.ButtonActions>
                  </OverlayTrigger>
                </div>
              )
            }

            return elementsAction
          },
        },
      ],
    [hideDirector, isRoleScope, isRoleScopeAdmin],
  );

  const handleActionButtons = (row, typeModal) => {
    if (row) {
      setRowActionUser(row);
      if (typeModal === 'emailConfirm')
        setModalShow('emailConfirm');
      if (typeModal === 'voucherUser')
        setModalShow('voucherUser');
      if (typeModal === 'edit'){
        
        setLoading(true);
        api.get(`/admin/users/${row.id}`).then(response => {
          
          setUserEdit(response.data);
          setLoading(false);
          
        }).catch(error => {
          console.error('Failed to fetch data:', error);
          setLoading(false);
        });
        
        setModalShow('edit');
      }
      if (typeModal === 'remove')
        setModalShow('remove');
    }
  };

  const sendEmailConfirmUser = async () => {
    try {
      setLoadingModal(true);
      setModalShow('')

      console.log(rowActionUser)

      await api.post(`${rowActionUser.btnAction.linkEmail}`);

      setLoadingModal(false);

      addToast({
        type: 'success',
        title: 'Email de confirmação reenviado!',
        description: 'um email com o link de confimação foi enviado para o usuário!',
      });

    } catch (error) {
      setLoadingModal(false);
      setModalShow('')

      if (!error.response) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Serviço de dados indisponível no momento, favor tente mais tarde!',
        });
      }

      if (error.response.data) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: error.response.data.message,
        });
      }else {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Tente novamente',
        });
      }
    }
  }

  const sendVoucherUser = async () => {
    try {
      setLoadingModal(true);
      setModalShow('')

      await api.post(`/admin/vouchers/user`, {
        code: `Parca5on`,
        cpf: `${rowActionUser.person.cpf}`,
      });

      setLoadingModal(false);

      addToast({
        type: 'success',
        title: 'Cadastro realizado!',
        description: 'Voucher enviado para o email do usuário!',
      });

    } catch (error) {
      setLoadingModal(false);
      setModalShow('')

      if (!error.response) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Serviço de dados indisponível no momento, favor tente mais tarde!',
        });
      }

      if (error.response.data) {
        addToast({
          type: 'error',
          title: 'Erro Voucher',
          description: error.response.data.message,
        });
      }else {
        addToast({
          type: 'error',
          title: 'Erro ao enviar voucher',
          description: 'Tente novamente',
        });
      }
    }
  }

  const deleteUser = async () => {
    try {
      setLoadingModal(true);
      setModalShow('')

      await api.delete(`/admin/users/${rowActionUser?.id}`);

      setLoadingModal(false);

      addToast({
        type: 'success',
        title: 'Usuário Removido!',
        description: 'Usuário removido com sucesso!',
      });

    } catch (error) {
      setLoadingModal(false);
      setModalShow('')

      if (!error.response) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Serviço de dados indisponível no momento, favor tente mais tarde!',
        });
      }

      if (error.response.data) {
        addToast({
          type: 'error',
          title: 'Erro Deletar Usuario',
          description: error.response.data.message,
        });
      }else {
        addToast({
          type: 'error',
          title: 'Erro ao deletar Usuario',
          description: 'Tente novamente',
        });
      }
    }
  }


  const fetchUsers = async page => {
    
    console.log("PAGE: ", page, perPage)
    setLoading(true);
    api.get(`/dashboard/users?page=${page}&perpage=${perPage}&typeFilter=${typeFilter}&filter=${filter}`).then(response => {
      setData(getObjectData(response.data.data));
      setPage(response.data.page);
      setTotalRows(response.data.total);
      setLoading(false);
      setDataExcel([]);
      setIsInitialLoad(false);
    }).catch(error => {
      console.error('Failed to fetch data:', error);
      setLoading(false);
    });
    
  };

  const handlePageChange = page => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("PERPAGE: ", newPerPage)
    setLoading(true);
    api.get(`/dashboard/users?page=${page}&perpage=${newPerPage}&typeFilter=${typeFilter}&filter=${filter}`).then(response => {
      setData(getObjectData(response.data.data));
      setPage(response.data.page);
      setPerPage(newPerPage);
      setLoading(false);
      setTotalRows(response.data.total);
      setDataExcel([]);
      setIsInitialLoad(false);
    }).catch(error => {
      console.error('Failed to fetch data:', error);
      setLoading(false);
    });
  };

  const getObjectData = (data) => {
    const dataArray = []

    data.map(async (obj, i) => {

      var model = {
        id: obj.key,
        nickname: obj.nickname,
        email: obj.email,
        creditTotal: obj.wallet?.creditTotal,
        creditUsed: obj.wallet?.creditUsed,
        musics: obj.music.length,
        created_at: Moment(obj.created_at).format('DD/MM/YYYY'),
        is_active: obj.is_active ? "Sim" : "Não",
        profile: (obj.person && obj.person.fullname !== 'Temp') ? "Sim" : "Não",
        contact: obj.person && obj.person.contacts ? obj.person.contacts[0].number : '',
        contactLink: obj.person && obj.person.contacts ? `https://api.whatsapp.com/send?1=pt_BR&phone=55${obj.person.contacts[0].number}&text=Olá, ${obj.nickname}. Tudo bem?` : '',
        // person: (obj.person && obj.person.fullname !== 'Temp') ? obj.person.fullname : "",
      }

      let person = {}
      if (obj.person && obj.person.fullname !== 'Temp') {
        person.name = obj.person.fullname
        person.cpf = obj.person.cpf
      } else {
        person.name = ''
        person.cpf = ''
      }

      let btnAction = {}
      if (!obj.is_active) {
        btnAction.linkEmail = `admin/users/send/email/${obj.email}`
      } else {
        btnAction.linkEmail = ''
      }

      if (!obj.is_active && obj.person.fullname === 'Temp') {
        btnAction.linkRemove = `/#`
      } else {
        btnAction.linkRemove = ''
      }

      model.btnAction = btnAction
      model.person = person


      dataArray.push(model);
    });

    return dataArray;
  };

  
  const findData = useCallback(async () => {
    if (isInitialLoad) {
      setLoading(true);
      api.get(`/dashboard/users?page=${page}&perpage=${perPage}&typeFilter=${typeFilter}&filter=${filter}`).then(response => {
        setData(getObjectData(response.data.data));
        setPage(response.data.page);
        setTotalRows(response.data.total);
        setLoading(false);        
        setIsInitialLoad(false);
      }).catch(error => {
        console.error('Failed to fetch data:', error);
        setLoading(false);
      });
    }
  }, [page, perPage, typeFilter, isInitialLoad, filter]);

  useEffect(() => {
    setIsRoleScope(dataProfile?.roleScope?.includes('ADMIN') || dataProfile?.roleScope?.includes('GESTOR'))
    setIsRoleScopeAdmin(dataProfile?.roleScope?.includes('ADMIN'))

    findData();

  }, [findData, dataProfile, isRoleScope, isRoleScopeAdmin, history, page, perPage]);



  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };


  const ExpandedComponent = ({ data }) => {
    return (
      <div>
        <br />
        &nbsp;&nbsp;<label>Perfil Ativo: </label>&nbsp;<span>{data.is_active}</span><br />
        &nbsp;&nbsp;<label>Perfil Completo: </label>&nbsp;<span>{data.profile}</span><br />
        &nbsp;&nbsp;<label>Nickname: </label>&nbsp;<span>{data.nickname}</span><br />
        &nbsp;&nbsp;<label>Nome Completo: </label>&nbsp;<span>{data.person.name}</span><br />
        &nbsp;&nbsp;<label>CPF: </label>&nbsp;<span>{data.person.cpf}</span><br />
        &nbsp;&nbsp;<label>Email: </label>&nbsp;<span>{data.email}</span><br />
        &nbsp;&nbsp;<label>Telefone: </label>&nbsp;<span>{data.contact}</span><br />
        &nbsp;&nbsp;<label>Data Cadastro: </label>&nbsp;<span>{data.created_at}</span><br />
        &nbsp;&nbsp;<label>Musicas Registradas: </label>&nbsp;<span>{data.musics}</span><br />
        &nbsp;&nbsp;<label>Total de Créditos: </label>&nbsp;<span>{data.creditTotal}</span><br />
        &nbsp;&nbsp;<label>Créditos Disponíveis: </label>&nbsp;<span>{data.creditUsed}</span><br />
      </div>
      // <pre>{JSON.stringify(data, null, 2)}</pre>
    )
  };


  const handleCategoryFilter = (event) => {
    console.log(event.target.value);
    setCategoryFilter(event.target.value)
  };

  const handleCheckboxFilter = (index, oldSelect) => {
    const updatedFiltrosBusca = filtrosBusca.map((item, i) => {
      if (index === i) {
        let obj = { name: item.name, value: item.value, select: !oldSelect }
        setQuestionarioBusca(false);
        setFilterSelected(obj);
        setTypeFilter(item.value);
        return obj; // Inverte o valor de select
      }else {
        if(oldSelect){
          return item; 
        }else{
          return { name: item.name, value: item.value, select: oldSelect }; // Inverte o valor de select
        }
      }
    });
    setFiltrosBusca(updatedFiltrosBusca);
  };

  const handleQuestionarioBusca = (event) => {
    console.log(event);
    setFilter(!questionarioBusca);
    setQuestionarioBusca(!questionarioBusca);
    setIsInitialLoad(true);
    setDataExcel([]);
    // findData();
  };

  const handleInputData = (event) => {
    console.log(event);
    setInputDataCadastro(event)
    if(event.length === 10){
      const date = Moment(event, 'DD/MM/YYYY');
      setFilter(date.format('YYYY-MM-DD'));
      setIsInitialLoad(true);
      setDataExcel([]);
      //findData();
    }

    console.log(inputDataCadastro);
  }

  const handleSearch = (event) => {
    event.preventDefault();
    setDataExcel([]);
    setTypeFilter(categoryFilter);
    setIsInitialLoad(true);
    findData();
  };

  const handleUserInputEmail = (event) => {
    setUserEdit({
      email: event,
      nickname: userEdit.nickname,
      is_active: userEdit.is_active
    });
  };

  const handleUserInputNickname = (event) => {
    setUserEdit({
      email: userEdit.email,
      nickname: event,
      is_active: userEdit.is_active
    });
  };

  const handleUserInputIsactive = () => {
    setUserEdit({
      email: userEdit.email,
      nickname: userEdit.nickname,
      is_active: !userEdit.is_active
    });
  };

  const editUser = async () => {
    setLoadingModal(true);
    await api.put(`/admin/users/${rowActionUser?.id}`, userEdit).then(response => {
      
      setModalShow('')
      setLoadingModal(false);
      addToast({
        type: 'success',
        title: 'Usuário Atualizado!',
        description: 'Usuário atualizado com sucesso!',
      });
      setIsInitialLoad(true);
      findData();

    }).catch(error => {
      console.error('Failed to fetch data:', error);
      setLoadingModal(false);
      setModalShow('')
      
      if (!error.response) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Serviço de dados indisponível no momento, favor tente mais tarde!',
        });
      }

      if (error.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao Atualizar',
          description: error.response.data.message,
        });
      }else {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar Usuario',
          description: 'Tente novamente',
        });
      }
    });
  }

  
  const exportToExcel = () => {
    setLoadingModal(true);
    if(!dataExcel.length){
      api.get(`/dashboard/users?page=${page}&perpage=${perPage}&typeFilter=${typeFilter}&filter=${filter}&isExcel=true`).then(response => {
        setDataExcel(response.data.rows);
        gerarExcel(response.data.rows);
        setLoadingModal(false);
      }).catch(error => {
        setLoadingModal(false);
        console.error('Failed to fetch data:', error);
      });
    }else{
      gerarExcel(dataExcel);
      setLoadingModal(false);
    }    
  };

  const gerarExcel = (data) => {
    // Cria uma nova planilha
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Cria um novo livro (workbook)
    const workbook = XLSX.utils.book_new();

    // Adiciona a planilha ao livro
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dados");

    // Gera um arquivo Excel e converte-o em um blob
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Usa o FileSaver para salvar o arquivo Excel
    saveAs(dataBlob, 'dados.xlsx');
  }


  return (
    <Index>
      <S.BackgroundSection>
        <>
          <h1>
            DASHBOARD ADMINISTRATIVO
          </h1>

          <Card bg='dark'>
            <Card.Header>FILTROS</Card.Header>
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Form onSubmit={handleSearch} inline>
                    <Form.Row>
                      <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                        Buscar por:
                      </Form.Label>
                      <Form.Control value={categoryFilter} onChange={(e) => handleCategoryFilter(e)}
                        className="my-1 mr-2" as="select" size="sm" custom>
                        <option value="nomeArtistico">Nome Artístico</option>
                        <option value="nome">Nome</option>
                        <option value="cpf">CPF</option>
                        <option value="telefone">Telefone</option>
                        <option value="email">Email</option>
                      </Form.Control>
                      <Form.Control size="sm" type="text" value={filter} onChange={(e) => setFilter(e.target.value)} 
                      className="my-1 mr-2" placeholder="Digite sua pesquisa" />
                      <Button type='submit' size="sm">
                        Buscar
                      </Button>                    
                    </Form.Row>
                  </Form>
                </Col>
              </Row>
              <br/>
              <Row>     
                <Col sm={12}>
                  <Form>
                    <Form.Row>
                      <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref02">Filtrar por:</Form.Label>
                      {filtrosBusca.map((item, index) => (
                        <Form.Check
                          className="my-1 mr-2"
                          key={index}
                          type="checkbox"
                          id={`checkbox-${index}`}
                          label={item.name}
                          checked={item.select}
                          onChange={() => handleCheckboxFilter(index, item.select)}
                        />
                      ))}

                      <Button type='button' size="sm" disabled={loadingModal} onClick={exportToExcel}>
                        Exportar para Excel
                      </Button>
                    </Form.Row>                      
                  </Form>
                </Col>
              </Row>
              <br/>
              <Row>                
                <Col sm={12}>
                  <Form>
                    <Form.Row>
                      <div>
                        {(filterSelected.value === 'dataCadastro' && filterSelected.select) && (
                          <InputMask mask="99/99/9999" maskChar={null}  value={inputDataCadastro} 
                          onChange={(e) => handleInputData(e.target.value)}>
                            {() => <Form.Control size="sm" type="text" placeholder="dd/mm/yyyy" />}
                          </InputMask> 
                        )}  
                        {(filterSelected.value !== 'dataCadastro'  && filterSelected.select) && (
                          <Form.Check
                            inline
                            label="SIM"
                            name="group1"
                            type="radio"
                            id="inline-radio-01"
                            checked={questionarioBusca}
                            onChange={(e) => handleQuestionarioBusca(e.target.value)}
                          />
                        )}
                        {(filterSelected.value !== 'dataCadastro' && filterSelected.select) && (
                          <Form.Check
                            inline
                            label="NÃO"
                            name="group2"
                            type="radio"
                            id="inline-radio-02"
                            checked={!questionarioBusca}
                            onChange={(e) => handleQuestionarioBusca(e.target.value)}
                          />
                        )}
                      </div>
                    </Form.Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
        <br/> 
        <>
          {loadingModal && (
            <img
              src={loadingGif}
              alt="Carregando..."
              width="50px"
              className="loading"
            />
          )}
          <DataTable
            title="Lista de Usuários"
            columns={columns}
            data={filteredItems}
            // actions={actionsMemo}
            theme="dark"
            dense
            fixedHeader={true}
            fixedHeaderScrollHeight="500px"
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationResetDefaultPage={resetPaginationToggle}
            customStyles={customStyles}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </>
      </S.BackgroundSection>
      {modalShow === 'emailConfirm' && (
        <Modal
          isOpen={modalShow}
          onClickClose={() => setModalShow('')}
        >
          <div className="remove">
            <h1>Enviar email de confimação</h1>
            <span className="spanRemove">
              Deseja reenviar o email de confimação para o usuário <b>{rowActionUser?.nickname}</b>?
            </span>
            <div className="alignButtonsRemove">
              <button
                className="buttonsCancel"
                type="button"
                onClick={() => setModalShow('')}
              >
                Cancelar
              </button>
              <button
                className="buttonsRemove"
                type="button"
                onClick={() => sendEmailConfirmUser()}
              >
                Confirmar
              </button>
            </div>
          </div>
        </Modal>
      )}
      {modalShow === 'voucherUser' && (
        <Modal
          isOpen={modalShow}
          onClickClose={() => setModalShow('')}
        >
          <div className="remove">
            <h1>Enviar Voucher</h1>
            <span className="spanRemove">
              Deseja enviar para o usuário <b>{rowActionUser?.nickname}</b> o voucher <b>Parca5on</b>?
            </span>
            <div className="alignButtonsRemove">
              <button
                className="buttonsCancel"
                type="button"
                onClick={() => setModalShow('')}
              >
                Cancelar
              </button>
              <button
                className="buttonsRemove"
                type="button"
                onClick={() => sendVoucherUser()}
              >
                Enviar
              </button>
            </div>
          </div>
        </Modal>
      )}
      {modalShow === 'edit' && (
        <Modal
          isOpen={modalShow}
          onClickClose={() => setModalShow('')}
        >
          <div className="remove">
            <h1>Editar Usuário</h1>
            <div>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} controlId="formEmail">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="email" placeholder="email" 
                    value={userEdit.email} onChange={(e) => handleUserInputEmail(e.target.value)}/>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formNomeArtistico">
                    <Form.Label>Nome artístico:</Form.Label>
                    <Form.Control placeholder="nome artistico" 
                    value={userEdit.nickname} onChange={(e) => handleUserInputNickname(e.target.value)}/>
                  </Form.Group>
                </Form.Row>

                <Form.Group id="formAtivo">
                  <Form.Check type="checkbox" label="Ativo:" 
                  checked={userEdit.is_active} onChange={() => handleUserInputIsactive()}/>
                </Form.Group>

              </Form>
            </div>
            <div className="alignButtonsRemove">
              <button
                className="buttonsRemove"
                type="button"
                onClick={() => setModalShow('')}
              >
                Cancelar
              </button>
              <button
                disabled={loadingModal}
                className="buttonsCancel"
                type="button"
                onClick={() => { editUser() }}
              >
                Editar
                &nbsp;&nbsp;
                {loadingModal && (
                <span>
                  <img
                    src={loadingGif}
                    alt="Carregando..."
                    width="10px"
                    className="loading"
                  />
                </span>
                )}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {modalShow === 'remove' && (
        <Modal
          isOpen={modalShow}
          onClickClose={() => setModalShow('')}
        >
          <div className="remove">
            <h1>Excluir Usuário</h1>
            <span className="spanRemove">
              Deseja excluir o usuário <b>{rowActionUser?.nickname}</b> permanentemente?
            </span>
            <div className="alignButtonsRemove">
              <button
                className="buttonsCancel"
                type="button"
                onClick={() => setModalShow('')}
              >
                Cancelar
              </button>
              <button
                className="buttonsRemove"
                type="button"
                onClick={() => { deleteUser() }}
              >
                Excluir
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Index>

  );
};

export default Usuarios;
