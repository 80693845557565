import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';
import socket from '../services/socket';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const refreshToken = localStorage.getItem('@AcordeOn:refreshToken');
    const token = localStorage.getItem('@AcordeOn:token');
    const user = localStorage.getItem('@AcordeOn:user');

    if (token && user && refreshToken) {
      // api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        user: JSON.parse(user),
      };
    }

    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('auth/signin', {
      email,
      password,
    });

    const { token, refreshToken, user } = response.data;

    localStorage.setItem('@AcordeOn:token', token);
    localStorage.setItem('@AcordeOn:refreshToken', refreshToken);
    localStorage.setItem('@AcordeOn:user', JSON.stringify(user));

    // api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, refreshToken, user });
  }, []);

  const signOut = useCallback(() => {

    const token = localStorage.getItem('@AcordeOn:token');
    // api.defaults.headers.authorization = `Bearer ${token}`;
    const refreshToken = localStorage.getItem('@AcordeOn:refreshToken');
    // console.log(token)
    if(!token)
      socket.close();

    // console.log(refreshToken);
    api.post('/auth/logout', {
      refresh_token: refreshToken,
    });

    localStorage.removeItem('@AcordeOn:token');
    localStorage.removeItem('@AcordeOn:refreshToken');
    localStorage.removeItem('@AcordeOn:user');

    setData({});
  }, []);

  const updateUser = useCallback(
    user => {
      localStorage.setItem('@AcordeOn:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
