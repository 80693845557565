import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FaRegCopy, FaRegShareSquare } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import ClipboardJS from 'clipboard';
import Moment from 'moment';

import Index from '~/pages/Index';
import check from '~/assets/img/check.svg';
// import boletoimg from '~/assets/img/boletoimg.png';

import * as S from './styles';

import { useToast } from '~/hooks/toast';

import OrderStatusEnum from '~/enums/OrderStatusEnum';
import { useProfile } from '~/hooks/profile';
import formatValue from '~/utils/formatValue';

import api from '~/services/api';

// const [qtdCredit, setQtdCredit] = useState(1);
// const [qtdPortion, setQtdPortion] = useState(1);

// const mult = qtdCredit * 19.9;
// const share = mult / qtdPortion;
// const creditFormattedMult = formatValue(mult);
// const creditFormattedShare = formatValue(share);

export default function SuccessCard() {
  const history = useHistory();
  const { addToast } = useToast();
  const { cardData } = useProfile();
  const formatedTotal = formatValue(cardData.total);
  const [statusOrder, setStatusOrder] = useState('');

  const formatDate = Moment(cardData.created_at).format('DD/MM/YYYY');

  useEffect(() => {
    setStatusOrder(
      cardData.status_id
        ? OrderStatusEnum.properties[cardData.status_id].label
        : ''
    );
  }, [statusOrder, cardData]);

  const clipboard = new ClipboardJS('#buttonCopy');
  clipboard.on('success', e => {
    // console.info('Action:', e.action);
    // console.info('Text:', e.text);
    // console.info('Trigger:', e.trigger);
    addToast({
      type: 'success',
      title: 'Sucesso',
      description: `Código de barras copiado para área de transferência!`,
    });

    e.clearSelection();
  });

  clipboard.on('error', e => {
    addToast({
      type: 'error',
      title: 'Erro',
      description: `Erro ao copiar!`,
    });
    // console.error('Action:', e.action);
    // console.error('Trigger:', e.trigger);
  });

  function openTab() {
    if (cardData.payment) {
      window.open(
        `${cardData.payment.transaction.boleto_url}?format=pdf`,
        '_blank'
      );
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: `Erro ao capturar boleto!`,
      });
    }
  }

  const sendBilletEmail = async () => {
    api
      .get(`/email/send/billet/order/${cardData.key}`)
      .then(response => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `Boleto enviado para o seu e-mail!`,
        });
      })
      .catch(err => {
        console.log('erro', err);
        addToast({
          type: 'error',
          title: 'Erro',
          description: `Erro ao enviar boleto!`,
        });
      });
  };

  useEffect(() => {
    if (cardData.length === 0) {
      history.push('/dashboard/inicio/resumo');
    }
  }, [cardData, history]);

  return (
    <Index>
      <S.SectionNotMusic>
        <div className="sucess">
          <img src={check} alt="Solicitação realizada com sucesso" />

          <span>Solicitação realizada com sucesso!</span>
        </div>
        <div className="resume">
          <h1>Resumo:</h1>
          <Table borderless>
            <div className="orderService">
              <span>
                Transação Nº:
                <strong>
                  {' '}
                  {cardData.payment && cardData.payment.transaction
                    ? cardData.payment.transaction.transaction_id
                    : ''}
                </strong>
              </span>
            </div>
            <tbody>
              <tr>
                <td>Créditos:</td>
                <td>{cardData.__meta__ ? cardData.__meta__.qty_items : ''}</td>
              </tr>

              <tr>
                <td>Status:</td>
                <td>{statusOrder}</td>
              </tr>

              <tr>
                <td>Meio de pagamento:</td>
                <td>Boleto</td>
              </tr>

              <tr>
                <td>Data:</td>
                <td>{formatDate}</td>
              </tr>

              <tr>
                <td>Total:</td>
                <td>{formatedTotal}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="informationBillet">
          {/* <img src={boletoimg} alt="Código de barras" /> */}
          <span>
            {cardData.payment
              ? cardData.payment.transaction.boleto_barcode
              : ''}
          </span>
          <div>
            <button
              id="buttonCopy"
              type="button"
              data-clipboard-text={
                cardData.payment
                  ? cardData.payment.transaction.boleto_barcode
                  : ''
              }
            >
              <FaRegCopy />
              <span>Copiar</span>
            </button>
            <button type="button" onClick={openTab}>
              <FiDownload />
              <span>Baixar</span>
            </button>
            <button type="button" onClick={sendBilletEmail}>
              <FaRegShareSquare />
              <span>Enviar</span>
            </button>
          </div>
          <span>
            Pagamentos por boleto podem levar até 3 dias utéis para
            pracessamento
          </span>
        </div>
        <Link to="/dashboard/inicio/resumo">Voltar para o início</Link>
      </S.SectionNotMusic>
    </Index>
  );
}
