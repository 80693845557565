import styled from 'styled-components';

export const BackgroundSection2 = styled.section`
  height: 100%;
  background: #181818;
  font-family: 'UniviaUltraLight';
  display: flex;
  align-items: center;
  position: relative;
  padding: 50px 0;
`;

export const DivSection2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.divLeft {
    margin-right: 1rem;
    margin-bottom: 2rem;
  }


  div {
    h2 {
      color: #fff;
      font-family: 'UniviaRegular';
      font-size: 28px;
    }
    p,
    h3 {
      margin-top: 2rem;
      font-size: 16px;
      line-height: 1.3;
    }
    p {
      font-family: 'UniviaLight';
      color: #fafafa;
    }

    h3 {
      font-family: 'UniviaRegular';
      color: #fe5812;
    }
  }

  div.divRight {
    width: 100%;
    margin-top: -17px;
  }

  /* div.react-player, img {
    width: 555px !important;
    height: 313px !important;
  } */

  /* Responsividade */

  @media (max-width: 1200px) {
    flex-direction: column;

    /* div.divRight {
      margin: 25px auto;
    }

    div.react-player, img {
      width: 555px !important;
      height: 313px !important;
    } */
  }

  @media (max-width: 700px) {
    /* div.react-player, img {
      width: 360px !important;
      height: 205px !important;
      margin-top: 30px;
    } */
  }

  @media (max-width: 450px) {
    div {
      p {
        line-height: 2;
      }
      h3 {
        line-height: 1.5;
      }
    }

    /* div.divRight {
      width: 100% !important;

    }

    div.react-player, img {
      height: 170px !important;
    } */
  }
`;
