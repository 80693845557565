import React, { useEffect, useState } from 'react';

import Loading from '~/components/Loading';

import { useProfile } from '~/hooks/profile';
import ContentStart from '~/components/ContentStart';
import api from '~/services/api';
// import Pagination from '~/components/Pagination';

export default function Credit() {
  const { dataProfile } = useProfile();
  const [historiesCredit, setHistoriesCredit] = useState();
  const [inteiro, setInteiro] = useState();

  const limit = 5;
  const [currentPages, setCurrentPages] = useState(1);

  useEffect(() => {
    api
      .get(`/user/history/wallet?page=${currentPages}&limit=${limit}`)
      .then(response => {
        setHistoriesCredit(response.data.data);
        const { total } = response.data;
        setInteiro(total);
      });
  }, [currentPages]);

  const creditTotal = dataProfile?.wallet?.creditUsed;

  return (
    <>
      {dataProfile.length === 0 ? (
        <Loading />
      ) : (
        <ContentStart
          title="Seu saldo atual:"
          textButton="Adicionar créditos"
          textTooltip="O que são os créditos?"
          tooltip="Crédito é a moeda utilizada na AcordeOn®. Cada Crédito equivale a 01 (UM) registro de música, que pode ser utilizado a qualquer momento pois eles NÃO têm validade."
          historyName="CRÉDITOS"
          total={creditTotal}
          typeObject="creditUsed"
          histories={historiesCredit}
          // historyDataValue={novoArray}
          inteiro={inteiro}
          limit={limit}
          setCurrentPages={setCurrentPages}
          link="/dashboard/adicionar-creditos/planos"
        >
          <>
            <span className="clasNumberCred">
              {creditTotal <= 9
                ? `0${creditTotal}`
                : creditTotal}
            </span>
            <aside>
              {creditTotal <= 1 ? 'Crédito' : 'Créditos'}
            </aside>
          </>
        </ContentStart>
      )}
    </>
  );
}
