import React from 'react';

// import Header from '~/components/Header';
import Section1 from '~/components/Home/Section1';
import Section2 from '~/components/Home/Section2';
import Section3 from '~/components/Home/Section3';
import Section4 from '~/components/Home/Section4';

export default function Home() {
    return (
        <>
            {/* <Header /> */}
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
        </>
    );
}
