import React, { useState, useEffect } from 'react';

import { Link, useLocation, useHistory } from 'react-router-dom';
import Modal from '~/components/Modal';
// import { useAuth } from '~/hooks/auth';
import { useProfile } from '~/hooks/profile';

// import Burger from './burger';
import avatar from '~/assets/img/acordeonAvatar.svg';
import * as S from './styles';

import Loading from '~/components/Loading';
// import api from '~/services/api';

export default function Index({ children }) {
  const history = useHistory();
  const location = useLocation();
  const { dataProfile, dataPerson } = useProfile();
  // const [avatarImg, setAvatarImg] = useState();
  const [modalShow, setModalShow] = useState('');
  const [showDashboardAdmin, setShowDashboardAdmin] = useState(false);
  const [showDashboardConfig, setShowDashboardConfig] = useState(false);
  const [open, setOpen] = useState(false);


  const handleRegisterMusic = () => {
    if (dataProfile.wallet.creditUsed > 0 && dataPerson.fullname !== null) {
      history.push('/dashboard/registrar-musica');
    } else if (dataPerson.fullname === null) {
      setModalShow('showNotProfile');
    } else if (dataProfile.wallet.creditUsed <= 0) {
      setModalShow('showNotCredit');
    }
  };

  useEffect(() => {
    const isRoleScope = dataProfile?.roleScope

    if (isRoleScope)
      if (isRoleScope.includes("ADMIN")) {
        setShowDashboardAdmin(isRoleScope.includes("ADMIN"));
        setShowDashboardConfig(isRoleScope.includes("ADMIN"));
      } else if(isRoleScope.includes("GESTOR")){
        setShowDashboardAdmin(isRoleScope.includes("GESTOR"));
      }

  }, [dataProfile]);

  return (
    <>
    {/* <Suspense fallback={<Loading />}> */}
      {dataProfile ? (
        <S.BackgroundSection>
          <S.StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </S.StyledBurger>
          <S.SectionLeft open={open}>
            <div>
              <img
                src={dataProfile.avatar ? dataProfile.avatar : avatar}
                alt={dataProfile.nickname}
              />
              <h1>{dataProfile.nickname}</h1>
            </div>
            <div className="tab-links">
              <button
                className="buttonRegister"
                type="button"
                onClick={() => handleRegisterMusic()}
              >
                Registrar música
              </button>

              <Link
                to="/dashboard/inicio/resumo"
                className={
                  location.pathname.split('/')[2] === 'inicio'
                    ? 'isSelected'
                    : ''
                }
              >
                Início
              </Link>
              <Link
                to="/dashboard/perfil"
                className={
                  location.pathname.split('/')[2] === 'perfil'
                    ? 'isSelected'
                    : ''
                }
              >
                Perfil
              </Link>
              <Link
                to="/dashboard/minhas-musicas"
                className={
                  location.pathname.split('/')[2] === 'minhas-musicas'
                    ? 'isSelected'
                    : ''
                }
              >
                Minhas músicas
              </Link>
              <Link
                to="/dashboard/minhas-compras"
                className={
                  location.pathname.split('/')[2] === 'minhas-compras'
                    ? 'isSelected'
                    : ''
                }
              >
                Minhas compras
              </Link>
              <Link
                to="/dashboard/tutoriais"
                className={
                  location.pathname.split('/')[2] === 'tutoriais'
                    ? 'isSelected'
                    : ''
                }
              >
                Tutoriais
              </Link>
              <Link
                to="/dashboard/perguntas/geral"
                className={
                  location.pathname.split('/')[2] === 'perguntas'
                    ? 'isSelected'
                    : ''
                }
              >
                Perguntas
              </Link>

              { showDashboardAdmin ?
                <Link
                  to="/dashboard/admin/lista-usuarios"
                  className={
                    location.pathname.split('/')[2] === 'lista-usuarios'
                      ? 'isSelected'
                      : ''
                  }
                >
                  Dashboard Admin
                </Link>
                : ''
              }
              { showDashboardConfig ?
                <Link
                  to="/dashboard/admin/configuracoes"
                  className={
                    location.pathname.split('/')[3] === 'configuracoes'
                      ? 'isSelected'
                      : ''
                  }
                >
                  Configurações
                </Link>
                : ''
              }

            </div>
          </S.SectionLeft>
          <S.SectionRight open={open}>
            <div className="divBody">
              <main>
                <div className="tab-content">{children}</div>
              </main>
            </div>
          </S.SectionRight>
        </S.BackgroundSection>
       ) : (
          <Loading />
        )};

      {/* Modais */}

      {modalShow === 'showNotProfile' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <h1 className="h1ButtonRegisterMusic">
            Falta pouco para você se tornar um grande compositor de sucesso
          </h1>
          <p className="pButtonRegisterMusic">
            Complete seu cadastro para adicionar créditos
          </p>

          <Link className="buttonModal" to="/dashboard/perfil">
            Completar cadastro
          </Link>
        </Modal>
      )}

      {modalShow === 'showNotCredit' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <h1 className="h1ButtonRegisterMusic">
            Falta pouco para você se tornar um grande compositor de sucesso
          </h1>
          <p className="pButtonRegisterMusic">
            Garanta a segurança de sua obra adicionando crédito para registrar
            sua música
          </p>

          <Link
            className="buttonModal"
            to="/dashboard/adicionar-creditos/planos"
          >
            Adicionar crédito
          </Link>
        </Modal>
      )}
    </>
  );
}
